import React, { useEffect } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

const PrivacyPolicy = () => {
  
  useEffect(() => {
    window.scroll(0,0);
   }, [])
  return (
    <>
      <Header />
      <section className="commonSection">
        <div className="container">
          <div class="WordSection1">
            <p style={{ margin: "0in", background: "white" }}>
              <b>
                <span
                  style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
                >
                  PRIVACY NOTICE
                </span>
              </b>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
              >
                Thank you for choosing to be part of our community at ARE
                LUNCHBOX LLC, doing business as Lunchbox ("Lunchbox," "we,"
                "us," or "our"). We are committed to protecting your personal
                information and your right to privacy. If you have any questions
                or concerns about this privacy notice or our practices with
                regard to your personal information, please contact us at{" "}
              </span>
              <span style={{ color: "black" }}>
                <a href="mailto:info@arelunchbox.com">
                  <span style={{fontFamily:"Arial,sans-serif",color:"#1155CC"}}>
                    info@arelunchbox.com
                  </span>
                </a>
              </span>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
              >
                . This privacy notice describes how we might use your
                information if you:
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span style={{fontFamily:"Arial,sans-serif",color:"black"}}>
                &nbsp;
              </span>
            </p>

            <p
              style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",textIndent:"-.25in",background:"white",verticalAlign:"baseline"}}
            >
              <span style={{fontSize:"10.0pt",fontFamily:"Symbol",color:"black"}}>
                Â·
                <span style={{font:"7.0pt Times New Roman"}}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span style={{fontFamily:"Arial,sans-serif",color:"#222222"}}>
                Visit our website at{" "}
              </span>
              <span style={{ color: "black" }}>
                <a href="http://www.arelunchbox.com/">
                  <span
                    style={{fontFamily:"Arial,sans-serif",color:"#1155CC"}}
                  >
                    http://www.arelunchbox.com
                  </span>
                </a>
              </span>
            </p>

            <p
             style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",background:"white"}}
            >
              <span style={{fontFamily:"Arial,sans-serif",color:"black"}}>
                &nbsp;
              </span>
            </p>

            <p
              style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",textIndent:"-.25in",background:"white",verticalAlign:"baseline"}}
            >
              <span style={{fontSize:"10.0pt",fontFamily:"Symbol",color:"#222222"}}>
                Â·
                <span style={{font:"7.0pt Times New Roman"}}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span style={{fontFamily:"Arial,sans-serif",color:"#222222"}}>
                Engage with us in other related ways - including any sales,
                marketing, or events
              </span>
            </p>

            <p
              style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",background:"white"}}
            >
              <span style={{fontFamily:"Arial,sans-serif",color:"black"}}>
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
              >
                In this privacy notice, if we refer to:
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p
              style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",textIndent:"-.25in",background:"white",verticalAlign:"baseline"}}
            >
              <span style={{fontSize:"10.0pt",fontFamily:"Symbol",color:"#222222"}}>
                Â·
                <span style={{font:"7.0pt Times New Roman"}}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <b>
                <span style={{fontFamily:"Arial,sans-serif",color:"#222222"}}>
                  "Website,"
                </span>
              </b>
              <span style={{fontFamily:"Arial,sans-serif",color:"#222222"}}>
                {" "}
                we are referring to any website of ours that references or links
                to this policy
              </span>
            </p>

            <p
             style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",background:"white"}}
            >
              <span style={{fontFamily:"Arial,sans-serif",color:"black"}}>
                &nbsp;
              </span>
            </p>

            <p
              style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",textIndent:"-.25in",background:"white",verticalAlign:"baseline"}}
            >
              <span style={{fontSize:"10.0pt",fontFamily:"Symbol",color:"#222222"}}>
                Â·
                <span style={{font:"7.0pt Times New Roman"}}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <b>
                <span style={{fontFamily:"Arial,sans-serif",color:"#222222"}}>
                  "Services,"
                </span>
              </b>
              <span style={{fontFamily:"Arial,sans-serif",color:"#222222"}}>
                {" "}
                we are referring to our Website, and other related services,
                including any sales, marketing, or events
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
              >
                The purpose of this privacy notice is to explain to you in the
                clearest way possible what information we collect, how we use
                it, and what rights you have in relation to it. If there are any
                terms in this privacy notice that you do not agree with, please
                discontinue use of our Services immediately.&nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <b>
                <span
                  style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
                >
                  Please read this privacy notice carefully, as it will help you
                  understand what we do with the information that we collect.
                </span>
              </b>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <b>
                <span
                  style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
                >
                  TABLE OF CONTENTS
                </span>
              </b>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <u>
                <span
                  style={{fontFamily:"Arial,sans-serif",color:"blue"}}
                >
                  1. WHAT INFORMATION DO WE COLLECT?
                </span>
              </u>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <u>
                <span
                  style={{fontFamily:"Arial,sans-serif",color:"blue"}}
                >
                  2. HOW DO WE USE YOUR INFORMATION?
                </span>
              </u>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <u>
                <span
                  style={{fontFamily:"Arial,sans-serif",color:"blue"}}
                >
                  3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
                </span>
              </u>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <u>
                <span
                  style={{fontFamily:"Arial,sans-serif",color:"blue"}}
                >
                  4. WHO WILL YOUR INFORMATION BE SHARED WITH?
                </span>
              </u>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <u>
                <span
                  style={{fontFamily:"Arial,sans-serif",color:"blue"}}
                >
                  5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                </span>
              </u>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <u>
                <span
                  style={{fontFamily:"Arial,sans-serif",color:"blue"}}
                >
                  6. HOW LONG DO WE KEEP YOUR INFORMATION?
                </span>
              </u>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <u>
                <span
                  style={{fontFamily:"Arial,sans-serif",color:"blue"}}
                >
                  7. HOW DO WE KEEP YOUR INFORMATION SAFE?
                </span>
              </u>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <u>
                <span
                  style={{fontFamily:"Arial,sans-serif",color:"blue"}}
                >
                  8. WHAT ARE YOUR PRIVACY RIGHTS?
                </span>
              </u>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <u>
                <span
                  style={{fontFamily:"Arial,sans-serif",color:"blue"}}
                >
                  9. CONTROLS FOR DO-NOT-TRACK FEATURES
                </span>
              </u>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <u>
                <span
                  style={{fontFamily:"Arial,sans-serif",color:"blue"}}
                >
                  10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                </span>
              </u>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <u>
                <span
                  style={{fontFamily:"Arial,sans-serif",color:"blue"}}
                >
                  11. DO WE MAKE UPDATES TO THIS NOTICE?
                </span>
              </u>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <u>
                <span
                  style={{fontFamily:"Arial,sans-serif",color:"blue"}}
                >
                  12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                </span>
              </u>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <u>
                <span
                  style={{fontFamily:"Arial,sans-serif",color:"blue"}}
                >
                  13. HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT
                  FROM YOU?
                </span>
              </u>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <b>
                <span
                  style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
                >
                  1. WHAT INFORMATION DO WE COLLECT?
                </span>
              </b>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <b>
                <span
                  style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
                >
                  Personal information you disclose to us
                </span>
              </b>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <b>
                <i>
                  <span
                    style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
                  >
                    In Short:
                  </span>
                </i>
              </b>
              <i>
                <span
                  style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
                >
                  {" "}
                  We collect personal information that you provide to us.
                </span>
              </i>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
              >
                We collect personal information that you voluntarily provide to
                us when you register on the Website, express an interest in
                obtaining information about us or our products and Services,
                when you participate in activities on the Website or otherwise
                when you contact us.
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
              >
                The personal information that we collect depends on the context
                of your interactions with us and the Website, the choices you
                make and the products and features you use. The personal
                information we collect may include the following:
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <b>
                <span
                  style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
                >
                  Personal Information Provided by You.{" "}
                </span>
              </b>
              <span style={{fontFamily:"Arial,sans-serif",color:"#222222"}}>
                We collect names; email addresses; billing addresses;
                debit/credit card numbers; passwords; contact or authentication
                data; and other similar information.
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <b>
                <span
                  style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
                >
                  Payment Data.
                </span>
              </b>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
              >
                {" "}
                We may collect data necessary to process your payment if you
                make purchases, such as your payment instrument number (such as
                a credit card number), and the security code associated with
                your payment instrument. All payment data is stored by Stripe.
                You may find their privacy notice links) here:{" "}
              </span>
              <span style={{ color: "black" }}>
                <a href="https://stripe.com/privacy">
                  <span style={{fontFamily:"Arial,sans-serif",color:"#1155CC"}}>
                    https://stripe.com/privacy
                  </span>
                </a>
              </span>
              <span style={{fontFamily:"Arial,sans-serif",color:"#222222"}}>
                .
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <b>
                <span
                  style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
                >
                  Social Media Login Data.
                </span>
              </b>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
              >
                {" "}
                We may provide you with the option to register with us using
                your existing social media account details, like your Facebook,
                Twitter or other social media account. If you choose to register
                in this way, we will collect the information described in the
                section called "HOW DO WE HANDLE YOUR SOCIAL LOGINS?" below.
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
              >
                All personal information that you provide to us must be true,
                complete and accurate, and you must notify us of any changes to
                such personal information.
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <b>
                <span
                  style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
                >
                  Information automatically collected
                </span>
              </b>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <b>
                <i>
                  <span
                    style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
                  >
                    In Short:{" "}
                  </span>
                </i>
              </b>
              <i>
                <span
                  style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
                >
                  Some information - such as your Internet Protocol (IP) address
                  and/or browser and device characteristics - is collected
                  automatically when you visit our Website.
                </span>
              </i>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
              >
                We automatically collect certain information when you visit, use
                or navigate the Website. This information does not reveal your
                specific identity (like your name or contact information) but
                may include device and usage information, such as your IP
                address, browser and device characteristics, operating system,
                language preferences, referring URLs, device name, country,
                location, information about how and when you use our Website and
                other technical information. This information is primarily
                needed to maintain the security and operation of our Website,
                and for our internal analytics and reporting purposes.&nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
              >
                The information we collect includes:
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p
              style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",textIndent:"-.25in",background:"white",verticalAlign:"baseline"}}
            >
              <span style={{fontSize:"10.0pt",fontFamily:"Symbol",color:"#222222"}}>
                Â·
                <span style={{font:"7.0pt Times New Roman"}}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <i>
                <span style={{fontFamily:"Arial,sans-serif",color:"#222222"}}>
                  &nbsp;Log and Usage Data.
                </span>
              </i>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
              >
                {" "}
                Log and usage data is service-related, diagnostic, usage and
                performance information our servers automatically collect when
                you access or use our Website and which we record in log files.
                Depending on how you interact with us, this log data may include
                your IP address, device information, browser type and settings
                and information about your activity in the Website (such as the
                date/time stamps associated with your usage, pages and files
                viewed, searches and other actions you take such as which
                features you use), device event information (such as system
                activity, error reports (sometimes called 'crash dumps') and
                hardware settings).
              </span>
            </p>

            <p
             style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",background:"white"}}
            >
              <span style={{fontFamily:"Arial,sans-serif",color:"black"}}>
                &nbsp;
              </span>
            </p>

            <p
              style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",textIndent:"-.25in",background:"white",verticalAlign:"baseline"}}
            >
              <span style={{fontSize:"10.0pt",fontFamily:"Symbol",color:"#222222"}}>
                Â·
                <span style={{font:"7.0pt Times New Roman"}}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span style={{fontFamily:"Arial,sans-serif",color:"#222222"}}>
                Device Data. We collect device data such as information about
                your computer, phone, tablet or other device you use to access
                the Website. Depending on the device used, this device data may
                include information such as your IP address (or proxy server),
                device and application identification numbers, location, browser
                type, hardware model, Internet service provider and/or mobile
                carrier, operating system and system configuration information.
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <b>
                <span
                  style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
                >
                  2. HOW DO WE USE YOUR INFORMATION?
                </span>
              </b>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <b>
                <i>
                  <span
                    style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
                  >
                    In Short:
                  </span>
                </i>
              </b>
              <i>
                <span
                  style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
                >
                  {" "}
                  We process your information for purposes based on legitimate
                  business interests, the fulfillment of our contract with you,
                  compliance with our legal obligations, and/or your consent.
                </span>
              </i>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
              >
                We use personal information collected via our Website for a
                variety of business purposes described below. We process your
                personal information for these purposes in reliance on our
                legitimate business interests, in order to enter into or perform
                a contract with you, with your consent, and/or for compliance
                with our legal obligations. We indicate the specific processing
                grounds we rely on next to each purpose listed below.
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
              >
                We use the information we collect or receive:
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p
              style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",textIndent:"-.25in",background:"white",verticalAlign:"baseline"}}
            >
              <span style={{fontSize:"10.0pt",fontFamily:"Symbol",color:"#222222"}}>
                Â·
                <span style={{font:"7.0pt Times New Roman"}}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <b>
                <span style={{fontFamily:"Arial,sans-serif",color:"#222222"}}>
                  To facilitate account creation and logon process.{" "}
                </span>
              </b>
              <span style={{fontFamily:"Arial,sans-serif",color:"#222222"}}>
                If you choose to link your account with us to a third-party
                account (such as your Google or Facebook account), we use the
                information you allowed us to collect from those third parties
                to facilitate account creation and logon process for the
                performance of the contract. Spe the section below headed "HOW
                DO WE HANDLE YOUR SOCIAL LOGINS?" for further information.
              </span>
            </p>

            <p
             style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",background:"white"}}
            >
              <span style={{fontFamily:"Arial,sans-serif",color:"black"}}>
                &nbsp;
              </span>
            </p>

            <p
              style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",textIndent:"-.25in",background:"white",verticalAlign:"baseline"}}
            >
              <span style={{fontSize:"10.0pt",fontFamily:"Symbol",color:"#222222"}}>
                Â·
                <span style={{font:"7.0pt Times New Roman"}}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <b>
                <span style={{fontFamily:"Arial,sans-serif",color:"#222222"}}>
                  To post testimonials.{" "}
                </span>
              </b>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
              >
                We post testimonials on our Website that may contain personal
                information. Prior to posting a testimonial, we will obtain your
                consent to use your name and the content of the testimonial. If
                you wish to update, or delete your testimonial, please contact
                us at{" "}
              </span>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"#1155CC"}}
              >
                info@arelunchbox.com
              </span>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
              >
                {" "}
                and be sure to include your name, testimonial location, and
                contact information.
              </span>
            </p>

            <p
             style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",background:"white"}}
            >
              <span style={{fontFamily:"Arial,sans-serif",color:"black"}}>
                &nbsp;
              </span>
            </p>

            <p
              style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",textIndent:"-.25in",background:"white",verticalAlign:"baseline"}}
            >
              <span style={{fontSize:"10.0pt",fontFamily:"Symbol",color:"#222222"}}>
                Â·
                <span style={{font:"7.0pt Times New Roman"}}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <b>
                <span style={{fontFamily:"Arial,sans-serif",color:"#222222"}}>
                  Request feedback.{" "}
                </span>
              </b>
              <span style={{fontFamily:"Arial,sans-serif",color:"#222222"}}>
                We may use your information to request feedback and to contact
                you about your use of our Website. To enable user-to-user
                communications. We may use your information in order to enable
                user-to-user communications with each user's consent.
              </span>
            </p>

            <p
             style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",background:"white"}}
            >
              <span style={{fontFamily:"Arial,sans-serif",color:"black"}}>
                &nbsp;
              </span>
            </p>

            <p
              style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",textIndent:"-.25in",background:"white",verticalAlign:"baseline"}}
            >
              <span style={{fontSize:"10.0pt",fontFamily:"Symbol",color:"#222222"}}>
                Â·
                <span style={{font:"7.0pt Times New Roman"}}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <b>
                <span style={{fontFamily:"Arial,sans-serif",color:"#222222"}}>
                  To manage user accounts.
                </span>
              </b>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
              >
                {" "}
                We may use your information for the purposes of managing our
                account and keeping it in working order.
              </span>
            </p>

            <p
             style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",background:"white"}}
            >
              <span style={{fontFamily:"Arial,sans-serif",color:"black"}}>
                &nbsp;
              </span>
            </p>

            <p
              style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",textIndent:"-.25in",background:"white",verticalAlign:"baseline"}}
            >
              <span style={{fontSize:"10.0pt",fontFamily:"Symbol",color:"#222222"}}>
                Â·
                <span style={{font:"7.0pt Times New Roman"}}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <b>
                <span style={{fontFamily:"Arial,sans-serif",color:"#222222"}}>
                  To send administrative information to you.
                </span>
              </b>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
              >
                {" "}
                We may use your personal information to send you product,
                service and new feature information and/or information about
                changes to our terms, conditions, and policies.
              </span>
            </p>

            <p
             style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",background:"white"}}
            >
              <span style={{fontFamily:"Arial,sans-serif",color:"black"}}>
                &nbsp;
              </span>
            </p>

            <p
              style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",textIndent:"-.25in",background:"white",verticalAlign:"baseline"}}
            >
              <span style={{fontSize:"10.0pt",fontFamily:"Symbol",color:"#222222"}}>
                Â·
                <span style={{font:"7.0pt Times New Roman"}}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <b>
                <span style={{fontFamily:"Arial,sans-serif",color:"#222222"}}>
                  To protect our Services.{" "}
                </span>
              </b>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
              >
                We may use your information as part of our efforts to keep our
                Website safe and secure (for example, for fraud monitoring and
                prevention).
              </span>
            </p>

            <p
             style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",background:"white"}}
            >
              <span style={{fontFamily:"Arial,sans-serif",color:"black"}}>
                &nbsp;
              </span>
            </p>

            <p
              style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",textIndent:"-.25in",background:"white",verticalAlign:"baseline"}}
            >
              <span style={{fontSize:"10.0pt",fontFamily:"Symbol",color:"#222222"}}>
                Â·
                <span style={{font:"7.0pt Times New Roman"}}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <b>
                <span style={{fontFamily:"Arial,sans-serif",color:"#222222"}}>
                  To enforce our terms, conditions and policies for business
                  purposes, to comply with legal and regulatory requirements or
                  in connection with our contract.
                </span>
              </b>
            </p>

            <p
             style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",background:"white"}}
            >
              <span style={{fontFamily:"Arial,sans-serif",color:"black"}}>
                &nbsp;
              </span>
            </p>

            <p
              style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",textIndent:"-.25in",background:"white",verticalAlign:"baseline"}}
            >
              <span style={{fontSize:"10.0pt",fontFamily:"Symbol",color:"#222222"}}>
                Â·
                <span style={{font:"7.0pt Times New Roman"}}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <b>
                <span style={{fontFamily:"Arial,sans-serif",color:"#222222"}}>
                  To respond to legal requests and prevent harm.{" "}
                </span>
              </b>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
              >
                If we receive a subpoena or other legal request, we may need to
                inspect the data we hold to determine how to respond.
              </span>
            </p>

            <p
             style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",background:"white"}}
            >
              <span style={{fontFamily:"Arial,sans-serif",color:"black"}}>
                &nbsp;
              </span>
            </p>

            <p
              style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",textIndent:"-.25in",background:"white",verticalAlign:"baseline"}}
            >
              <span style={{fontSize:"10.0pt",fontFamily:"Symbol",color:"#222222"}}>
                Â·
                <span style={{font:"7.0pt Times New Roman"}}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <b>
                <span style={{fontFamily:"Arial,sans-serif",color:"#222222"}}>
                  To send you marketing and promotional communications.{" "}
                </span>
              </b>
              <span style={{fontFamily:"Arial,sans-serif",color:"#222222"}}>
                We and/or our third-party marketing partners may use the
                personal information you send to us for our marketing purposes,
                if this is in accordance with your marketing preferences. For
                example, when expressing an interest in obtaining information
                about us or our Website, subscribing to marketing or otherwise
                contacting us, we will collect personal information from you.
                You can opt-out of our marketing emails at any time (see the
                "WHAT ARE YOUR PRIVACY RIGHTS?" below).
              </span>
            </p>

            <p
             style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",background:"white"}}
            >
              <span style={{fontFamily:"Arial,sans-serif",color:"black"}}>
                &nbsp;
              </span>
            </p>

            <p
              style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",textIndent:"-.25in",background:"white",verticalAlign:"baseline"}}
            >
              <span style={{fontSize:"10.0pt",fontFamily:"Symbol",color:"#222222"}}>
                Â·
                <span style={{font:"7.0pt Times New Roman"}}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <b>
                <span style={{fontFamily:"Arial,sans-serif",color:"#222222"}}>
                  Deliver targeted advertising to you.{" "}
                </span>
              </b>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
              >
                We may use your information to develop and display personalized
                content and advertising (and work with third parties who do so)
                tailored to your interests and/or location and to measure its
                effectiveness.
              </span>
            </p>

            <p
             style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",background:"white"}}
            >
              <span style={{fontFamily:"Arial,sans-serif",color:"black"}}>
                &nbsp;
              </span>
            </p>

            <p
              style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",textIndent:"-.25in",background:"white",verticalAlign:"baseline"}}
            >
              <span style={{fontSize:"10.0pt",fontFamily:"Symbol",color:"#222222"}}>
                Â·
                <span style={{font:"7.0pt Times New Roman"}}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <b>
                <span style={{fontFamily:"Arial,sans-serif",color:"#222222"}}>
                  For other business purposes.{" "}
                </span>
              </b>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
              >
                We may use your information for other business purposes, such as
                data analysis, identifying usage trends, determining the
                effectiveness of our promotional campaigns and to evaluate and
                improve our Website, products, marketing and your experience. We
                may use and store this information in aggregated and anonymized
                form so that it is not associated with individual end users and
                does not include personal information.
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <b>
                <span
                  style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
                >
                  3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
                </span>
              </b>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <b>
                <i>
                  <span
                    style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
                  >
                    In Short:{" "}
                  </span>
                </i>
              </b>
              <i>
                <span
                  style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
                >
                  We only share information with your consent, to comply with
                  laws, to provide you with services, to protect your rights, or
                  to fulfill business obligations.
                </span>
              </i>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
              >
                We may process or share your data that we hold based on the
                following legal basis:
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p
              style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",textIndent:"-.25in",background:"white",verticalAlign:"baseline"}}
            >
              <span style={{fontSize:"10.0pt",fontFamily:"Symbol",color:"#222222"}}>
                Â·
                <span style={{font:"7.0pt Times New Roman"}}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <b>
                <span style={{fontFamily:"Arial,sans-serif",color:"#222222"}}>
                  Consent:
                </span>
              </b>
              <span style={{fontFamily:"Arial,sans-serif",color:"#222222"}}>
                We may process your data if you have given us specific consent
                to use your personal information for a specific purpose
              </span>
            </p>

            <p
             style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",background:"white"}}
            >
              <span style={{fontFamily:"Arial,sans-serif",color:"black"}}>
                &nbsp;
              </span>
            </p>

            <p
              style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",textIndent:"-.25in",background:"white",verticalAlign:"baseline"}}
            >
              <span style={{fontSize:"10.0pt",fontFamily:"Symbol",color:"#222222"}}>
                Â·
                <span style={{font:"7.0pt Times New Roman"}}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <b>
                <span style={{fontFamily:"Arial,sans-serif",color:"#222222"}}>
                  Legitimate Interests:{" "}
                </span>
              </b>
              <span style={{fontFamily:"Arial,sans-serif",color:"#222222"}}>
                We may process your data when it is reasonably necessary to
                achieve our legitimate business interests.
              </span>
            </p>

            <p
             style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",background:"white"}}
            >
              <span style={{fontFamily:"Arial,sans-serif",color:"black"}}>
                &nbsp;
              </span>
            </p>

            <p
              style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",textIndent:"-.25in",background:"white",verticalAlign:"baseline"}}
            >
              <span style={{fontSize:"10.0pt",fontFamily:"Symbol",color:"#222222"}}>
                Â·
                <span style={{font:"7.0pt Times New Roman"}}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <b>
                <span style={{fontFamily:"Arial,sans-serif",color:"#222222"}}>
                  Performance of a Contract:{" "}
                </span>
              </b>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
              >
                Where we have entered into a contract with you, we may process
                your personal information to fulfill the terms of our contract.
              </span>
            </p>

            <p
             style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",background:"white"}}
            >
              <span style={{fontFamily:"Arial,sans-serif",color:"black"}}>
                &nbsp;
              </span>
            </p>

            <p
              style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",textIndent:"-.25in",background:"white",verticalAlign:"baseline"}}
            >
              <span style={{fontSize:"10.0pt",fontFamily:"Symbol",color:"#222222"}}>
                Â·
                <span style={{font:"7.0pt Times New Roman"}}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <b>
                <span style={{fontFamily:"Arial,sans-serif",color:"#222222"}}>
                  Legal Obligations:{" "}
                </span>
              </b>
              <span style={{fontFamily:"Arial,sans-serif",color:"#222222"}}>
                We may disclose your information where we are legally required
                to do so in order to comply with applicable law, governmental
                requests, a judicial proceeding, court order, or legal process,
                such as in response to a court order or a subpoena (including in
                response to public authorities to meet national security or law
                enforcement requirements).
              </span>
            </p>

            <p
             style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",background:"white"}}
            >
              <span style={{fontFamily:"Arial,sans-serif",color:"black"}}>
                &nbsp;
              </span>
            </p>

            <p
              style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",textIndent:"-.25in",background:"white",verticalAlign:"baseline"}}
            >
              <span style={{fontSize:"10.0pt",fontFamily:"Symbol",color:"#222222"}}>
                Â·
                <span style={{font:"7.0pt Times New Roman"}}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <b>
                <span style={{fontFamily:"Arial,sans-serif",color:"#222222"}}>
                  Vital Interests:{" "}
                </span>
              </b>
              <span style={{fontFamily:"Arial,sans-serif",color:"#222222"}}>
                We may disclose your information where we believe it is
                necessary to investigate, prevent, or take action regarding
                potential violations of our policies,&nbsp; suspected fraud,
                situations involving potential threats to the safety of any
                person and illegal activities, or as evidence in litigation in
                which we are involved.&nbsp;
              </span>
            </p>

            <p
             style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",background:"white"}}
            >
              <span style={{fontFamily:"Arial,sans-serif",color:"black"}}>
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
              >
                More specifically, we may need to process your data or share
                your personal information in the following situations:
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p
              style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",textIndent:"-.25in",background:"white",verticalAlign:"baseline"}}
            >
              <span style={{fontSize:"10.0pt",fontFamily:"Symbol",color:"#222222"}}>
                Â·
                <span style={{font:"7.0pt Times New Roman"}}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <b>
                <span style={{fontFamily:"Arial,sans-serif",color:"#222222"}}>
                  Business Transfers.{" "}
                </span>
              </b>
              <span style={{fontFamily:"Arial,sans-serif",color:"#222222"}}>
                We may share or transfer your information in connection with, or
                during negotiations of, any merger, sale of company assets,
                financing, or acquisition of all or a portion of our business to
                another company.
              </span>
            </p>

            <p
             style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",background:"white"}}
            >
              <span style={{fontFamily:"Arial,sans-serif",color:"black"}}>
                &nbsp;
              </span>
            </p>

            <p
              style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",textIndent:"-.25in",background:"white",verticalAlign:"baseline"}}
            >
              <span style={{fontSize:"10.0pt",fontFamily:"Symbol",color:"#222222"}}>
                Â·
                <span style={{font:"7.0pt Times New Roman"}}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <b>
                <span style={{fontFamily:"Arial,sans-serif",color:"#222222"}}>
                  Vendors, Consultants and Other Third-Party Service Providers.
                </span>
              </b>
              <span style={{fontFamily:"Arial,sans-serif",color:"#222222"}}>
                {" "}
                We may share your data with third-party vendors, service
                providers, contractors or agents who perform services for us or
                on our behalf and require access to such information to do that
                work. Examples include: payment processing, data analysis, email
                delivery, hosting services, customer service and marketing
                efforts. We may allow selected third parties to use tracking
                technology on the Website, which will enable them to collect
                data on our behalf about how you interact with our Website over
                time. This information may be used to, among other things,
                analyze and track data, determine the popularity of certain
                content, pages or features, and better understand online
                activity. Unless described in this notice, we do not share,
                sell, rent or trade any of your information with third parties
                for their promotional purposes.
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <b>
                <span
                  style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
                >
                  4. WHO WILL YOUR INFORMATION BE SHARED WITH?
                </span>
              </b>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <b>
                <i>
                  <span
                    style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
                  >
                    In Short:{" "}
                  </span>
                </i>
              </b>
              <i>
                <span
                  style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
                >
                  We only share information with the following categories of
                  third parties.&nbsp;
                </span>
              </i>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
              >
                We only share and disclose your information with the following
                categories of third parties. If we have processed your data
                based on your consent and you wish to revoke your consent,
                please contact us using the contact details provided in the
                section below titled "HOW CAN YOU CONTACT US ABOUT THIS
                NOTICE?".
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p
              style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",textIndent:"-.25in",background:"white",verticalAlign:"baseline"}}
            >
              <span style={{fontSize:"10.0pt",fontFamily:"Symbol",color:"#222222"}}>
                Â·
                <span style={{font:"7.0pt Times New Roman"}}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span style={{fontFamily:"Arial,sans-serif",color:"#222222"}}>
                Data Analytics Services
              </span>
            </p>

            <p
             style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",background:"white"}}
            >
              <span style={{fontFamily:"Arial,sans-serif",color:"black"}}>
                &nbsp;
              </span>
            </p>

            <p
              style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",textIndent:"-.25in",background:"white",verticalAlign:"baseline"}}
            >
              <span style={{fontSize:"10.0pt",fontFamily:"Symbol",color:"#222222"}}>
                Â·
                <span style={{font:"7.0pt Times New Roman"}}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span style={{fontFamily:"Arial,sans-serif",color:"#222222"}}>
                Performance Monitoring Tools
              </span>
            </p>

            <p
             style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",background:"white"}}
            >
              <span style={{fontFamily:"Arial,sans-serif",color:"black"}}>
                &nbsp;
              </span>
            </p>

            <p
              style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",textIndent:"-.25in",background:"white",verticalAlign:"baseline"}}
            >
              <span style={{fontSize:"10.0pt",fontFamily:"Symbol",color:"#222222"}}>
                Â·
                <span style={{font:"7.0pt Times New Roman"}}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span style={{fontFamily:"Arial,sans-serif",color:"#222222"}}>
                User Account Registration &amp; Authentication Services
              </span>
            </p>

            <p
             style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",background:"white"}}
            >
              <span style={{fontFamily:"Arial,sans-serif",color:"black"}}>
                &nbsp;
              </span>
            </p>

            <p
              style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",textIndent:"-.25in",background:"white",verticalAlign:"baseline"}}
            >
              <span style={{fontSize:"10.0pt",fontFamily:"Symbol",color:"#222222"}}>
                Â·
                <span style={{font:"7.0pt Times New Roman"}}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span style={{fontFamily:"Arial,sans-serif",color:"#222222"}}>
                Data Storage Service Providers
              </span>
            </p>

            <p
             style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",background:"white"}}
            >
              <span style={{fontFamily:"Arial,sans-serif",color:"black"}}>
                &nbsp;
              </span>
            </p>

            <p
              style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",textIndent:"-.25in",background:"white",verticalAlign:"baseline"}}
            >
              <span style={{fontSize:"10.0pt",fontFamily:"Symbol",color:"#222222"}}>
                Â·
                <span style={{font:"7.0pt Times New Roman"}}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span style={{fontFamily:"Arial,sans-serif",color:"#222222"}}>
                Payment Processors
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p
              style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",textIndent:"-.25in",background:"white",verticalAlign:"baseline"}}
            >
              <span style={{fontSize:"10.0pt",fontFamily:"Symbol",color:"#222222"}}>
                Â·
                <span style={{font:"7.0pt Times New Roman"}}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span style={{fontFamily:"Arial,sans-serif",color:"#222222"}}>
                Ad Networks
              </span>
            </p>

            <p
             style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",background:"white"}}
            >
              <span style={{fontFamily:"Arial,sans-serif",color:"black"}}>
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <b>
                <span
                  style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
                >
                  5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                </span>
              </b>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <b>
                <i>
                  <span
                    style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
                  >
                    In Short:{" "}
                  </span>
                </i>
              </b>
              <i>
                <span
                  style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
                >
                  If you choose to register or log in to our services using a
                  social media account, we may have access to certain
                  information about you.
                </span>
              </i>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
              >
                Our Website offers you the ability to register and login using
                your third-party social media account details (like your
                Facebook or Twitter logins). Where you choose to do this, we
                will receive certain profile information about you from your
                social media provider. The profile information we receive may
                vary depending on the social media provider concerned, but will
                often include your name; email address, friends list, profile
                picture as well as other information you choose to make public
                on such social media platform.&nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
              >
                We will use the information we receive only for the purposes
                that are described in this privacy notice or that are otherwise
                made clear to you on the relevant Website. Please note that we
                do not control, and are not responsible for, other uses of your
                personal information by your third-party social media provider.
                We recommend that you review their privacy notice to understand
                how they collect, use and share your personal information, and
                how you can set your privacy preferences on their sites and
                apps.
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <b>
                <span
                  style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
                >
                  6. HOW LONG DO WE KEEP YOUR INFORMATION?
                </span>
              </b>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <b>
                <i>
                  <span
                    style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
                  >
                    In Short:{" "}
                  </span>
                </i>
              </b>
              <i>
                <span
                  style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
                >
                  We keep your information for as long as necessary to fulfill
                  the purposes outlined in this privacy notice unless otherwise
                  required by law.
                </span>
              </i>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
              >
                We will only keep your personal information for as long as it is
                necessary for the purposes set out in this privacy notice,
                unless a longer retention period is required or permitted by law
                (such as tax, accounting or other legal requirements). No
                purpose in this notice will require us to keep your personal
                information for longer than the period of time in which users
                have an account with us.
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
              >
                When we have no ongoing legitimate business need to process your
                personal information, we will either delete or anonymize such
                information, or, if this is not possible (for example, because
                your personal information has been stored in backup archives),
                then we will securely store your personal information and
                isolate it from any further processing until deletion is
                possible.
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <b>
                <span
                  style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
                >
                  7. HOW DO WE KEEP YOUR INFORMATION SAFE?
                </span>
              </b>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <b>
                <i>
                  <span
                    style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
                  >
                    In Short:{" "}
                  </span>
                </i>
              </b>
              <i>
                <span
                  style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
                >
                  We aim to protect your personal information through a system
                  of organizational and technical security measures.
                </span>
              </i>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
              >
                We have implemented appropriate technical and organizational
                security measures designed to protect the security of any
                personal information we process. However, despite our safeguards
                and efforts to secure your information, no electronic
                transmission over the Internet or information storage technology
                can be guaranteed to be 100% secure, so we cannot promise or
                guarantee that hackers, cybercriminals, or other unauthorized
                third parties will not be able to defeat our security, and
                improperly collect, access, steal, or modify your information.
                Although we will do our best to protect your personal
                information, transmission of personal information to and from
                our Website is at your own risk. You should only access the
                Website within a secure environment.
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <b>
                <span
                  style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
                >
                  8. WHAT ARE YOUR PRIVACY RIGHTS?
                </span>
              </b>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <b>
                <i>
                  <span
                    style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
                  >
                    In Short:{" "}
                  </span>
                </i>
              </b>
              <i>
                <span
                  style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
                >
                  You may review, change, or terminate your account at any
                  time.&nbsp;
                </span>
              </i>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
              >
                If you are a resident in the EEA or UK and you believe we are
                unlawfully processing your personal information, you also have
                the right to complain to your local data protection supervisory
                authority. You can find their contact details here:{" "}
              </span>
              <span style={{ color: "black" }}>
                <a href="https://ec.europa.eu/justice/data-">
                  <span
                    style={{fontFamily:"Arial,sans-serif",color:"#1155CC"}}
                  >
                    https://ec.europa.eu/justice/data-
                  </span>
                </a>
              </span>
              <span style={{fontFamily:"Arial,sans-serif",color:"#222222"}}>
                protection/bodies/authorities/index en.htm.
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
              >
                If you are a resident in Switzerland, the contact details for
                the data protection authorities are available here:{" "}
              </span>
              <span style={{ color: "black" }}>
                <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">
                  <span style={{fontFamily:"Arial,sans-serif",color:"#1155CC"}}>
                    https://www.edoeb.admin.ch/edoeb/en/home.html
                  </span>
                </a>
              </span>
              <span style={{fontFamily:"Arial,sans-serif",color:"#222222"}}>
                .&nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
              >
                If you have questions or comments about your privacy rights, you
                may email us at{" "}
              </span>
              <span style={{ color: "black" }}>
                <a href="mailto:info@arelunchbox.com">
                  <span
                    style={{fontFamily:"Arial,sans-serif",color:"#1155CC"}}
                  >
                    info@arelunchbox.com
                  </span>
                </a>
              </span>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
              >
                .
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <b>
                <span
                  style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
                >
                  Account Information
                </span>
              </b>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
              >
                If you would at any time like to review or change the
                information in your account or terminate your account, you can:
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p
              style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",textIndent:"-.25in",background:"white",verticalAlign:"baseline"}}
            >
              <span style={{fontSize:"10.0pt",fontFamily:"Symbol",color:"#222222"}}>
                Â·
                <span style={{font:"7.0pt Times New Roman"}}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span style={{fontFamily:"Arial,sans-serif",color:"#222222"}}>
                Contact us using the contact information provided.
              </span>
            </p>

            <p
             style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",background:"white"}}
            >
              <span style={{fontFamily:"Arial,sans-serif",color:"black"}}>
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
              >
                Upon your request to terminate your account, we will deactivate
                or delete your account and information from our active
                databases. However, we may retain some information in our files
                to prevent fraud, troubleshoot problems, assist with any
                investigations, enforce our Terms of Use and/or comply with
                applicable legal requirements.
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <b>
                <u>
                  <span
                    style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
                  >
                    Opting out of email marketing:
                  </span>
                </u>
              </b>
              <span style={{fontFamily:"Arial,sans-serif",color:"#222222"}}>
                {" "}
                You can unsubscribe from our marketing email list at any time by
                clicking on the unsubscribe link in the emails that we send or
                by contacting us using the details provided below. You will then
                be removed from the marketing email list - however, we may still
                communicate with you, for example to send you service-related
                emails that are necessary for the administration and use of your
                account, to respond to service requests, or for other
                non-marketing purposes. To otherwise opt-out, you may:
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p
              style={{marginTop:"0in",marginRight:"0in",marginBottom:"0in",marginLeft:".5in",textIndent:"-.25in",background:"white",verticalAlign:"baseline"}}
            >
              <span style={{fontSize:"10.0pt",fontFamily:"Symbol",color:"#222222"}}>
                Â·
                <span style={{font:"7.0pt Times New Roman"}}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span style={{fontFamily:"Arial,sans-serif",color:"#222222"}}>
                Contact us using the contact information provided
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <b>
                <span
                  style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
                >
                  9. CONTROLS FOR DO-NOT-TRACK FEATURES
                </span>
              </b>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
              >
                Most web browsers and some mobile operating systems and mobile
                applications include a Do-Not-Track ("DNT") feature or setting
                you can activate to signal your privacy preference not to have
                data about your online browsing activities monitored and
                collected. At this stage no uniform technology standard for
                recognizing and implementing DNT signals has been finalized. As
                such, we do not currently respond to DNT browser signals or any
                other mechanism that automatically communicates your choice not
                to be tracked online. If a standard for online tracking is
                adopted that we must follow in the future, we will inform you
                about that practice in a revised version of this privacy notice.
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <b>
                <span
                  style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
                >
                  10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                </span>
              </b>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <b>
                <i>
                  <span
                    style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
                  >
                    In Short:{" "}
                  </span>
                </i>
              </b>
              <i>
                <span
                  style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
                >
                  Yes, if you are a resident of California, you are granted
                  specific rights regarding access to your personal information.
                </span>
              </i>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
              >
                California Civil Code Section 1798.83, also known as the "Shine
                The Light" law, permits our users who are California residents
                to request and obtain from us, once a year and free of charge,
                information about categories of personal information (if any) we
                disclosed to third parties for direct marketing purposes and the
                names and addresses of all third parties with which we shared
                personal information in the immediately preceding calendar year.
                If you are a California resident and would like to make such a
                request, please submit your request in writing to us using the
                contact information provided below.
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
              >
                If you are under 18 years of age, reside in California, and have
                a registered account with the Website, you have the right to
                request removal of unwanted data that you publicly post on the
                Website. To request removal of such data, please contact us
                using the contact information provided below, and include the
                email address associated with your account and a statement that
                you reside in California. We will make sure the data is not
                publicly displayed on the Website, but please be aware that the
                data may not be completely or comprehensively removed from all
                our systems (e.g. backups, etc.).
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <b>
                <span
                  style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
                >
                  11. DO WE MAKE UPDATES TO THIS NOTICE?
                </span>
              </b>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <b>
                <i>
                  <span
                    style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
                  >
                    In Short:{" "}
                  </span>
                </i>
              </b>
              <i>
                <span
                  style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
                >
                  Yes, we will update this notice as necessary to stay compliant
                  with relevant laws.
                </span>
              </i>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
              >
                We may update this privacy notice from time to time. The updated
                version will be indicated by an updated "Revised" date and the
                updated version will be effective as soon as it is accessible.
                If we make material changes to this privacy notice, we may
                notify you either by prominently posting a notice of such
                changes or by directly sending you a notification. We encourage
                you to review this privacy notice frequently to be informed of
                how we are protecting your information.
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <b>
                <span
                  style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
                >
                  12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                </span>
              </b>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
              >
                If you have questions or comments about this notice, you may
                email us at{" "}
              </span>
              <span style={{fontFamily:"Arial,sans-serif",color:"#1155CC"}}>
                info@arelunchbox.com
              </span>
              <span style={{fontFamily:"Arial,sans-serif",color:"#222222"}}>
                {" "}
                or by post to:
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
              >
                ARE LUNCHBOX LLC
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
              >
                10877 Rose Avenue
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
              >
                Apt 5
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
              >
                Los Angeles, CA 90034
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
              >
                United States
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <b>
                <span
                  style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
                >
                  13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                  FROM YOU?
                </span>
              </b>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"black"}}
              >
                &nbsp;
              </span>
            </p>

            <p style={{ margin: "0in", background: "white" }}>
              <span
                style={{fontFamily:"Arial,sans-serif",color:"#222222"}}
              >
                Based on the applicable laws of your country, you may have the
                right to request access to the personal information we collect
                from you, change that information, or delete it in some
                circumstances. To request to review, update, or delete your
                personal information, please contact{" "}
              </span>
              <span style={{fontFamily:"Arial,sans-serif",color:"#1155CC"}}>
                info@arelunchbox.com
              </span>
            </p>

            <p class="MsoNormal">
              <span
                style={{fontSize:"12.0pt",lineHeight:"107%",fontFamily:"Calibri Light,sans-serif"}}
              >
                &nbsp;
              </span>
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
