import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {useHistory} from 'react-router-dom'
import { addToCartItem, deleteCartItem, getAllCourses, getUserCourses } from '../../reducers/coursesReducer';
const PaymentSuccess = () => {
  const dispatch = useDispatch();
  const cartData = useSelector((state)=>state?.course?.cartListData);
  const user = useSelector((state) => state?.authReducer?.userData);
  const history = useHistory()
  useEffect(()=>{
    cartData && cartData?.map(async (each,i)=>{
      // console.log("each",each);
      await dispatch(deleteCartItem({docId:each.documentId})) 
    })
    dispatch(getAllCourses());
    dispatch(getUserCourses({ uid: user?.uid }))
    // dispatch(addToCartItem(null))
  },[])
    return (
        <div className="paymentDiv">
        <div className="paymentCard">
      <div className="paymentIcon">
      <svg xmlns="http://www.w3.org/2000/svg" width="65pt" height="65pt" viewBox="0 0 15 15" fill="none">
    <g>
      <path
        d="M13.7653 4.34741L6.3724 11.7394C6.16867 11.944 5.89283 12.0574 5.60439 12.0574C5.31596 12.0574 5.04012 11.9432 4.83639 11.7394L0.402122 7.30518C-0.0227126 6.88122 -0.0227126 6.19357 0.402122 5.77004C0.826523 5.34564 1.51373 5.34564 1.9377 5.77004L5.60396 9.43587L12.2284 2.8114C12.6528 2.387 13.34 2.387 13.7636 2.8114C14.1897 3.2358 14.1897 3.92345 13.7653 4.34741Z"
        fill="#33C095"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="14" height="14" fill="white" transform="translate(0.0834961 0.275391)" />
      </clipPath>
    </defs>
  </svg>
      </div>
        <h1>Payment Success</h1> 
        <p>We received your purchase request;<br/> please wait a few minutes while we load in your course content</p>
        <a href="" onClick={()=>history.push('/dashboard')}>Back to Dashboard</a>
      </div>
      </div>
    );
}

export default PaymentSuccess;
