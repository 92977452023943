import React, { useEffect } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

const TermAndConditions = () => {

   useEffect(() => {
    window.scroll(0,0);
   }, [])
  return (
    <>
      <Header />
      <section className="commonSection">
        <div className="container">
          

          <div data-custom-class="body">
            <div>
              <strong>
                <span style={{fontSize: "26px"}}>
                  <span data-custom-class="title">TERMS AND CONDITIONS</span>
                </span>
              </strong>
            </div>
            <br />
            <div>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <strong>
                  <span style={{ fontSize: "15px" }}>
                    <span data-custom-class="subtitle">
                      Last updated <bdt class="question">November 20, 2021</bdt>
                    </span>
                  </span>
                </strong>
              </span>
            </div>
            <br />
            <br />
            <br />
            <div style={{lineHeight: "1.5"}}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{color: "rgb(89, 89, 89)",fontSize:"15px"}}>
                  <span data-custom-class="body_text"></span>
                </span>
              </span>
            </div>
            <div style={{lineHeight: "1.5"}}>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span data-custom-class="heading_1">
                    <strong>1. </strong>
                    <strong>Agreement to Terms</strong>
                  </span>
                </span>
              </span>
            </div>
            <div style={{lineHeight: "1.5"}}>
              <span style={{ fontSize: "15px" }}>
                <br />
              </span>
            </div>
            <div style={{lineHeight: "1.5"}}>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      1.1{" "}
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            These Terms and Conditions constitute a legally
                            binding agreement made between you, whether
                            personally or on behalf of an entity (
                            <strong>you</strong>), and{" "}
                            <bdt class="question">
                              <strong>ARE LUNCHBOX LLC</strong>
                            </bdt>
                            <span style={{ fontSize: "15px" }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                  <strong>
                                    <bdt class="block-component"></bdt>
                                  </strong>
                                  , doing business as
                                  <strong>
                                    <bdt class="question">Lunchbox</bdt>
                                    <bdt class="statement-end-if-in-editor"></bdt>
                                  </strong>
                                  ,<strong> </strong>located at
                                  <strong>
                                    <bdt class="question">
                                      10877 Rose Avenue
                                    </bdt>
                                    ,
                                  </strong>
                                  <strong>
                                    <bdt class="block-component"></bdt>
                                    <bdt class="question">APT 5</bdt>,
                                    <bdt class="statement-end-if-in-editor"></bdt>
                                  </strong>
                                  <strong>
                                    <bdt class="question">Los Angeles</bdt>,
                                    <bdt class="block-component"></bdt>
                                    <bdt class="question">Los Angeles</bdt>,
                                    <bdt class="statement-end-if-in-editor"></bdt>
                                    <bdt class="block-component"></bdt>
                                    <bdt class="block-component"></bdt>
                                    <bdt class="question">CA</bdt>
                                    <bdt class="statement-end-if-in-editor"></bdt>
                                    <bdt class="block-component"></bdt>
                                  </strong>
                                  <strong>
                                    <bdt class="question">90035</bdt>
                                  </strong>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
              <span data-custom-class="body_text">
                <strong>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: "15px" }}>
                      <bdt class="block-component"></bdt>
                      <bdt class="block-component">, </bdt>
                    </span>
                  </span>
                </strong>
              </span>
              <strong>
                <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                  <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                    <span data-custom-class="body_text">
                      <bdt class="question">
                        United States
                        <span data-custom-class="body_text">
                          <span style={{ fontSize: "15px" }}>
                            <span data-custom-class="body_text">
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <bdt class="statement-end-if-in-editor"></bdt>
                                </span>
                                <bdt class="else-block"></bdt>
                              </span>
                            </span>
                          </span>
                        </span>
                      </bdt>
                    </span>
                  </span>
                </span>
              </strong>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            {" "}
                            <span style={{ fontSize: "15px" }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                  (<strong>we</strong>,<strong>us</strong>),
                                  concerning your access to and use of the{" "}
                                  <bdt class="question">
                                    <strong>ARE Lunchbox</strong>
                                  </bdt>{" "}
                                  <strong>
                                    (
                                    <bdt class="question">
                                      <a
                                        href="http://www.arelunchbox.com"
                                        target="_blank"
                                        data-custom-class="link"
                                      >
                                        http://www.arelunchbox.com
                                      </a>
                                    </bdt>
                                    )
                                  </strong>{" "}
                                  website as well as any related applications
                                  (the
                                  <strong>Site</strong>).
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{lineHeight: "1.5"}}>
              <span style={{ fontSize: "15px" }}>
                <br />
              </span>
            </div>
            <div style={{lineHeight: "1.5"}}>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: "15px" }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                The Site provides the following services:
                                <strong>
                                  <bdt class="question">
                                    The website offers a subscription-based
                                    model in which customers pay a fee to be
                                    granted access to content pertaining to the
                                    Architectural Licensing Exams. The content
                                    is sorted into modules and the modules are
                                    sorted into their respective courses.
                                  </bdt>
                                </strong>
                                (<strong>Services</strong>). You agree that by
                                accessing the Site and/or Services, you have
                                read, understood, and agree to be bound by all
                                of these Terms and Conditions.
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{lineHeight: "1.5"}}>
              <br />
            </div>
            <div style={{lineHeight: "1.5"}}>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: "15px" }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <strong>
                                  If you do not agree with all of these Terms
                                  and Conditions, then you are prohibited from
                                  using the Site and Services and you must
                                  discontinue use immediately
                                </strong>
                                . We recommend that you print a copy of these
                                Terms and Conditions for future reference.
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{lineHeight: "1.5"}}>
              <br />
            </div>
            <div style={{lineHeight: "1.5"}}>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <span data-custom-class="body_text">
                            <span style={{color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal", fontVariantLigatures: "normal", fontVariantCaps: "normal", fontWeight: "400", letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent:" 0px",textTransform: "none", whiteSpace: "normal",widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial", textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                              1.2
                            </span>
                            <span style={{ fontSize: "15px" }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                The supplemental policies set out in Section 1.7
                                below, as well as any supplemental terms and
                                condition or documents that may be posted on the
                                Site from time to time, are expressly
                                incorporated by reference.
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{lineHeight: "1.5"}}>
              <br />
            </div>
            <div style={{lineHeight: "1.5"}}>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <span data-custom-class="body_text">
                            <span 
                            // style={{color: "rgb(89, 89, 89)",fontFamily: "sans-serif", fontSize: "15px", fontStyle: "normal", font-variant-ligatures: "normal"; font-variant-caps: "normal"; font-weight: "400", letter-spacing: "normal", orphans: "2", textAlign: "justify", text-indent:" 0px", text-transform: "none" white-space: "normal", widows: "2", wordSpacing: "0px", -webkit-text-stroke-width: "0px", backgroundColor: "rgb(255, 255, 255)", text-decoration-style: "initial", text-decoration-color: "initial", display: "inline !important", float: "none"}}
                            >
                              1.3
                            </span>
                            <span style={{ fontSize: "15px" }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                We may make changes to these Terms and
                                Conditions at any time. The updated version of
                                these Terms and Conditions will be indicated by
                                an updated "Revised" date and the updated
                                version will be effective as soon as it is
                                accessible. You are responsible for reviewing
                                these Terms and Conditions to stay informed of
                                updates. Your continued use of the Site
                                represents that you have accepted such changes.
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{lineHeight: "1.5"}}>
              <br />
            </div>
            <div style={{lineHeight: "1.5"}}>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <span data-custom-class="body_text">
                            <span style={{color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal", fontVariantLigatures: "normal", fontVariantCaps: "normal", fontWeight: "400", letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent:" 0px",textTransform: "none", whiteSpace: "normal",widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial", textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                              1.4
                            </span>
                            <span style={{ fontSize: "15px" }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                We may update or change the Site from time to
                                time to reflect changes to our products, our
                                users' needs and/or our business priorities.
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{lineHeight: "1.5"}}>
              <br />
            </div>
            <div style={{lineHeight: "1.5"}}>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <span data-custom-class="body_text">
                            <span style={{color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal", fontVariantLigatures: "normal", fontVariantCaps: "normal", fontWeight: "400", letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent:" 0px",textTransform: "none", whiteSpace: "normal",widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial", textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                              1.5
                            </span>
                            <span style={{ fontSize: "15px" }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                Our site is directed to people residing in{" "}
                                <bdt class="block-component"></bdt>the
                                <span style={{ fontSize: "15px" }}>
                                  <span style={{ color: "rgb(127, 127, 127)" }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span data-custom-class="body_text">
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span data-custom-class="body_text">
                                            <span data-custom-class="body_text">
                                              <span
                                                style={{ fontSize: "15px" }}
                                              >
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)",
                                                  }}
                                                >
                                                  <bdt class="question">
                                                    __________
                                                  </bdt>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                                <bdt class="statement-end-if-in-editor"></bdt>.
                                The information provided on the Site is not
                                intended for distribution to or use by any
                                person or entity in any jurisdiction or country
                                where such distribution or use would be contrary
                                to law or regulation or which would subject us
                                to any registration requirement within such
                                jurisdiction or country.
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{lineHeight: "1.5"}}>
              <br />
            </div>
            <div style={{lineHeight: "1.5"}}>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <span data-custom-class="body_text">
                            <span style={{color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal", fontVariantLigatures: "normal", fontVariantCaps: "normal", fontWeight: "400", letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent:" 0px",textTransform: "none", whiteSpace: "normal",widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial", textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                              1.6
                            </span>
                            <span style={{ fontSize: "15px" }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                The Site is intended for users who are at least
                                18 years old. If you are under the age of 18,
                                you are not permitted to register for the Site
                                or use the Services without parental permission.
                                <bdt class="block-component"></bdt>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{lineHeight: "1.5"}}>
              <br />
            </div>
            <div style={{lineHeight: "1.5"}}>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          1.7{" "}
                          <span style={{ fontSize: "15px" }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                Additional policies which also apply to your use
                                of the Site include:
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{lineHeight: "1.5"}}>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span data-custom-class="body_text">
                        <span style={{ fontSize: "15px" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <bdt class="block-component"></bdt>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <ul>
              <li style={{lineHeight: "1.5",listStyleType: "square",marginLeft:"5%"}}>
                <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                  <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            Our Privacy Notice{" "}
                            <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                              <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                <span data-custom-class="body_text">
                                  <span style={{ fontSize: "15px" }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span data-custom-class="body_text">
                                        <bdt class="question">__________</bdt>,
                                        which sets out the terms on which we
                                        process any personal data we collect
                                        from you, or that you provide to us. By
                                        using the Site, you consent to such
                                        processing and you warrant that all data
                                        provided by you is accurate
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                            <span data-custom-class="body_text">
                              <span style={{ fontSize: "15px" }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  .
                                </span>
                              </span>
                            </span>
                          </span>
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: "15px" }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <bdt class="statement-end-if-in-editor"></bdt>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <div style={{lineHeight: "1.5"}}>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span data-custom-class="body_text">
                        <span style={{ fontSize: "15px" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <bdt class="block-component"></bdt>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>

              <div style={{lineHeight: "1.5"}}>
                <span style={{ fontSize: "15px" }}>
                  <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <span data-custom-class="body_text">
                          <span style={{ fontSize: "15px" }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <bdt class="block-component"></bdt>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>

                <div style={{lineHeight: "1.5"}}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <bdt class="block-component"></bdt>
                      </span>
                    </span>
                  </span>

                  <div style={{lineHeight: "1.5"}}>
                    <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                      <span data-custom-class="body_text">
                        <bdt class="block-component"></bdt>
                      </span>
                    </span>
                  </div>
                  <ul >
                    <li style={{lineHeight: "1.5",listStyleType: "square",marginLeft:"5%"}}>
                      <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                        <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: "15px" }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                  Certain parts of this Site{" "}
                                  <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                    <span data-custom-class="body_text">
                                      <span style={{color: "rgb(0, 0, 0)", fontFamily: "sans-serif", fontStyle: "normal", fontVariantLigatures: "normal", fontVariantCaps: "normal", fontWeight: "400", letterSpacing: "normal", orphans: "2", textAlign: "start", textIndent: "0px", textTransform: "none", whiteSpace: "normal", widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", backgroundColor: "rgb(255, 255, 255)", textDecorationStyle: "initial", textDecorationColor: "initial", fontSize: "15px"}}>
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          can be used only on payment of a fee.
                                          <bdt class="block-component"></bdt>
                                          <span style={{fontFamily: "sans-serif", fontSize: "15px", fontStyle: "normal", fontVariantLigatures: "normal", fontVariantCaps: "normal", fontWeight: "400", letterSpacing: "normal", orphans: "2", textAlign: "start", textIndent: "0px", textTransform: "none", whiteSpace: "normal", widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", textDecorationStyle: "initial", textDecorationColor: "initial", color: "rgb(0, 0, 0)", backgroundColor: "rgb(255, 255, 255)"}}>
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              If you wish to use such Services,
                                              you will, in addition to our Terms
                                              and Conditions, also be subject to
                                              our Terms and conditions of supply{" "}
                                              <bdt class="question">
                                                __________
                                              </bdt>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                  <span data-custom-class="body_text">
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        .
                                      </span>
                                    </span>
                                  </span>
                                </span>
                                <span data-custom-class="body_text">
                                  <span style={{ fontSize: "15px" }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <bdt class="statement-end-if-in-editor"></bdt>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                      <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                        <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: "15px" }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                  <span style={{ fontSize: "15px" }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <bdt class="statement-end-if-in-editor">
                                        <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                          <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                            <span data-custom-class="body_text">
                                              <span
                                                style={{ fontSize: "15px" }}
                                              >
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)",
                                                  }}
                                                >
                                                  <span data-custom-class="body_text">
                                                    <span
                                                      style={{
                                                        fontSize: "15px",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <bdt class="statement-end-if-in-editor">
                                                          <span
                                                            style={{
                                                              fontSize: "15px",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(127, 127, 127)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <span data-custom-class="body_text">
                                                                        <span
                                                                          style={{
                                                                            fontSize:
                                                                              "15px",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                            }}
                                                                          >
                                                                            <bdt class="statement-end-if-in-editor"></bdt>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </bdt>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </bdt>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(127, 127, 127)" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text"></span>
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span data-custom-class="heading_1">
                          <strong>2.</strong>
                          <strong>Acceptable Use</strong>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span data-custom-class="heading_1">
                          <span style={{ fontSize: "15px" }}>
                            <span style={{color: "rgb(0, 0, 0)"}}>
                              <span data-custom-class="body_text">
                                <bdt class="block-component"></bdt>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            2.1{" "}
                            <span data-custom-class="body_text">
                              <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                You may not access{" "}
                                <span data-custom-class="body_text">
                                  <span style={{ fontSize: "15px" }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      or use the Site for any purpose other than
                                      that for which we make the site and our
                                      services available. The Site may not be
                                      used in connection with any commercial
                                      endeavors except those that are
                                      specifically endorsed or approved by us.
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            2.2{" "}
                            <span data-custom-class="body_text">
                              <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                As a user of this Site, you agree not to:
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span data-custom-class="body_text">
                              <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                <bdt class="forloop-component"></bdt>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <ul>
                    <li style={{lineHeight: "1.5",listStyleType: "square",marginLeft:"5%"}}>
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(127, 127, 127)" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <span data-custom-class="body_text">
                                <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                  <bdt class="question">
                                    Systematically retrieve data or other
                                    content from the Site to a compile database
                                    or directory without written permission from
                                    us
                                  </bdt>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                  <div>
                    <bdt class="block-component">
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ fontSize: "15px" }}>
                          <span style={{ color: "rgb(127, 127, 127)" }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(127, 127, 127)" }}
                                      >
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span data-custom-class="body_text">
                                            <span data-custom-class="body_text">
                                              <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                                <bdt class="forloop-component"></bdt>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </bdt>
                  </div>
                  <ul>
                    <li style={{lineHeight: "1.5",listStyleType: "square",marginLeft:"5%"}}>
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(127, 127, 127)" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <span data-custom-class="body_text">
                                <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                  <bdt class="question">
                                    Make any unauthorized use of the Site,
                                    including collecting usernames and/or email
                                    addresses of users to send unsolicited email
                                    or creating user accounts under false
                                    pretenses
                                  </bdt>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                  <div>
                    <bdt class="block-component">
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ fontSize: "15px" }}>
                          <span style={{ color: "rgb(127, 127, 127)" }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(127, 127, 127)" }}
                                      >
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span data-custom-class="body_text">
                                            <span data-custom-class="body_text">
                                              <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                                <bdt class="forloop-component"></bdt>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </bdt>
                  </div>
                  <ul>
                    <li style={{lineHeight: "1.5",listStyleType: "square",marginLeft:"5%"}}>
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(127, 127, 127)" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <span data-custom-class="body_text">
                                <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                  <bdt class="question">
                                    Use the Site to advertise or sell goods and
                                    services
                                  </bdt>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                  <div>
                    <bdt class="block-component">
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ fontSize: "15px" }}>
                          <span style={{ color: "rgb(127, 127, 127)" }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(127, 127, 127)" }}
                                      >
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span data-custom-class="body_text">
                                            <span data-custom-class="body_text">
                                              <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                                <bdt class="forloop-component"></bdt>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </bdt>
                  </div>
                  <ul>
                    <li style={{lineHeight: "1.5",listStyleType: "square",marginLeft:"5%"}}>
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(127, 127, 127)" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <span data-custom-class="body_text">
                                <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                  <bdt class="question">
                                    Circumvent, disable, or otherwise interfere
                                    with security-related features of the Site,
                                    including features that prevent or restrict
                                    the use or copying of any content or enforce
                                    limitations on the use
                                  </bdt>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                  <div>
                    <bdt class="block-component">
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ fontSize: "15px" }}>
                          <span style={{ color: "rgb(127, 127, 127)" }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(127, 127, 127)" }}
                                      >
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span data-custom-class="body_text">
                                            <span data-custom-class="body_text">
                                              <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                                <bdt class="forloop-component"></bdt>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </bdt>
                  </div>
                  <ul>
                    <li style={{lineHeight: "1.5",listStyleType: "square",marginLeft:"5%"}}>
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(127, 127, 127)" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <span data-custom-class="body_text">
                                <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                  <bdt class="question">
                                    Trick, defraud, or mislead us and other
                                    users, especially in any attempt to learn
                                    sensitive account information such as user
                                    passwords
                                  </bdt>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                  <div>
                    <bdt class="block-component">
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ fontSize: "15px" }}>
                          <span style={{ color: "rgb(127, 127, 127)" }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(127, 127, 127)" }}
                                      >
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span data-custom-class="body_text">
                                            <span data-custom-class="body_text">
                                              <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                                <bdt class="forloop-component"></bdt>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </bdt>
                  </div>
                  <ul>
                    <li style={{lineHeight: "1.5",listStyleType: "square",marginLeft:"5%"}}>
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(127, 127, 127)" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <span data-custom-class="body_text">
                                <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                  <bdt class="question">
                                    Make improper use of our support services,
                                    or submit false reports of abuse or
                                    misconduct
                                  </bdt>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                  <div>
                    <bdt class="block-component">
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ fontSize: "15px" }}>
                          <span style={{ color: "rgb(127, 127, 127)" }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(127, 127, 127)" }}
                                      >
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span data-custom-class="body_text">
                                            <span data-custom-class="body_text">
                                              <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                                <bdt class="forloop-component"></bdt>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </bdt>
                  </div>
                  <ul>
                    <li style={{lineHeight: "1.5",listStyleType: "square",marginLeft:"5%"}}>
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(127, 127, 127)" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <span data-custom-class="body_text">
                                <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                  <bdt class="question">
                                    Engage in any automated use of the system,
                                    such as using scripts to send comments or
                                    messages, or using any data mining, robots,
                                    or similar data gathering and extraction
                                    tools
                                  </bdt>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                  <div>
                    <bdt class="block-component">
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ fontSize: "15px" }}>
                          <span style={{ color: "rgb(127, 127, 127)" }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(127, 127, 127)" }}
                                      >
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span data-custom-class="body_text">
                                            <span data-custom-class="body_text">
                                              <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                                <bdt class="forloop-component"></bdt>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </bdt>
                  </div>
                  <ul>
                    <li style={{lineHeight: "1.5",listStyleType: "square",marginLeft:"5%"}}>
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(127, 127, 127)" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <span data-custom-class="body_text">
                                <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                  <bdt class="question">
                                    Interfere with, disrupt, or create an undue
                                    burden on the Site or the networks and
                                    services connected to the Site
                                  </bdt>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                  <div>
                    <bdt class="block-component">
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ fontSize: "15px" }}>
                          <span style={{ color: "rgb(127, 127, 127)" }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(127, 127, 127)" }}
                                      >
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span data-custom-class="body_text">
                                            <span data-custom-class="body_text">
                                              <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                                <bdt class="forloop-component"></bdt>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </bdt>
                  </div>
                  <ul>
                    <li style={{lineHeight: "1.5",listStyleType: "square",marginLeft:"5%"}}>
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(127, 127, 127)" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <span data-custom-class="body_text">
                                <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                  <bdt class="question">
                                    Attempt to impersonate another user or
                                    person, or use the username of another user
                                  </bdt>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                  <div>
                    <bdt class="block-component">
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ fontSize: "15px" }}>
                          <span style={{ color: "rgb(127, 127, 127)" }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(127, 127, 127)" }}
                                      >
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span data-custom-class="body_text">
                                            <span data-custom-class="body_text">
                                              <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                                <bdt class="forloop-component"></bdt>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </bdt>
                  </div>
                  <ul>
                    <li style={{lineHeight: "1.5",listStyleType: "square",marginLeft:"5%"}}>
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(127, 127, 127)" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <span data-custom-class="body_text">
                                <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                  <bdt class="question">
                                    Use any information obtained from the Site
                                    in order to harass, abuse, or harm another
                                    person
                                  </bdt>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                  <div>
                    <bdt class="block-component">
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ fontSize: "15px" }}>
                          <span style={{ color: "rgb(127, 127, 127)" }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(127, 127, 127)" }}
                                      >
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span data-custom-class="body_text">
                                            <span data-custom-class="body_text">
                                              <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                                <bdt class="forloop-component"></bdt>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </bdt>
                  </div>
                  <ul>
                    <li style={{lineHeight: "1.5",listStyleType: "square",marginLeft:"5%"}}>
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(127, 127, 127)" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <span data-custom-class="body_text">
                                <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                  <bdt class="question">
                                    Use the Site or our content as part of any
                                    effort to compete with us or to create a
                                    revenue-generating endeavor or commercial
                                    enterprise
                                  </bdt>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                  <div>
                    <bdt class="block-component">
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ fontSize: "15px" }}>
                          <span style={{ color: "rgb(127, 127, 127)" }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(127, 127, 127)" }}
                                      >
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span data-custom-class="body_text">
                                            <span data-custom-class="body_text">
                                              <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                                <bdt class="forloop-component"></bdt>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </bdt>
                  </div>
                  <ul>
                    <li style={{lineHeight: "1.5",listStyleType: "square",marginLeft:"5%"}}>
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(127, 127, 127)" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <span data-custom-class="body_text">
                                <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                  <bdt class="question">
                                    Decipher, decompile, disassemble, or reverse
                                    engineer any of the software comprising or
                                    in any way making up a part of the Site
                                  </bdt>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                  <div>
                    <bdt class="block-component">
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ fontSize: "15px" }}>
                          <span style={{ color: "rgb(127, 127, 127)" }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(127, 127, 127)" }}
                                      >
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span data-custom-class="body_text">
                                            <span data-custom-class="body_text">
                                              <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                                <bdt class="forloop-component"></bdt>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </bdt>
                  </div>
                  <ul>
                    <li style={{lineHeight: "1.5",listStyleType: "square",marginLeft:"5%"}}>
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(127, 127, 127)" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <span data-custom-class="body_text">
                                <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                  <bdt class="question">
                                    Attempt to access any portions of the Site
                                    that you are restricted from accessing
                                  </bdt>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                  <div>
                    <bdt class="block-component">
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ fontSize: "15px" }}>
                          <span style={{ color: "rgb(127, 127, 127)" }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(127, 127, 127)" }}
                                      >
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span data-custom-class="body_text">
                                            <span data-custom-class="body_text">
                                              <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                                <bdt class="forloop-component"></bdt>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </bdt>
                  </div>
                  <ul>
                    <li style={{lineHeight: "1.5",listStyleType: "square",marginLeft:"5%"}}>
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(127, 127, 127)" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <span data-custom-class="body_text">
                                <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                  <bdt class="question">
                                    Harass, annoy, intimidate, or threaten any
                                    of our employees, agents, or other users
                                  </bdt>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                  <div>
                    <bdt class="block-component">
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ fontSize: "15px" }}>
                          <span style={{ color: "rgb(127, 127, 127)" }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(127, 127, 127)" }}
                                      >
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span data-custom-class="body_text">
                                            <span data-custom-class="body_text">
                                              <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                                <bdt class="forloop-component"></bdt>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </bdt>
                  </div>
                  <ul>
                    <li style={{lineHeight: "1.5",listStyleType: "square",marginLeft:"5%"}}>
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(127, 127, 127)" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <span data-custom-class="body_text">
                                <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                  <bdt class="question">
                                    Delete the copyright or other proprietary
                                    rights notice from any of the content
                                  </bdt>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                  <div>
                    <bdt class="block-component">
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ fontSize: "15px" }}>
                          <span style={{ color: "rgb(127, 127, 127)" }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(127, 127, 127)" }}
                                      >
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span data-custom-class="body_text">
                                            <span data-custom-class="body_text">
                                              <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                                <bdt class="forloop-component"></bdt>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </bdt>
                  </div>
                  <ul>
                    <li style={{lineHeight: "1.5",listStyleType: "square",marginLeft:"5%"}}>
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(127, 127, 127)" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <span data-custom-class="body_text">
                                <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                  <bdt class="question">
                                    Copy or adapt the Siteâ€™s software,
                                    including but not limited to Flash, PHP,
                                    HTML, JavaScript, or other code
                                  </bdt>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                  <div>
                    <bdt class="block-component">
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ fontSize: "15px" }}>
                          <span style={{ color: "rgb(127, 127, 127)" }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(127, 127, 127)" }}
                                      >
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span data-custom-class="body_text">
                                            <span data-custom-class="body_text">
                                              <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                                <bdt class="forloop-component"></bdt>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </bdt>
                  </div>
                  <ul>
                    <li style={{lineHeight: "1.5",listStyleType: "square",marginLeft:"5%"}}>
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(127, 127, 127)" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <span data-custom-class="body_text">
                                <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                  <bdt class="question">
                                    Upload or transmit (or attempt to upload or
                                    to transmit) viruses, Trojan horses, or
                                    other material that interferes with any
                                    partyâ€™s uninterrupted use and enjoyment of
                                    the Site, or any material that acts as a
                                    passive or active information collection or
                                    transmission mechanism
                                  </bdt>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                  <div>
                    <bdt class="block-component">
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ fontSize: "15px" }}>
                          <span style={{ color: "rgb(127, 127, 127)" }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(127, 127, 127)" }}
                                      >
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span data-custom-class="body_text">
                                            <span data-custom-class="body_text">
                                              <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                                <bdt class="forloop-component"></bdt>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </bdt>
                  </div>
                  <ul>
                    <li style={{lineHeight: "1.5",listStyleType: "square",marginLeft:"5%"}}>
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(127, 127, 127)" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <span data-custom-class="body_text">
                                <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                  <bdt class="question">
                                    Use, launch, or engage in any automated use
                                    of the system, such as using scripts to send
                                    comments or messages, robots, scrapers,
                                    offline readers, or similar data gathering
                                    and extraction tools
                                  </bdt>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                  <div>
                    <bdt class="block-component">
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ fontSize: "15px" }}>
                          <span style={{ color: "rgb(127, 127, 127)" }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(127, 127, 127)" }}
                                      >
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span data-custom-class="body_text">
                                            <span data-custom-class="body_text">
                                              <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                                <bdt class="forloop-component"></bdt>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </bdt>
                  </div>
                  <ul>
                    <li style={{lineHeight: "1.5",listStyleType: "square",marginLeft:"5%"}}>
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(127, 127, 127)" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <span data-custom-class="body_text">
                                <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                  <bdt class="question">
                                    Disparage, tarnish, or otherwise harm, in
                                    our opinion, us and/or the Site
                                  </bdt>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                  <div>
                    <bdt class="block-component">
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ fontSize: "15px" }}>
                          <span style={{ color: "rgb(127, 127, 127)" }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(127, 127, 127)" }}
                                      >
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span data-custom-class="body_text">
                                            <span data-custom-class="body_text">
                                              <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                                <bdt class="forloop-component"></bdt>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </bdt>
                  </div>
                  <ul>
                    <li style={{lineHeight: "1.5",listStyleType: "square",marginLeft:"5%"}}>
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(127, 127, 127)" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <span data-custom-class="body_text">
                                <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                  <bdt class="question">
                                    Use the Site in a manner inconsistent with
                                    any applicable laws or regulations
                                  </bdt>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                  <div>
                    <bdt class="block-component">
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ fontSize: "15px" }}>
                          <span style={{ color: "rgb(127, 127, 127)" }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(127, 127, 127)" }}
                                      >
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span data-custom-class="body_text">
                                            <span data-custom-class="body_text">
                                              <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                                <bdt class="forloop-component"></bdt>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </bdt>
                  </div>
                  <ul>
                    <li style={{lineHeight: "1.5",listStyleType: "square",marginLeft:"5%"}}>
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(127, 127, 127)" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <span data-custom-class="body_text">
                                <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                  <bdt class="question">
                                    Threaten users with negative feedback or
                                    offering services solely to give positive
                                    feedback to users
                                  </bdt>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                  <div>
                    <bdt class="block-component">
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ fontSize: "15px" }}>
                          <span style={{ color: "rgb(127, 127, 127)" }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(127, 127, 127)" }}
                                      >
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span data-custom-class="body_text">
                                            <span data-custom-class="body_text">
                                              <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                                <bdt class="forloop-component"></bdt>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </bdt>
                  </div>
                  <ul>
                    <li style={{lineHeight: "1.5",listStyleType: "square",marginLeft:"5%"}}>
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(127, 127, 127)" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <span data-custom-class="body_text">
                                <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                  <bdt class="question">
                                    Advertise products or services not intended
                                    by us
                                  </bdt>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                  <div>
                    <bdt class="block-component">
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ fontSize: "15px" }}>
                          <span style={{ color: "rgb(127, 127, 127)" }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(127, 127, 127)" }}
                                      >
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span data-custom-class="body_text">
                                            <span data-custom-class="body_text">
                                              <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                                <bdt class="forloop-component"></bdt>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </bdt>
                  </div>
                  <ul>
                    <li style={{lineHeight: "1.5",listStyleType: "square",marginLeft:"5%"}}>
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(127, 127, 127)" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <span data-custom-class="body_text">
                                <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                  Falsely imply{" "}
                                  <span data-custom-class="body_text">
                                    <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                      a relationship with us or another company
                                      with whom you do not have a relationship
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                  <div>
                    <span style={{ fontSize: "15px" }}>
                      <bdt class="statement-end-if-in-editor"></bdt>
                    </span>
                    <span style={{ fontSize: "15px" }}>
                      <bdt class="block-component"></bdt>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span data-custom-class="heading_1">
                          <strong>
                            <span data-custom-class="heading_1">3. </span>
                          </strong>
                          <strong>Information you provide to us</strong>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            3.1 You represent{" "}
                            <span data-custom-class="body_text">
                              <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                and warrant that: (a) all registration
                                information you submit will be true, accurate,
                                current, and complete and relate to you and not
                                a third party; (b) you will maintain the
                                accuracy of such information and promptly update
                                such information as necessary; (c) you will keep
                                your password confidential and will be
                                responsible for all use of your password and
                                account; (d) you have the legal capacity and you
                                agree to comply with these Terms and Conditions;
                                and (e) you are not a minor in the jurisdiction
                                in which you reside, or if a minor, you have
                                received parental permission to use the Site.
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                    If you know or suspect that anyone other
                                    than you knows your user information (such
                                    as an identification code or user name)
                                    and/or password you must promptly notify us
                                    at{" "}
                                    <bdt class="question">
                                      support@arelunchbox.com
                                    </bdt>
                                  </span>
                                </span>
                                .
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            3.2 If you provide{" "}
                            <span data-custom-class="body_text">
                              <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                any information that is untrue, inaccurate, not
                                current or incomplete, we may suspend or
                                terminate your account. We may remove or change
                                a user name you select if we determine that such
                                user name is inappropriate.
                                <span data-custom-class="body_text">
                                  <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                    <bdt class="block-component"></bdt>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            3.3 As part of{" "}
                            <span data-custom-class="body_text">
                              <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                the functionality of the Site, you may link your
                                account with online accounts you may have with
                                third party service providers (each such
                                account, a <strong>Third Party Account</strong>)
                                by either: (a) providing your Third Party
                                Account login information through the Site; or
                                (b) allowing us to access your Third Party
                                Account, as is permitted under the applicable
                                terms and conditions that govern your use of
                                each Third Party Account.
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                    You represent that you are entitled to
                                    disclose your Third Party Account login
                                    information to us and/or grant us access to
                                    your Third Party Account without breach by
                                    you of any of the terms and conditions that
                                    govern your use of the applicable Third
                                    Party Account and without obligating us to
                                    pay any fees or making us subject to any
                                    usage limitations imposed by such third
                                    party service providers.
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            3.4 By granting us{" "}
                            <span data-custom-class="body_text">
                              <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                access to any Third Party Accounts, you
                                understand that (a) we may access, make
                                available and store (if applicable) any content
                                that you have provided to and stored in your
                                Third Party Account (the "
                                <strong>Social Network Content</strong>") so
                                that it is available on and through the Site via
                                your account, including without limitation any
                                friend lists; and (b) we may submit and receive
                                additional information to your Third Party
                                Account to the extent you are notified when you
                                link your account with the Third Party Account.
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                    Depending on the Third Party Accounts you
                                    choose and subject to the privacy settings
                                    that you have set in such Third Party
                                    Accounts, personally identifiable
                                    information that you post to your Third
                                    Party Accounts may be available on and
                                    through your account on the Site. Please
                                    note that if a Third Party Account or
                                    associated service becomes unavailable or
                                    our access to such Third Party Account is
                                    terminated by the third party service
                                    provider, then Social Network Content may no
                                    longer be available on and through the Site.
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                    You will have the ability to disable the
                                    connection between your account on the Site
                                    and your Third Party Accounts at any time.{" "}
                                    <strong>
                                      Please note that your relationship with
                                      the third party service providers
                                      associated with your third party accounts
                                      is governed solely by your agreement(s)
                                      with such third party service providers.
                                    </strong>{" "}
                                    We make no effort to review any Social
                                    Network Content for any purpose, including
                                    but not limited to, for accuracy, legality
                                    or non-infringement, and we are not
                                    responsible for any Social Network Content.
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                    You acknowledge and agree that we may access
                                    your email address book associated with a
                                    Third Party Account and your contacts list
                                    stored on your mobile device or tablet
                                    computer solely for purposes of identifying
                                    and informing you of those contacts who have
                                    also registered to use the Site. At your
                                    email request to{" "}
                                    <bdt class="question">
                                      support@arelunchbox.com
                                    </bdt>{" "}
                                    or through your account settings (if
                                    applicable), we will deactivate the
                                    connection between the Site and your Third
                                    Party Account and attempt to delete any
                                    information stored on our servers that was
                                    obtained through such Third Party Account,
                                    except the username and profile picture that
                                    became associated with your account.
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(127, 127, 127)" }}
                                      >
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span data-custom-class="body_text">
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              <span data-custom-class="body_text">
                                                <span
                                                  style={{ fontSize: "15px" }}
                                                >
                                                  <span
                                                    style={{
                                                      color:
                                                        "rgb(127, 127, 127)",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <bdt class="statement-end-if-in-editor"></bdt>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: "15px" }}>
                              <span style={{ color: "rgb(127, 127, 127)" }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span data-custom-class="body_text">
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span data-custom-class="body_text">
                                        <span style={{ fontSize: "15px" }}>
                                          <span
                                            style={{
                                              color: "rgb(127, 127, 127)",
                                            }}
                                          >
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              <span data-custom-class="body_text">
                                                <bdt class="statement-end-if-in-editor"></bdt>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span data-custom-class="heading_1">
                          <span data-custom-class="body_text">
                            <bdt
                              class="block-component"
                              style={{ fontSize: "15px" }}
                            ></bdt>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span data-custom-class="heading_1">
                          <strong>4.</strong>
                          <strong>Content you provide to us</strong>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            4.1{" "}
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                    There may be opportunities for you to post
                                    content to the Site or send feedback to us (
                                    <strong>User Content</strong>). You
                                    understand and agree that your User Content
                                    may be viewed by other users on the Site,
                                    and that they may be able to see who has
                                    posted that User Content.
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                    <bdt class="block-component"></bdt>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                    <span data-custom-class="body_text">
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span style={{ fontSize: "15px" }}>
                          <bdt class="block-component"></bdt>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal", fontVariantLigatures: "normal", fontVariantCaps: "normal", fontWeight: "400", letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent:" 0px",textTransform: "none", whiteSpace: "normal",widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial", textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                    4.2 You warrant{" "}
                                    <span data-custom-class="body_text">
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <span style={{fontSize:"15px"}}>
                                          that any User Content does comply with
                                          our Acceptable Use Policy, and you
                                          will be liable to us and indemnify us
                                          for any breach of that warranty. This
                                          means you will be responsible for any
                                          loss or damage we suffer as a result
                                          of your breach of this warranty.
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal", fontVariantLigatures: "normal", fontVariantCaps: "normal", fontWeight: "400", letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent:" 0px",textTransform: "none", whiteSpace: "normal",widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial", textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                    4.3 We have the right{" "}
                                    <span data-custom-class="body_text">
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <span style={{fontSize:"15px"}}>
                                          to remove any User Content you put on
                                          the Site if, in our opinion, such User
                                          Content does not comply with the
                                          Acceptable Use Policy.
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal", fontVariantLigatures: "normal", fontVariantCaps: "normal", fontWeight: "400", letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent:" 0px",textTransform: "none", whiteSpace: "normal",widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial", textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                    4.4 We are not responsible and accept no
                                    liability for any User Content including any
                                    such content that contains incorrect
                                    information or is defamatory or loss of User
                                    Content. We accept no obligation to screen,
                                    edit or monitor any User Content but we
                                    reserve the right to remove, screen and/or
                                    edit any User Content without notice and at
                                    any time. User Content has not been verified
                                    or approved by us and the views expressed by
                                    other users on the Site do not represent our
                                    views or values.
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal", fontVariantLigatures: "normal", fontVariantCaps: "normal", fontWeight: "400", letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent:" 0px",textTransform: "none", whiteSpace: "normal",widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial", textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                    4.5 If you wish to complain{" "}
                                  </span>
                                  <span data-custom-class="body_text">
                                    <span style={{fontSize: "15px",color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal",fontVariantLigatures: "normal",fontVariantCaps: "normal",fontWeight:"400",letterSpacing: "normal", orphans: "2", textAlign: "justify",textIndent: "0px",textTransform: "none",whiteSpace: "normal",widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial", textDecorationColor: "initial",display: "inline !important", float: "none"}}>
                                      about User Content uploaded by other users
                                      please contact us{" "}
                                      <bdt class="block-component"></bdt>at{" "}
                                      <bdt class="question">
                                        support@arelunchbox.com
                                      </bdt>{" "}
                                      or use the take down or report button.
                                    </span>
                                    <span style={{fontSize: "15px",color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal",fontVariantLigatures: "normal",fontVariantCaps: "normal",fontWeight: "400",letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent: "0px",textTransform: "none",whiteSpace: "normal",widows: "2",wordSpacing: "0px",webkitTextStrokeWidth: "0px",backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial",textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                      <span
                                        style={{ color: "rgb(127, 127, 127)" }}
                                      >
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span data-custom-class="body_text">
                                            <span data-custom-class="body_text">
                                              <span
                                                style={{ fontSize: "15px" }}
                                              >
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)",
                                                  }}
                                                >
                                                  <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                                    <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                                      <span data-custom-class="body_text">
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize:
                                                                    "15px",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <bdt class="statement-end-if-in-editor"></bdt>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal", fontVariantLigatures: "normal", fontVariantCaps: "normal", fontWeight: "400", letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent:" 0px",textTransform: "none", whiteSpace: "normal",widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial", textDecorationColor: "initial",display: "inline !important",float: "none"}}></span>
                                  <span style={{fontSize: "15px",color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal",fontVariantLigatures: "normal",fontVariantCaps: "normal",fontWeight: "400",letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent: "0px",textTransform: "none",whiteSpace: "normal",widows: "2",wordSpacing: "0px",webkitTextStrokeWidth: "0px",backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial",textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                    <span
                                      style={{ color: "rgb(127, 127, 127)" }}
                                    >
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <span data-custom-class="body_text">
                                          <span
                                            style={{ color: "rgb(89, 89, 89)" }}
                                          >
                                            <span data-custom-class="body_text">
                                              <span data-custom-class="body_text">
                                                <span data-custom-class="body_text">
                                                  <span style={{fontSize: "15px",color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal",fontVariantLigatures: "normal",fontVariantCaps: "normal",fontWeight:"400",letterSpacing: "normal", orphans: "2", textAlign: "justify",textIndent: "0px",textTransform: "none",whiteSpace: "normal",widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial", textDecorationColor: "initial",display: "inline !important", float: "none"}}>
                                                    <span
                                                      style={{
                                                        fontSize: "15px",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(127, 127, 127)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize:
                                                                    "15px",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                                                    <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                                                      <span data-custom-class="body_text">
                                                                        <span
                                                                          style={{
                                                                            fontSize:
                                                                              "15px",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <span
                                                                                style={{
                                                                                  fontSize:
                                                                                    "15px",
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    color:
                                                                                      "rgb(89, 89, 89)",
                                                                                  }}
                                                                                >
                                                                                  <bdt class="statement-end-if-in-editor"></bdt>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span data-custom-class="heading_1">
                          <strong>5.</strong>
                          <strong>Our content</strong>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal", fontVariantLigatures: "normal", fontVariantCaps: "normal", fontWeight: "400", letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent:" 0px",textTransform: "none", whiteSpace: "normal",widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial", textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                    5.1 Unless otherwise{" "}
                                  </span>
                                  <span data-custom-class="body_text">
                                    <span style={{fontSize: "15px",color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal",fontVariantLigatures: "normal",fontVariantCaps: "normal",fontWeight: "400",letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent: "0px",textTransform: "none",whiteSpace: "normal",widows: "2",wordSpacing: "0px",webkitTextStrokeWidth: "0px",backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial",textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        indicated, the Site and Services
                                        including source code, databases,
                                        functionality, software, website
                                        designs, audio, video, text,
                                        photographs, and graphics on the Site (
                                        <strong>Our Content</strong>) are owned
                                        or licensed to us, and are protected by
                                        copyright and trade mark laws.
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal", fontVariantLigatures: "normal", fontVariantCaps: "normal", fontWeight: "400", letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent:" 0px",textTransform: "none", whiteSpace: "normal",widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial", textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                    5.2 Except as expressly provided{" "}
                                  </span>
                                  <span data-custom-class="body_text">
                                    <span style={{fontSize: "15px",color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal",fontVariantLigatures: "normal",fontVariantCaps: "normal",fontWeight: "400",letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent: "0px",textTransform: "none",whiteSpace: "normal",widows: "2",wordSpacing: "0px",webkitTextStrokeWidth: "0px",backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial",textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        in these Terms and Conditions, no part
                                        of the Site, Services or Our Content may
                                        be copied, reproduced, aggregated,
                                        republished, uploaded, posted, publicly
                                        displayed, encoded, translated,
                                        transmitted, distributed, sold,
                                        licensed, or otherwise exploited for any
                                        commercial purpose whatsoever, without
                                        our express prior written permission.
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal", fontVariantLigatures: "normal", fontVariantCaps: "normal", fontWeight: "400", letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent:" 0px",textTransform: "none", whiteSpace: "normal",widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial", textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                    5.3 Provided that you{" "}
                                  </span>
                                  <span data-custom-class="body_text">
                                    <span style={{fontSize: "15px",color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal",fontVariantLigatures: "normal",fontVariantCaps: "normal",fontWeight: "400",letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent: "0px",textTransform: "none",whiteSpace: "normal",widows: "2",wordSpacing: "0px",webkitTextStrokeWidth: "0px",backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial",textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        are eligible to use the Site, you are
                                        granted a limited licence to access and
                                        use the Site and Our Content and to
                                        download or print a copy of any portion
                                        of the Content to which you have
                                        properly gained access solely for your
                                        personal, non-commercial use.
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal", fontVariantLigatures: "normal", fontVariantCaps: "normal", fontWeight: "400", letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent:" 0px",textTransform: "none", whiteSpace: "normal",widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial", textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                    5.4 You shall not{" "}
                                  </span>
                                  <span data-custom-class="body_text">
                                    <span style={{fontSize: "15px",color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal",fontVariantLigatures: "normal",fontVariantCaps: "normal",fontWeight: "400",letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent: "0px",textTransform: "none",whiteSpace: "normal",widows: "2",wordSpacing: "0px",webkitTextStrokeWidth: "0px",backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial",textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        (a) try to gain unauthorised access to
                                        the Site or any networks, servers or
                                        computer systems connected to the Site;
                                        and/or (b) make for any purpose
                                        including error correction, any
                                        modifications, adaptions, additions or
                                        enhancements to the Site or Our Content,
                                        including the modification of the paper
                                        or digital copies you may have
                                        downloaded.
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal", fontVariantLigatures: "normal", fontVariantCaps: "normal", fontWeight: "400", letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent:" 0px",textTransform: "none", whiteSpace: "normal",widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial", textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                    5.5 We shall{" "}
                                  </span>
                                  <span data-custom-class="body_text">
                                    <span style={{fontSize: "15px",color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal",fontVariantLigatures: "normal",fontVariantCaps: "normal",fontWeight: "400",letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent: "0px",textTransform: "none",whiteSpace: "normal",widows: "2",wordSpacing: "0px",webkitTextStrokeWidth: "0px",backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial",textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        (a) prepare the Site and Our Content
                                        with reasonable skill and care; and (b)
                                        use industry standard virus detection
                                        software to try to block the uploading
                                        of content to the Site that contains
                                        viruses.
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal", fontVariantLigatures: "normal", fontVariantCaps: "normal", fontWeight: "400", letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent:" 0px",textTransform: "none", whiteSpace: "normal",widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial", textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                    5.6 The content on{" "}
                                  </span>
                                  <span data-custom-class="body_text">
                                    <span style={{fontSize: "15px",color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal",fontVariantLigatures: "normal",fontVariantCaps: "normal",fontWeight: "400",letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent: "0px",textTransform: "none",whiteSpace: "normal",widows: "2",wordSpacing: "0px",webkitTextStrokeWidth: "0px",backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial",textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        the Site is provided for general
                                        information only. It is not intended to
                                        amount to advice on which you should
                                        rely. You must obtain professional or
                                        specialist advice before taking, or
                                        refraining from taking, any action on
                                        the basis of the content on the Site.
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal", fontVariantLigatures: "normal", fontVariantCaps: "normal", fontWeight: "400", letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent:" 0px",textTransform: "none", whiteSpace: "normal",widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial", textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                    5.7 Although{" "}
                                  </span>
                                  <span style={{fontSize: "15px",color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal",fontVariantLigatures: "normal",fontVariantCaps: "normal",fontWeight: "400",letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent: "0px",textTransform: "none",whiteSpace: "normal",widows: "2",wordSpacing: "0px",webkitTextStrokeWidth: "0px",backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial",textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                    <span data-custom-class="body_text">
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        we make reasonable efforts to update the
                                        information on our site, we make no
                                        representations, warranties or
                                        guarantees, whether express or implied,
                                        that Our Content on the Site is
                                        accurate, complete or up to date.
                                        <span style={{ fontSize: "15px" }}>
                                          <span data-custom-class="body_text">
                                            <bdt class="block-component"></bdt>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span data-custom-class="heading_1">
                          <strong>6.</strong>
                          <strong>Link to third party content</strong>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal", fontVariantLigatures: "normal", fontVariantCaps: "normal", fontWeight: "400", letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent:" 0px",textTransform: "none", whiteSpace: "normal",widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial", textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                    6.1 The Site may{" "}
                                  </span>
                                  <span data-custom-class="body_text">
                                    <span style={{fontSize: "15px",color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal",fontVariantLigatures: "normal",fontVariantCaps: "normal",fontWeight:"400",letterSpacing: "normal", orphans: "2", textAlign: "justify",textIndent: "0px",textTransform: "none",whiteSpace: "normal",widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial", textDecorationColor: "initial",display: "inline !important", float: "none"}}>
                                      contain links to websites or applications
                                      operated by third parties. We do not have
                                      any influence or control over any such
                                      third party websites or applications or
                                      the third party operator. We are not
                                      responsible for and do not endorse any
                                      third party websites or applications or
                                      their availability or content.
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal", fontVariantLigatures: "normal", fontVariantCaps: "normal", fontWeight: "400", letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent:" 0px",textTransform: "none", whiteSpace: "normal",widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial", textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                    6.2 We accept no{" "}
                                  </span>
                                  <span data-custom-class="body_text">
                                    <span style={{fontSize: "15px",color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal",fontVariantLigatures: "normal",fontVariantCaps: "normal",fontWeight:"400",letterSpacing: "normal", orphans: "2", textAlign: "justify",textIndent: "0px",textTransform: "none",whiteSpace: "normal",widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial", textDecorationColor: "initial",display: "inline !important", float: "none"}}>
                                      responsibility for adverts contained
                                      within the Site. If you agree to purchase
                                      goods and/or services from any third party
                                      who advertises in the Site, you do so at
                                      your own risk. The advertiser, and not us,
                                      is responsible for such goods and/or
                                      services and if you have any questions or
                                      complaints in relation to them, you should
                                      contact the advertiser.
                                    </span>
                                    <span style={{fontSize: "15px",color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal",fontVariantLigatures: "normal",fontVariantCaps: "normal",fontWeight: "400",letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent: "0px",textTransform: "none",whiteSpace: "normal",widows: "2",wordSpacing: "0px",webkitTextStrokeWidth: "0px",backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial",textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                      <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                        <span data-custom-class="body_text">
                                          <span style={{ fontSize: "15px" }}>
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              <span data-custom-class="body_text">
                                                <span
                                                  style={{ fontSize: "15px" }}
                                                >
                                                  <span
                                                    style={{
                                                      color: "rgb(89, 89, 89)",
                                                    }}
                                                  >
                                                    <bdt class="statement-end-if-in-editor"></bdt>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span data-custom-class="heading_1">
                          <strong>7.</strong>
                          <strong>Site Management</strong>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal", fontVariantLigatures: "normal", fontVariantCaps: "normal", fontWeight: "400", letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent:" 0px",textTransform: "none", whiteSpace: "normal",widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial", textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                    7.1 We reserve{" "}
                                  </span>
                                  <span data-custom-class="body_text">
                                    <span style={{fontSize: "15px",color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal",fontVariantLigatures: "normal",fontVariantCaps: "normal",fontWeight: "400",letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent: "0px",textTransform: "none",whiteSpace: "normal",widows: "2",wordSpacing: "0px",webkitTextStrokeWidth: "0px",backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial",textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        the right at our sole discretion, to (1)
                                        monitor the Site for breaches of these
                                        Terms and Conditions; (
                                        <span style={{color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal", fontVariantLigatures: "normal", fontVariantCaps: "normal", fontWeight: "400", letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent:" 0px",textTransform: "none", whiteSpace: "normal",widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial", textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                          2
                                        </span>
                                        ) take appropriate legal action against
                                        anyone in breach of applicable laws or
                                        these Terms and Conditions;{" "}
                                        <bdt class="block-component"></bdt>{" "}
                                        <span style={{color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal", fontVariantLigatures: "normal", fontVariantCaps: "normal", fontWeight: "400", letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent:" 0px",textTransform: "none", whiteSpace: "normal",widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial", textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                          (
                                        </span>
                                        <span style={{fontSize: "15px",color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal",fontVariantLigatures: "normal",fontVariantCaps: "normal",fontWeight: "400",letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent: "0px",textTransform: "none",whiteSpace: "normal",widows: "2",wordSpacing: "0px",webkitTextStrokeWidth: "0px",backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial",textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                          3
                                        </span>
                                        <span style={{color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal", fontVariantLigatures: "normal", fontVariantCaps: "normal", fontWeight: "400", letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent:" 0px",textTransform: "none", whiteSpace: "normal",widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial", textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                          ) refuse, restrict access to or
                                          availability of, or disable (to the
                                          extent technologically feasible) any
                                          of your Contributions;
                                        </span>
                                        <bdt class="statement-end-if-in-editor"></bdt>{" "}
                                        (
                                        <span style={{color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal", fontVariantLigatures: "normal", fontVariantCaps: "normal", fontWeight: "400", letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent:" 0px",textTransform: "none", whiteSpace: "normal",widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial", textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                          4
                                        </span>
                                        ) remove from the Site or otherwise
                                        disable all files and content that are
                                        excessive in size or are in any way a
                                        burden to our systems; and (
                                        <span style={{color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal", fontVariantLigatures: "normal", fontVariantCaps: "normal", fontWeight: "400", letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent:" 0px",textTransform: "none", whiteSpace: "normal",widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial", textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                          5
                                        </span>
                                        ) otherwise manage the Site in a manner
                                        designed to protect our rights and
                                        property and to facilitate the proper
                                        functioning of the Site and Services.
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal", fontVariantLigatures: "normal", fontVariantCaps: "normal", fontWeight: "400", letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent:" 0px",textTransform: "none", whiteSpace: "normal",widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial", textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                    7.2 We do not{" "}
                                  </span>
                                  <span data-custom-class="body_text">
                                    <span style={{fontSize: "15px",color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal",fontVariantLigatures: "normal",fontVariantCaps: "normal",fontWeight: "400",letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent: "0px",textTransform: "none",whiteSpace: "normal",widows: "2",wordSpacing: "0px",webkitTextStrokeWidth: "0px",backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial",textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        guarantee that the Site will be secure
                                        or free from bugs or viruses.
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal", fontVariantLigatures: "normal", fontVariantCaps: "normal", fontWeight: "400", letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent:" 0px",textTransform: "none", whiteSpace: "normal",widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial", textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                    7.3 You are responsible{" "}
                                  </span>
                                  <span style={{fontSize: "15px",color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal",fontVariantLigatures: "normal",fontVariantCaps: "normal",fontWeight: "400",letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent: "0px",textTransform: "none",whiteSpace: "normal",widows: "2",wordSpacing: "0px",webkitTextStrokeWidth: "0px",backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial",textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span data-custom-class="body_text">
                                        for configuring your information
                                        technology, computer programs and
                                        platform to access the Site and you
                                        should use your own virus protection
                                        software.
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span data-custom-class="heading_1">
                          <strong>8.</strong>
                          <strong>
                            Modifications to and availability of the Site
                          </strong>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal", fontVariantLigatures: "normal", fontVariantCaps: "normal", fontWeight: "400", letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent:" 0px",textTransform: "none", whiteSpace: "normal",widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial", textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                    8.1 We reserve{" "}
                                  </span>
                                  <span data-custom-class="body_text">
                                    <span style={{fontSize: "15px",color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal",fontVariantLigatures: "normal",fontVariantCaps: "normal",fontWeight: "400",letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent: "0px",textTransform: "none",whiteSpace: "normal",widows: "2",wordSpacing: "0px",webkitTextStrokeWidth: "0px",backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial",textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        the right to change, modify, or remove
                                        the contents of the Site at any time or
                                        for any reason at our sole discretion
                                        without notice. We also reserve the
                                        right to modify or discontinue all or
                                        part of the Services without notice at
                                        any time.
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal", fontVariantLigatures: "normal", fontVariantCaps: "normal", fontWeight: "400", letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent:" 0px",textTransform: "none", whiteSpace: "normal",widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial", textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                    8.2 We cannot{" "}
                                  </span>
                                  <span data-custom-class="body_text">
                                    <span style={{fontSize: "15px",color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal",fontVariantLigatures: "normal",fontVariantCaps: "normal",fontWeight: "400",letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent: "0px",textTransform: "none",whiteSpace: "normal",widows: "2",wordSpacing: "0px",webkitTextStrokeWidth: "0px",backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial",textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        guarantee the Site and Services will be
                                        available at all times. We may
                                        experience hardware, software, or other
                                        problems or need to perform maintenance
                                        related to the Site, resulting in
                                        interruptions, delays, or errors. You
                                        agree that we have no liability
                                        whatsoever for any loss, damage, or
                                        inconvenience caused by your inability
                                        to access or use the Site or Services
                                        during any downtime or discontinuance of
                                        the Site or Services. We are not obliged
                                        to maintain and support the Site or
                                        Services or to supply any corrections,
                                        updates, or releases.
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal", fontVariantLigatures: "normal", fontVariantCaps: "normal", fontWeight: "400", letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent:" 0px",textTransform: "none", whiteSpace: "normal",widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial", textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                    8.3 There may be{" "}
                                  </span>
                                  <span data-custom-class="body_text">
                                    <span style={{fontSize: "15px",color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal",fontVariantLigatures: "normal",fontVariantCaps: "normal",fontWeight: "400",letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent: "0px",textTransform: "none",whiteSpace: "normal",widows: "2",wordSpacing: "0px",webkitTextStrokeWidth: "0px",backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial",textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        information on the Site that contains
                                        typographical errors, inaccuracies, or
                                        omissions that may relate to the
                                        Services, including descriptions,
                                        pricing, availability, and various other
                                        information. We reserve the right to
                                        correct any errors, inaccuracies, or
                                        omissions and to change or update the
                                        information at any time, without prior
                                        notice.
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span data-custom-class="heading_1">
                          <strong>9.</strong>
                          <strong>Disclaimer/Limitation of Liability</strong>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal", fontVariantLigatures: "normal", fontVariantCaps: "normal", fontWeight: "400", letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent:" 0px",textTransform: "none", whiteSpace: "normal",widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial", textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                    9.1 The Site and Services{" "}
                                  </span>
                                  <span data-custom-class="body_text">
                                    <span style={{fontSize: "15px",color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal",fontVariantLigatures: "normal",fontVariantCaps: "normal",fontWeight: "400",letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent: "0px",textTransform: "none",whiteSpace: "normal",widows: "2",wordSpacing: "0px",webkitTextStrokeWidth: "0px",backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial",textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        are provided on an as-is and
                                        as-available basis. You agree that your
                                        use of the Site and/or Services will be
                                        at your sole risk except as expressly
                                        set out in these Terms and Conditions.
                                        All warranties, terms, conditions and
                                        undertakings, express or implied
                                        (including by statute, custom or usage,
                                        a course of dealing, or common law) in
                                        connection with the Site and Services
                                        and your use thereof including, without
                                        limitation, the implied warranties of
                                        satisfactory quality, fitness for a
                                        particular purpose and non-infringement
                                        are excluded to the fullest extent
                                        permitted by applicable law.
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal", fontVariantLigatures: "normal", fontVariantCaps: "normal", fontWeight: "400", letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent:" 0px",textTransform: "none", whiteSpace: "normal",widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial", textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                    We make no{" "}
                                  </span>
                                  <span data-custom-class="body_text">
                                    <span style={{fontSize: "15px",color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal",fontVariantLigatures: "normal",fontVariantCaps: "normal",fontWeight: "400",letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent: "0px",textTransform: "none",whiteSpace: "normal",widows: "2",wordSpacing: "0px",webkitTextStrokeWidth: "0px",backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial",textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        warranties or representations about the
                                        accuracy or completeness of the Siteâ€™s
                                        content and are not liable for any (1)
                                        errors or omissions in content; (2) any
                                        unauthorized access to or use of our
                                        servers and/or any and all personal
                                        information and/or financial information
                                        stored on our server; (3) any
                                        interruption or cessation of
                                        transmission to or from the site or
                                        services; and/or (4) any bugs, viruses,
                                        trojan horses, or the like which may be
                                        transmitted to or through the site by
                                        any third party. We will not be
                                        responsible for any delay or failure to
                                        comply with our obligations under these
                                        Terms and Conditions if such delay or
                                        failure is caused by an event beyond our
                                        reasonable control.
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal", fontVariantLigatures: "normal", fontVariantCaps: "normal", fontWeight: "400", letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent:" 0px",textTransform: "none", whiteSpace: "normal",widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial", textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                    9.2 Our responsibility for loss or damage
                                    suffered by you:
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span data-custom-class="body_text">
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <strong>
                                          Whether you are a consumer or a
                                          business user:
                                        </strong>
                                        <bdt class="block-component"></bdt>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <ul>
                    <li style={{lineHeight: "1.5"}}>
                      <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                        <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: "15px" }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                  We do not{" "}
                                  <span data-custom-class="body_text">
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span style={{fontSize:"15px"}}>
                                        exclude or limit in any way our
                                        liability to you where it would be
                                        unlawful to do so. This includes
                                        liability for death or personal injury
                                        caused by our negligence or the
                                        negligence of our employees, agents or
                                        subcontractors and for fraud or
                                        fraudulent misrepresentation.
                                      </span>
                                    </span>
                                  </span>
                                </span>
                                <span data-custom-class="body_text">
                                  <span style={{ fontSize: "15px" }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <bdt class="statement-end-if-in-editor"></bdt>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                  <div>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span data-custom-class="body_text">
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <bdt class="block-component"></bdt>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <ul>
                    <li style={{lineHeight: "1.5"}}>
                      <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                        <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: "15px" }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                  If we fail to{" "}
                                  <span data-custom-class="body_text">
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span style={{fontSize:"15px"}}>
                                        comply with these Terms and Conditions,
                                        we will be responsible for loss or
                                        damage you suffer that is a foreseeable
                                        result of our breach of these Terms and
                                        Conditions, but we would not be
                                        responsible for any loss or damage that
                                        were not foreseeable at the time you
                                        started using the Site/Services.
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                  <div style={{lineHeight: "1.5",marginLeft: "40px"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal", fontVariantLigatures: "normal", fontVariantCaps: "normal", fontWeight: "400", letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent:" 0px",textTransform: "none", whiteSpace: "normal",widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial", textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                    <span data-custom-class="body_text">
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <span style={{fontSize:"15px"}}>
                                          <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                            <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                              <span data-custom-class="body_text">
                                                <span
                                                  style={{ fontSize: "15px" }}
                                                >
                                                  <span
                                                    style={{
                                                      color: "rgb(89, 89, 89)",
                                                    }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      Notwithstanding
                                                      <span data-custom-class="body_text">
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span style={{fontSize:"15px"}}>
                                                            anything to the
                                                            contrary contained
                                                            in the
                                                            Disclaimer/Limitation
                                                            of Liability
                                                            section, our
                                                            liability to you for
                                                            any cause whatsoever
                                                            and regardless of
                                                            the form of the
                                                            action, will at all
                                                            times be limited to
                                                            a total aggregate
                                                            amount equal to the
                                                            greater of (a) the
                                                            sum of{" "}
                                                            <bdt class="block-component">
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span style={{fontSize:"15px"}}>
                                                                    Â£
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </bdt>
                                                            <bdt class="question">
                                                              $500
                                                            </bdt>
                                                            <bdt class="else-block"></bdt>{" "}
                                                            or (b) the amount
                                                            paid, if any, by you
                                                            to us for the
                                                            Services/Site during
                                                            the six (6) month
                                                            period prior to any
                                                            cause of action
                                                            arising.{" "}
                                                            <bdt class="block-component">
                                                              <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                                                <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                                                  <span data-custom-class="body_text">
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <span
                                                                            style={{
                                                                              fontSize:
                                                                                "15px",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  "rgb(89, 89, 89)",
                                                                              }}
                                                                            >
                                                                              <bdt class="statement-end-if-in-editor"></bdt>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </bdt>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5",marginLeft: "40px"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <bdt class="block-component"></bdt>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal", fontVariantLigatures: "normal", fontVariantCaps: "normal", fontWeight: "400", letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent:" 0px",textTransform: "none", whiteSpace: "normal",widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial", textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                    <strong>If you are a business user:</strong>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal", fontVariantLigatures: "normal", fontVariantCaps: "normal", fontWeight: "400", letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent:" 0px",textTransform: "none", whiteSpace: "normal",widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial", textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                    We will not be{" "}
                                    <span data-custom-class="body_text">
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <span style={{fontSize:"15px"}}>
                                          liable to you for any loss or damage,
                                          whether in contract, tort (including
                                          negligence), breach of statutory duty,
                                          or otherwise, even if foreseeable,
                                          arising under or in connection with:
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <bdt class="block-component"></bdt>
                        </span>
                      </span>
                    </span>
                  </div>
                  <ul>
                    <li style={{lineHeight: "1.5",listStyleType: "square",marginLeft:"5%"}}>
                      <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                        <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: "15px" }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                  <span data-custom-class="body_text">
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span style={{fontSize:"15px"}}>
                                        use of, or{" "}
                                        <span data-custom-class="body_text">
                                          <span
                                            style={{ color: "rgb(89, 89, 89)" }}
                                          >
                                            <span style={{fontSize:"15px"}}>
                                              inability to use, our
                                              Site/Services; or
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                                <span data-custom-class="body_text">
                                  <span style={{ fontSize: "15px" }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <bdt class="statement-end-if-in-editor"></bdt>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                  <div style={{lineHeight: "1.5"}}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <bdt class="block-component"></bdt>
                        </span>
                      </span>
                    </span>
                  </div>
                  <ul>
                    <li style={{lineHeight: "1.5",listStyleType: "square",marginLeft:"5%"}}>
                      <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                        <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: "15px" }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                  <span data-custom-class="body_text">
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span style={{fontSize:"15px"}}>
                                        use of or{" "}
                                        <span data-custom-class="body_text">
                                          <span
                                            style={{ color: "rgb(89, 89, 89)" }}
                                          >
                                            <span style={{fontSize:"15px"}}>
                                              reliance on any content displayed
                                              on our Site.
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                                <span data-custom-class="body_text">
                                  <span style={{ fontSize: "15px" }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <bdt class="statement-end-if-in-editor"></bdt>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span data-custom-class="body_text">
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        In particular, we will not be liable
                                        for:
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <bdt class="block-component"></bdt>
                        </span>
                      </span>
                    </span>
                  </div>
                  <ul>
                    <li style={{lineHeight: "1.5",listStyleType: "square",marginLeft:"5%"}}>
                      <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                        <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: "15px" }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                  <span data-custom-class="body_text">
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span style={{fontSize:"15px"}}>
                                        <span data-custom-class="body_text">
                                          <span
                                            style={{ color: "rgb(89, 89, 89)" }}
                                          >
                                            <span style={{fontSize:"15px"}}>
                                              loss of profits, sales, business,
                                              or revenue;
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                                <span data-custom-class="body_text">
                                  <span style={{ fontSize: "15px" }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <bdt class="statement-end-if-in-editor"></bdt>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                  <div style={{lineHeight: "1.5"}}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <bdt class="block-component"></bdt>
                        </span>
                      </span>
                    </span>
                  </div>
                  <ul>
                    <li style={{lineHeight: "1.5",listStyleType: "square",marginLeft:"5%"}}>
                      <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                        <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: "15px" }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                  <span data-custom-class="body_text">
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span style={{fontSize:"15px"}}>
                                        <span data-custom-class="body_text">
                                          <span
                                            style={{ color: "rgb(89, 89, 89)" }}
                                          >
                                            <span style={{fontSize:"15px"}}>
                                              business interruption;
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                                <span data-custom-class="body_text">
                                  <span style={{ fontSize: "15px" }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <bdt class="statement-end-if-in-editor"></bdt>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                  <div style={{lineHeight: "1.5"}}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <bdt class="block-component"></bdt>
                        </span>
                      </span>
                    </span>
                  </div>
                  <ul>
                    <li style={{lineHeight: "1.5",listStyleType: "square",marginLeft:"5%"}}>
                      <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                        <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: "15px" }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                  <span data-custom-class="body_text">
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span style={{fontSize:"15px"}}>
                                        <span data-custom-class="body_text">
                                          <span
                                            style={{ color: "rgb(89, 89, 89)" }}
                                          >
                                            <span style={{fontSize:"15px"}}>
                                              loss of anticipated savings;
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                                <span data-custom-class="body_text">
                                  <span style={{ fontSize: "15px" }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <bdt class="statement-end-if-in-editor"></bdt>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                  <div style={{lineHeight: "1.5"}}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <bdt class="block-component"></bdt>
                        </span>
                      </span>
                    </span>
                  </div>
                  <ul>
                    <li style={{lineHeight: "1.5",listStyleType: "square",marginLeft:"5%"}}>
                      <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                        <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: "15px" }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                  <span data-custom-class="body_text">
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span style={{fontSize:"15px"}}>
                                        <span data-custom-class="body_text">
                                          <span
                                            style={{ color: "rgb(89, 89, 89)" }}
                                          >
                                            <span style={{fontSize:"15px"}}>
                                              loss of business opportunity,
                                              goodwill or reputation; or
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                                <span data-custom-class="body_text">
                                  <span style={{ fontSize: "15px" }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <bdt class="statement-end-if-in-editor"></bdt>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                  <div style={{lineHeight: "1.5"}}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <bdt class="block-component"></bdt>
                        </span>
                      </span>
                    </span>
                  </div>
                  <ul>
                    <li style={{lineHeight: "1.5",listStyleType: "square",marginLeft:"5%"}}>
                      <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                        <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: "15px" }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                  <span data-custom-class="body_text">
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span style={{fontSize:"15px"}}>
                                        <span data-custom-class="body_text">
                                          <span
                                            style={{ color: "rgb(89, 89, 89)" }}
                                          >
                                            <span style={{fontSize:"15px"}}>
                                              any indirect or consequential loss
                                              or damage.
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                                <span data-custom-class="body_text">
                                  <span style={{ fontSize: "15px" }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <bdt class="statement-end-if-in-editor"></bdt>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                      <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                        <span data-custom-class="body_text">
                          <span style={{ fontSize: "15px" }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span style={{ fontSize: "15px" }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <bdt class="statement-end-if-in-editor"></bdt>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <bdt class="block-component"></bdt>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span data-custom-class="body_text">
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <strong>
                                          If you are a consumer user:
                                        </strong>
                                        <span data-custom-class="body_text">
                                          <span style={{ fontSize: "15px" }}>
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              <bdt class="block-component"></bdt>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <ul>
                    <li style={{lineHeight: "1.5",listStyleType: "square",marginLeft:"5%"}}>
                      <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                        <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: "15px" }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                  <span data-custom-class="body_text">
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span style={{fontSize:"15px"}}>
                                        <span data-custom-class="body_text">
                                          <span
                                            style={{ color: "rgb(89, 89, 89)" }}
                                          >
                                            <span style={{fontSize:"15px"}}>
                                              Please note that{" "}
                                              <span data-custom-class="body_text">
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)",
                                                  }}
                                                >
                                                  <span style={{fontSize:"15px"}}>
                                                    we only provide our Site for
                                                    domestic and private use.
                                                    You agree not to use our
                                                    Site for any commercial or
                                                    business purposes, and we
                                                    have no liability to you for
                                                    any loss of profit, loss of
                                                    business, business
                                                    interruption, or loss of
                                                    business opportunity.
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                                <span data-custom-class="body_text">
                                  <span style={{ fontSize: "15px" }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <bdt class="statement-end-if-in-editor"></bdt>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                  <div style={{lineHeight: "1.5"}}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <bdt class="block-component"></bdt>
                        </span>
                      </span>
                    </span>
                  </div>
                  <ul>
                    <li style={{lineHeight: "1.5",listStyleType: "square",marginLeft:"5%"}}>
                      <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                        <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: "15px" }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                  <span data-custom-class="body_text">
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span style={{fontSize:"15px"}}>
                                        <span data-custom-class="body_text">
                                          <span
                                            style={{ color: "rgb(89, 89, 89)" }}
                                          >
                                            <span style={{fontSize:"15px"}}>
                                              If defective digital
                                              <span data-custom-class="body_text">
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)",
                                                  }}
                                                >
                                                  <span style={{fontSize:"15px"}}>
                                                    content that we have
                                                    supplied, damages a device
                                                    or digital content belonging
                                                    to you and this is caused by
                                                    our failure to use
                                                    reasonable care and skill,
                                                    we will either repair the
                                                    damage or pay you
                                                    compensation.
                                                    <bdt class="block-component"></bdt>
                                                    <em style={{color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontVariantLigatures: "normal",fontVariantCaps: "normal",fontWeight: "400", letterSpacing: "normal", orphans: "2",textAlign: "justify",textIndent: "0px",textTransform: "none",whiteSpace: "normal",widows: "2",wordSpacing: "0px",webkitTextStrokeWidth: "0px",backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial",textDecorationColor: "initial"}}>
                                                      However, we will not be
                                                      liable for damage that you
                                                      could have avoided by
                                                      following our advice to
                                                      apply an update offered to
                                                      you free of charge or for
                                                      damage that was caused by
                                                      you failing to correctly
                                                      follow installation
                                                      instructions or to have in
                                                      place the minimum system
                                                      requirements advised by
                                                      us.
                                                    </em>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                                <span data-custom-class="body_text">
                                  <span style={{ fontSize: "15px" }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <bdt class="statement-end-if-in-editor">
                                        <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                          <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                            <span data-custom-class="body_text">
                                              <span
                                                style={{ fontSize: "15px" }}
                                              >
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)",
                                                  }}
                                                >
                                                  <span data-custom-class="body_text">
                                                    <span
                                                      style={{
                                                        fontSize: "15px",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <bdt class="statement-end-if-in-editor"></bdt>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </bdt>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                  <div style={{lineHeight: "1.5"}}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <bdt class="block-component"></bdt>
                        </span>
                      </span>
                    </span>
                  </div>
                  <ul>
                    <li style={{lineHeight: "1.5",listStyleType: "square",marginLeft:"5%"}}>
                      <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                        <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: "15px" }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                  <span data-custom-class="body_text">
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span style={{fontSize:"15px"}}>
                                        <span data-custom-class="body_text">
                                          <span
                                            style={{ color: "rgb(89, 89, 89)" }}
                                          >
                                            <span style={{fontSize:"15px"}}>
                                              You have legal r
                                              <span data-custom-class="body_text">
                                                <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                                  ights in relation to goods
                                                  that are faulty or not as
                                                  described. Advice about your
                                                  legal rights is available from
                                                  your local Citizens' Advice
                                                  Bureau or Trading Standards
                                                  office. Nothing in these Terms
                                                  and Conditions will affect
                                                  these legal rights.
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                                <span data-custom-class="body_text">
                                  <span style={{ fontSize: "15px" }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <bdt class="statement-end-if-in-editor"></bdt>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{fontSize: "15px",color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal",fontVariantLigatures: "normal",fontVariantCaps: "normal",fontWeight: "400",letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent: "0px",textTransform: "none",whiteSpace: "normal",widows: "2",wordSpacing: "0px",webkitTextStrokeWidth: "0px",backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial",textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                    <span data-custom-class="body_text">
                                      <span style={{ fontSize: "15px" }}>
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span data-custom-class="body_text">
                                            <span style={{ fontSize: "15px" }}>
                                              <span
                                                style={{
                                                  color: "rgb(89, 89, 89)",
                                                }}
                                              >
                                                <bdt class="statement-end-if-in-editor"></bdt>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                  <span style={{color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal", fontVariantLigatures: "normal", fontVariantCaps: "normal", fontWeight: "400", letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent:" 0px",textTransform: "none", whiteSpace: "normal",widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial", textDecorationColor: "initial",display: "inline !important",float: "none"}}></span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span data-custom-class="heading_1">
                          <strong>10.</strong>
                          <strong>Term and Termination</strong>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal", fontVariantLigatures: "normal", fontVariantCaps: "normal", fontWeight: "400", letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent:" 0px",textTransform: "none", whiteSpace: "normal",widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial", textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                    10.1
                                  </span>
                                  <span data-custom-class="body_text">
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        These Terms and Conditions
                                        <span data-custom-class="body_text">
                                          <span style={{ fontSize: "15px" }}>
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              shall remain in full force and
                                              effect while you use the Site or
                                              Services or are otherwise a user
                                              of the Site, as applicable. You
                                              may terminate your use or
                                              participation at any time, for any
                                              reason, by following the
                                              instructions for terminating user
                                              accounts in your account settings,
                                              if available, or by contacting us
                                              at{" "}
                                              <bdt class="question">
                                                support@arelunchbox.com
                                              </bdt>
                                            </span>
                                          </span>
                                        </span>
                                        .
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal", fontVariantLigatures: "normal", fontVariantCaps: "normal", fontWeight: "400", letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent:" 0px",textTransform: "none", whiteSpace: "normal",widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial", textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                    10.2
                                  </span>
                                  <span data-custom-class="body_text">
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        Without limiting{" "}
                                        <span data-custom-class="body_text">
                                          <span style={{ fontSize: "15px" }}>
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              any other provision of these Terms
                                              and Conditions, we reserve the
                                              right to, in our sole discretion
                                              and without notice or liability,
                                              deny access to and use of the Site
                                              and the Services (including
                                              blocking certain IP addresses), to
                                              any person for any reason
                                              including without limitation for
                                              breach of any representation,
                                              warranty or covenant contained in
                                              these Terms and Conditions or of
                                              any applicable law or regulation.
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span data-custom-class="body_text">
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        If we determine,{" "}
                                        <span data-custom-class="body_text">
                                          <span style={{ fontSize: "15px" }}>
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              in our sole discretion, that your
                                              use of the Site/Services is in
                                              breach of these Terms and
                                              Conditions or of any applicable
                                              law or regulation, we may
                                              terminate your use or
                                              participation in the Site and the
                                              Services or delete
                                              <bdt class="block-component"></bdt>{" "}
                                              your profile and
                                              <bdt class="statement-end-if-in-editor"></bdt>{" "}
                                              any content or information that
                                              you posted at any time, without
                                              warning, in our sole discretion.
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal", fontVariantLigatures: "normal", fontVariantCaps: "normal", fontWeight: "400", letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent:" 0px",textTransform: "none", whiteSpace: "normal",widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial", textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                    10.3
                                  </span>
                                  <span data-custom-class="body_text">
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        If we terminate{" "}
                                        <span data-custom-class="body_text">
                                          <span style={{ fontSize: "15px" }}>
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              or suspend your account for any
                                              reason set out in this Section 9,
                                              you are prohibited from
                                              registering and creating a new
                                              account under your name, a fake or
                                              borrowed name, or the name of any
                                              third party, even if you may be
                                              acting on behalf of the third
                                              party. In addition to terminating
                                              or suspending your account, we
                                              reserve the right to take
                                              appropriate legal action,
                                              including without limitation
                                              pursuing civil, criminal, and
                                              injunctive redress.
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span data-custom-class="body_text">
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <span data-custom-class="body_text">
                                          <span style={{ fontSize: "15px" }}>
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            ></span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span data-custom-class="body_text">
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <span data-custom-class="body_text">
                                          <span style={{ fontSize: "15px" }}>
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              <span data-custom-class="body_text">
                                                <span
                                                  style={{ fontSize: "15px" }}
                                                >
                                                  <span
                                                    style={{
                                                      color: "rgb(89, 89, 89)",
                                                    }}
                                                  >
                                                    <bdt class="block-component"></bdt>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5",marginLeft: "40px"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span data-custom-class="heading_1">
                          <strong>11.</strong>
                          <strong>General </strong>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal", fontVariantLigatures: "normal", fontVariantCaps: "normal", fontWeight: "400", letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent:" 0px",textTransform: "none", whiteSpace: "normal",widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial", textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                    11.1
                                  </span>
                                  <span data-custom-class="body_text">
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        Visiting the Site,{" "}
                                        <span data-custom-class="body_text">
                                          <span style={{ fontSize: "15px" }}>
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              sending us emails, and completing
                                              online forms constitute electronic
                                              communications. You consent to
                                              receive electronic communications
                                              and you agree that all agreements,
                                              notices, disclosures, and other
                                              communications we provide to you
                                              electronically, via email and on
                                              the Site, satisfy any legal
                                              requirement that such
                                              communication be in writing.
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span data-custom-class="body_text">
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <strong>
                                          You hereby agree to the use{" "}
                                        </strong>
                                        <span data-custom-class="body_text">
                                          <span style={{ fontSize: "15px" }}>
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              <strong>
                                                of electronic signatures,
                                                contracts, orders and other
                                                records and to electronic
                                                delivery of notices, policies
                                                and records of transactions
                                                initiated or completed by us or
                                                via the Site.
                                              </strong>{" "}
                                              You hereby waive any rights or
                                              requirements under any statutes,
                                              regulations, rules, ordinances or
                                              other laws in any jurisdiction
                                              which require an original
                                              signature or delivery or retention
                                              of non-electronic records, or to
                                              payments or the granting of
                                              credits by other than electronic
                                              means.
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal", fontVariantLigatures: "normal", fontVariantCaps: "normal", fontWeight: "400", letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent:" 0px",textTransform: "none", whiteSpace: "normal",widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial", textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                    11.2
                                  </span>
                                  <span data-custom-class="body_text">
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        These Terms and Conditions
                                        <span data-custom-class="body_text">
                                          <span style={{ fontSize: "15px" }}>
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              and any policies or operating
                                              rules posted by us on the Site or
                                              in respect to the Services
                                              constitute the entire agreement
                                              and understanding between you and
                                              us.
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal", fontVariantLigatures: "normal", fontVariantCaps: "normal", fontWeight: "400", letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent:" 0px",textTransform: "none", whiteSpace: "normal",widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial", textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                    11.3
                                  </span>
                                  <span data-custom-class="body_text">
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        Our failure to exercise
                                        <span data-custom-class="body_text">
                                          <span style={{ fontSize: "15px" }}>
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              or enforce any right or provision
                                              of these Terms and Conditions
                                              shall not operate as a waiver of
                                              such right or provision.
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal", fontVariantLigatures: "normal", fontVariantCaps: "normal", fontWeight: "400", letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent:" 0px",textTransform: "none", whiteSpace: "normal",widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial", textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                    11.4
                                  </span>
                                  <span data-custom-class="body_text">
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        We may assign any or all of our rights
                                        and obligations to others at any time.
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal", fontVariantLigatures: "normal", fontVariantCaps: "normal", fontWeight: "400", letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent:" 0px",textTransform: "none", whiteSpace: "normal",widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial", textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                    11.5
                                  </span>
                                  <span data-custom-class="body_text">
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        We shall not be{" "}
                                        <span data-custom-class="body_text">
                                          <span style={{ fontSize: "15px" }}>
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              responsible or liable for any
                                              loss, damage, delay or failure to
                                              act caused by any cause beyond our
                                              reasonable control.
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal", fontVariantLigatures: "normal", fontVariantCaps: "normal", fontWeight: "400", letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent:" 0px",textTransform: "none", whiteSpace: "normal",widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial", textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                    11.6
                                  </span>
                                  <span data-custom-class="body_text">
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        If any provision{" "}
                                        <span data-custom-class="body_text">
                                          <span style={{ fontSize: "15px" }}>
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              or part of a provision of these
                                              Terms and Conditions is unlawful,
                                              void or unenforceable, that
                                              provision or part of the provision
                                              is deemed severable from these
                                              Terms and Conditions and does not
                                              affect the validity and
                                              enforceability of any remaining
                                              provisions.
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal", fontVariantLigatures: "normal", fontVariantCaps: "normal", fontWeight: "400", letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent:" 0px",textTransform: "none", whiteSpace: "normal",widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial", textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                    11.7
                                  </span>
                                  <span data-custom-class="body_text">
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        There is no joint{" "}
                                        <span data-custom-class="body_text">
                                          <span style={{ fontSize: "15px" }}>
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              venture, partnership, employment
                                              or agency relationship created
                                              between you and us as a result of
                                              these Terms and Conditions or use
                                              of the Site or Services.
                                              <span data-custom-class="body_text">
                                                <span style={{color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal",fontVariantLigatures: "normal",fontVariantCaps: "normal",fontWeight: "400", letterSpacing: "normal",orphans: "2",textIndent: "0px",textTransform: "none",whiteSpace: "normal",widows: "2", wordSpacing: "0px",webkitTextStrokeWidth: "0px",textDecorationStyle: "initial",textDecorationColor: "initial",textAlign: "start", backgroundColor: "rgb(255, 255, 255)"}}>
                                                  <bdt class="block-component"></bdt>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal", fontVariantLigatures: "normal", fontVariantCaps: "normal", fontWeight: "400", letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent:" 0px",textTransform: "none", whiteSpace: "normal",widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial", textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                    11.8
                                  </span>
                                  <span data-custom-class="body_text">
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <strong>
                                          <em>For consumers only</em>
                                        </strong>
                                        <em> - </em>
                                        <span data-custom-class="body_text">
                                          <span style={{fontSize:"15px"}}>
                                            <em>
                                              <span
                                                style={{
                                                  color: "rgb(89, 89, 89)",
                                                }}
                                              >
                                                Please note that these Terms and
                                                Conditions, their subject matter
                                                and their formation, are
                                                governed by English law. You and
                                                we both agree that the courts of
                                                England and Wales will have
                                                exclusive jurisdiction expect
                                                that if you are a resident of
                                                Northern Ireland you may also
                                                bring proceedings in Northern
                                                Ireland, and if you are resident
                                                of Scotland, you may also bring
                                                proceedings in Scotland. If you
                                                have any complaint or wish to
                                                raise a dispute under these
                                                Terms and Conditions or
                                                otherwise in relation to the
                                                Site please follow this link
                                              </span>
                                            </em>
                                          </span>{" "}
                                          <a href="http://ec.europa.eu/odr"></a>
                                          <em>
                                            <span style={{color: "rgb(48, 48, 241)"}}>
                                              <a
                                                data-custom-class="link"
                                                href="http://ec.europa.eu/odr"
                                                target="_blank"
                                              >
                                                http://ec.europa.eu/odr
                                              </a>
                                            </span>
                                          </em>
                                        </span>
                                        .
                                        <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                          <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                            <span data-custom-class="body_text">
                                              <span
                                                style={{ fontSize: "15px" }}
                                              >
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)",
                                                  }}
                                                >
                                                  <span data-custom-class="body_text">
                                                    <span
                                                      style={{
                                                        fontSize: "15px",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <bdt class="statement-end-if-in-editor"></bdt>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                        <span data-custom-class="body_text">
                                          <span style={{color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal",fontVariantLigatures: "normal",fontVariantCaps: "normal",fontWeight: "400", letterSpacing: "normal",orphans: "2",textIndent: "0px",textTransform: "none",whiteSpace: "normal",widows: "2", wordSpacing: "0px",webkitTextStrokeWidth: "0px",textDecorationStyle: "initial",textDecorationColor: "initial",textAlign: "start", backgroundColor: "rgb(255, 255, 255)"}}>
                                            <bdt class="block-component"></bdt>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal", fontVariantLigatures: "normal", fontVariantCaps: "normal", fontWeight: "400", letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent:" 0px",textTransform: "none", whiteSpace: "normal",widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial", textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                    11.9
                                  </span>
                                  <span data-custom-class="body_text">
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <strong>
                                          <em>For business users only</em>
                                        </strong>
                                        <em> </em>-{" "}
                                        <span data-custom-class="body_text">
                                          <em>
                                            <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                              If you are a business user, these
                                              Terms and Conditions, their
                                              subject matter and their formation
                                              (and any non-contractual disputes
                                              or claims) are governed by English
                                              Law. We both agree to the
                                              exclusive jurisdiction of the
                                              courts of England and Wales.
                                            </span>
                                          </em>
                                          <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                            <span style={{ fontSize: "15px" }}>
                                              <span
                                                style={{
                                                  color: "rgb(127, 127, 127)",
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)",
                                                  }}
                                                >
                                                  <span data-custom-class="body_text">
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <span data-custom-class="body_text">
                                                          <span data-custom-class="body_text">
                                                            <span
                                                              style={{
                                                                fontSize:
                                                                  "15px",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                                                  <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                        style={{
                                                                          fontSize:
                                                                            "15px",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <span
                                                                              style={{
                                                                                fontSize:
                                                                                  "15px",
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "rgb(89, 89, 89)",
                                                                                }}
                                                                              >
                                                                                <bdt class="statement-end-if-in-editor"></bdt>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span data-custom-class="body_text">
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <span data-custom-class="body_text">
                                          <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                            <span style={{ fontSize: "15px" }}>
                                              <span
                                                style={{
                                                  color: "rgb(127, 127, 127)",
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)",
                                                  }}
                                                >
                                                  <span data-custom-class="body_text">
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <span data-custom-class="body_text">
                                                          <span data-custom-class="body_text">
                                                            <span
                                                              style={{
                                                                fontSize:
                                                                  "15px",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                                                  <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                        style={{
                                                                          fontSize:
                                                                            "15px",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <span
                                                                              style={{
                                                                                fontSize:
                                                                                  "15px",
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "rgb(89, 89, 89)",
                                                                                }}
                                                                              >
                                                                                <bdt class="statement-end-if-in-editor">
                                                                                  <span data-custom-class="body_text">
                                                                                    <span
                                                                                      style={{
                                                                                        fontSize:
                                                                                          "15px",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          color:
                                                                                            "rgb(89, 89, 89)",
                                                                                        }}
                                                                                      >
                                                                                        <bdt class="block-component"></bdt>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </bdt>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal", fontVariantLigatures: "normal", fontVariantCaps: "normal", fontWeight: "400", letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent:" 0px",textTransform: "none", whiteSpace: "normal",widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial", textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                    11.10
                                  </span>
                                  <span data-custom-class="body_text">
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <span data-custom-class="body_text">
                                          <span style={{color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal",fontVariantLigatures: "normal",fontVariantCaps: "normal",fontWeight: "400", letterSpacing: "normal",orphans: "2",textIndent: "0px",textTransform: "none",whiteSpace: "normal",widows: "2", wordSpacing: "0px",webkitTextStrokeWidth: "0px",textDecorationStyle: "initial",textDecorationColor: "initial",textAlign: "start", backgroundColor: "rgb(255, 255, 255)"}}>
                                            <bdt class="block-component"></bdt>A
                                            <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                              <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                                <span data-custom-class="body_text">
                                                  <span
                                                    style={{ fontSize: "15px" }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <bdt class="statement-end-if-in-editor"></bdt>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                            <span data-custom-class="body_text">
                                              <span
                                                style={{ fontSize: "15px" }}
                                              >
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)",
                                                  }}
                                                >
                                                  person who is not a party to
                                                  these Terms and Conditions
                                                  shall have no right under the
                                                  Contracts (Rights of Third
                                                  Parties) Act 1999 to enforce
                                                  any term of these Terms and
                                                  Conditions.
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span data-custom-class="body_text">
                                  <span style={{color: "rgb(89, 89, 89)",fontFamily: "sans-serif",fontSize: "15px",fontStyle: "normal", fontVariantLigatures: "normal", fontVariantCaps: "normal", fontWeight: "400", letterSpacing: "normal",orphans: "2",textAlign: "justify",textIndent:" 0px",textTransform: "none", whiteSpace: "normal",widows: "2", wordSpacing: "0px", webkitTextStrokeWidth: "0px", backgroundColor: "rgb(255, 255, 255)",textDecorationStyle: "initial", textDecorationColor: "initial",display: "inline !important",float: "none"}}>
                                    11.11
                                  </span>
                                  <span data-custom-class="body_text">
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        In order to resolve{" "}
                                        <span data-custom-class="body_text">
                                          <span style={{ fontSize: "15px" }}>
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              a complaint regarding the Services
                                              or to receive further information
                                              regarding use of the Services,
                                              please contact us by email at{" "}
                                              <bdt class="question">
                                                support@arelunchbox.com
                                              </bdt>{" "}
                                              or by post to:
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <br />
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                      <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                        <span data-custom-class="body_text">
                          <span style={{ fontSize: "15px" }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                  <bdt class="question">ARE LUNCHBOX LLC</bdt>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <bdt class="question">
                              10877 Rose Avenue
                              <span data-custom-class="body_text">
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <bdt class="block-component"></bdt>
                                </span>
                              </span>
                            </bdt>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: "15px" }}>
                        <bdt class="question">APT 5</bdt>
                        <span data-custom-class="body_text">
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <bdt class="statement-end-if-in-editor"></bdt>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span data-custom-class="body_text">
                      <bdt class="question">Los Angeles</bdt>
                      <span data-custom-class="body_text">
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span style={{ fontSize: "15px" }}>
                            <span data-custom-class="body_text">
                              ,<bdt class="block-component"></bdt>
                              <bdt class="block-component"></bdt>
                              <bdt class="question">CA</bdt>
                              <bdt class="statement-end-if-in-editor"></bdt>
                              <bdt class="block-component"></bdt>
                              <bdt class="question">90035</bdt>
                              <bdt class="block-component"></bdt>
                            </span>
                          </span>
                        </span>
                      </span>
                      <span data-custom-class="body_text">
                        <span style={{ fontSize: "15px" }}>
                          <bdt class="block-component"></bdt>
                          <bdt class="block-component"></bdt>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                      <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                        <span data-custom-class="body_text">
                          <bdt class="question">
                            United States
                            <span data-custom-class="body_text">
                              <span style={{ fontSize: "15px" }}>
                                <span data-custom-class="body_text">
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <bdt class="statement-end-if-in-editor"></bdt>
                                    </span>
                                    <bdt class="else-block">
                                      <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                        <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                                          <span data-custom-class="body_text">
                                            <span style={{ fontSize: "15px" }}>
                                              <span data-custom-class="body_text">
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)",
                                                  }}
                                                >
                                                  <bdt class="statement-end-if-in-editor"></bdt>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </bdt>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </bdt>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                      <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                        <span data-custom-class="body_text"></span>
                      </span>
                    </span>
                  </div>
                  <div style={{lineHeight: "1.5"}}>
                    <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                      <span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
                        <span data-custom-class="body_text"></span>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default TermAndConditions;
