import React from 'react';

const Facebook = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 1200 1200" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 0V1200H1200V0H0ZM863.232 156.592C871.947 156.407 881.023 156.69 890.405 157.324C924.881 157.371 960.888 160.479 996.606 163.623L992.724 305.713H896.777C851.789 304.717 835.542 322.186 834.082 372.949V484.57H996.606L990.161 636.767H834.082V1060.47H675.513V636.768H565.43V484.57H675.513V353.906C675.513 259.697 715.342 199.732 793.799 169.336C813.948 161.408 837.087 157.147 863.232 156.592Z" fill="#1C77D2"/>
          </svg>
    );
}

export default Facebook;
