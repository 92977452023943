import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getComboCoursesList } from '../../reducers/coursesReducer';
import ComboCourseItem from './ComboCourseItem';
import _ from 'lodash';

const ComboCourses = () => {
  const dispatch = useDispatch();
  const [list,setList]=useState(null)
  const courseList = useSelector((state)=>state?.course?.coursesList);
  const comboList = useSelector((state)=>state?.course?.comboCoursesList);
  // const cartItems = useSelector((state)=>state?.course?.cartListData);
  // const user = useSelector((state)=> state?.authReducer?.userData);
  const [comboLarnMoreActive, setComboLearnMoreActive] = useState([])
  
  useEffect(()=>{
    dispatch(getComboCoursesList())
  },[])

  const onMobileTap=(index)=>{
    const updatedCheckedState = comboLarnMoreActive.map((item, i) =>
    index === i ? !item : false,
     );
     setComboLearnMoreActive(updatedCheckedState)
    // console.log("Combo mobile tab called")  
} 
  
  useEffect(()=>{
    setComboLearnMoreActive(new Array(comboList?.length).fill(false))
      const newArray = comboList?.map((each) => {  
        const courseDetail= each?.courses.map((data,i)=>{
          // console.log("daa>>>>",data)
            const array = courseList?.find((ea,i)=> {
              // console.log('each---data',ea.id,data,ea.id === data);
             return ea.id === data
            });
            return array
         })
         return {
           ...each,
           'courseListArr': courseDetail
         }
      })
      // console.log("arr",newArray);
      const array = _.sortBy(newArray, 'comboName')
      setList(array);
  },[comboList])
    return (
      <>
       {
         list?.map((each,i)=> <ComboCourseItem eachObject={each} count={list?.length - 1} indexValue={i} key={i} comboLearnMoreOpen={comboLarnMoreActive[i]} index={i} mobileTap={onMobileTap}/>)
       } 
    
{/* <div className="courseenrollment-section-5 courseenrollment-section-3">
  <p className="bold-heading">Bento Box 2:
  </p>
  <p className="bold-sub-heading">PA, PPD, & PDD</p>
</div>
<div className="courseenrollment-section-4">
<div className="courseenrollment-lightGray-border">
  <div ontouchstart="myFunction2(event)" className="courseenrollment-darkGray-bg">
    <div className="enrollment-phase-one-item card-bg3">
      <div className="enrollment-phase-item-left">
        <h4>Programming <br/>
          & Analysis</h4>
          <p>PA</p>
      </div>
      <div className="enrollment-phase-item-right">
        <img className="img-fluid" src="../assets/images/courseenrollment3.png" />
      </div>

    </div>
    <div className="enrollment-phase-one-item card-bg4">
      <div className="enrollment-phase-item-left">
        <h4>Project Planning<br/>
          & Design</h4>
          <p>PPD</p>
      </div>
      <div className="enrollment-phase-item-right">
        <img className="img-fluid" src="../assets/images/courseenrollment4.png" />
      </div>

    </div>
    <div className="enrollment-phase-one-item card-bg5">
      <div className="enrollment-phase-item-left">
        <h4>Project<br/>
          Development &<br/>
          Documentation</h4>
          <p>PDD</p>
      </div>
      <div className="enrollment-phase-item-right">
        <img className="img-fluid" src="../assets/images/courseenrollment5.png" />
      </div>
    </div>
    <div id="hover-btn-area2" className="hover-btn-area">
      <button data-toggle="modal"
      data-target="#myModal" className="learn-more-btn">Learn More</button>
    </div>
  </div>

</div>
</div>
<div className="courseenrollment-section-5 courseenrollment-section-3">
  <p className="bold-heading">$399</p>
  <p className="bold-sub-heading mb-0">*$133 Per ARE Course*</p>
</div>
<div className="courseenrollment-line custom-line-two"></div>
<div className="courseenrollment-section-5 courseenrollment-section-3">
  <p className="bold-heading">The Complete Lunchbox:</p>
  <p className="bold-sub-heading">PcM, PjM, PA, PPD, PDD, & CE</p>
</div>
<div className="courseenrollment-section-6 courseenrollment-section-4">
  <div className="courseenrollment-lightGray-border">
    <div ontouchstart="myFunction3(event)" className="courseenrollment-darkGray-bg">
      <div className="enrollment-phase-one-item card-bg1">
        <div className="enrollment-phase-item-left">
          <h4 className="mob-sm-text">Practice
            Management</h4>
            <p className="mob-sm-text">PcM</p>

        </div>
        <div className="enrollment-phase-item-right">
          <img className="img-fluid" src="../assets/images/courseenrollment1.png" />
        </div>
      </div>
      <div className="enrollment-phase-one-item card-bg2">
        <div className="enrollment-phase-item-left">
          <h4 className="mob-sm-text">Project<br/>
            Management</h4>
            <p className="mob-sm-text">PjM</p>
        </div>
        <div className="enrollment-phase-item-right">
          <img className="img-fluid" src="../assets/images/courseenrollment2.png" />
        </div>
      </div>
      <div className="enrollment-phase-one-item card-bg3">
        <div className="enrollment-phase-item-left">
          <h4 className="mob-sm-text">Programming <br/>
            & Analysis</h4>
            <p className="mob-sm-text">PA</p>
        </div>
        <div className="enrollment-phase-item-right">
          <img className="img-fluid" src="../assets/images/courseenrollment3.png" />
        </div>
      </div>
      <div className="enrollment-phase-one-item card-bg4">
        <div className="enrollment-phase-item-left">
          <h4 className="mob-sm-text">Project Planning<br/>
            & Design</h4>
            <p className="mob-sm-text">PPD</p>
        </div>
        <div className="enrollment-phase-item-right">
          <img className="img-fluid" src="../assets/images/courseenrollment4.png" />
        </div>
      </div>
      <div className="enrollment-phase-one-item card-bg5">
        <div className="enrollment-phase-item-left">
          <h4 className="mob-sm-text">Project<br/>
            Development &<br/>
            Documentation</h4>
            <p className="mob-sm-text">PDD</p>
        </div>
        <div className="enrollment-phase-item-right">
          <img className="img-fluid" src="../assets/images/courseenrollment5.png" />
        </div>
      </div>
      <div className="enrollment-phase-one-item card-bg6">
        <div className="enrollment-phase-item-left">
          <h4 className="mob-sm-text">Construction<br/>
            & Evaluation</h4>
            <p className="mob-sm-text">CE</p>
        </div>
        <div className="enrollment-phase-item-right">
          <img className="img-fluid" src="../assets/images/courseenrollment6.png" />
        </div>
      </div>

      <div id="hover-btn-area3" className="hover-btn-area">
        <button data-toggle="modal"
        data-target="#myModal" className="learn-more-btn">Learn More</button>
      </div>
    </div>
  </div>
  </div>
  <div className="courseenrollment-section-5 courseenrollment-section-3">
    <p className="bold-heading">$699 </p>
    <p className="bold-sub-heading mb-0">*$116 Per ARE Course* </p>
  </div> */}
  </>
    );
}

export default ComboCourses;
