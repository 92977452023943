import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  addToCartItem,
  getComboCourseDeta,
  getComboCourseDetails,
  getCourseDetails,
} from "../../reducers/coursesReducer";
import ComboCourseDetails from "../Landing/ComboCourseDetails";

const ComboCourseItem = ({
  eachObject,
  count,
  indexValue,
  typeOfRender,
  comboLearnMoreOpen,
  index,
  mobileTap = function temp(){},
}) => {
  const [modalShow, setModalShow] = useState(false);

  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state?.course?.cartListData);
  const user = useSelector((state) => state?.authReducer?.userData);
  const combocourse = useSelector((state) => state?.course?.comboCourseDetails);
  const combocourseMetaData = useSelector(
    (state) => state?.course?.comboCourseMetaData
  );
  const userCourseList = useSelector((state) => state?.course?.userCoursesList);
  const [successText, setSuccessText] = useState("");
  const [errorText, setErrorText] = useState("");
  const [comboData, setComboData] = useState(null);
  const [metaCombo, setMetaCombo] = useState(null);
  const courseDetail = useSelector((state) => state?.course?.courseDetails);
  // const [learnMoreActive, setLearnMoreActive] = useState(false)

  const openCourseDetails = async (comboName) => {
    // setLearnMoreActive(false)
    // console.log('---comboName---',comboName)

    // await dispatch(getComboCourseDeta(comboName)).then(async (response) => {
    //   await dispatch(getComboCourseDetails(response[0]?.courses)).then(
    //     (res) => {
    //       setComboData(res);
    //       setModalShow(true);
    //       setErrorText("");
    //       setSuccessText("");
    //     }
    //   );
    //   // setMetaCombo(response[0]);
    // });
    dispatch(getCourseDetails(comboName)).then(() => {
      // setComboData(res);
      setModalShow(true);
      setErrorText("");
      setSuccessText("");
    });
  };

  const courseExist = (courseId) => {
    return userCourseList?.some(function (course) {
      return course?.comboName === courseId;
    });
  };

  const test = () => {};

  const addToCartMethod = (data) => {
    setErrorText("");
    setSuccessText("");
    const isExist = courseExist(data?.comboName);
    if (user?.uid) {
      if (!isExist) {
        // console.log("cardItem>>>",data);
        const arr = [];
        let cartArr;
        let existCheck = 2;
        data.uid = user.uid;
        arr.push(data);
        // console.log('---cart items from combo -----',cartItems)
        if (cartItems) {
          const itemCheck =
            cartItems &&
            cartItems?.map(
              (each, i) => each.cartDocumentId !== data.documentId
            );
          // console.log("data", itemCheck, itemCheck.includes(false));
          if (itemCheck.includes(false)) {
            setErrorText("Already added to cart");
            existCheck = 4;
            cartArr = [...cartItems];
          } else {
            setSuccessText("Added to cart");
            cartArr = [...cartItems, ...arr];
          }
        } else {
          setSuccessText("Added to cart");
          cartArr = arr;
        }
        if (existCheck === 2) {
          dispatch(addToCartItem(arr));
        }
      } else {
        setErrorText("Already Purchased");
      }
    } else {
      setErrorText("You must login");
    }
  };
  return (
    <>
      {modalShow && (
        <ComboCourseDetails
          closeModal={() => {
            setModalShow(false);
          }}
          addToCart={addToCartMethod}
          eachObject={eachObject}
          successText={successText}
          errorText={errorText}
          camboData={courseDetail}
        />
      )}
      {typeOfRender !== "shopping" && (
        <div className="courseenrollment-section-3">
          <p className="bold-heading">{eachObject?.comboName}</p>
          <p className="bold-sub-heading">{eachObject?.comboDescription}</p>
        </div>
      )}
      <div
        className={`${
          eachObject?.courseListArr?.length > 3
            ? "courseenrollment-section-6"
            : ""
        } courseenrollment-section-4`}
      >
        <div
          className="courseenrollment-lightGray-border"
          // onClick={() => {

          //   openCourseDetails(eachObject?.comboName);
          //   setErrorText("");
          //   setSuccessText("");

          // }}
        >
          <div
            className="courseenrollment-darkGray-bg"
            onTouchStart={() =>
              comboLearnMoreOpen ? test() : mobileTap(index)
            }
            onClick={() => (comboLearnMoreOpen ? test() : mobileTap(index))}
          >
            {eachObject?.courseListArr?.map((data, i) => {
              const rgbValue =
                data?.rgbValues?.rValue +
                "," +
                data?.rgbValues?.gValue +
                "," +
                data?.rgbValues?.bValue;
              const darkRgbValue =
                data?.darkRgbValues?.rValue +
                "," +
                data?.darkRgbValues?.gValue +
                "," +
                data?.darkRgbValues?.bValue;

              return (
                <div
                  className="enrollment-phase-one-item card-bg6"
                  key={i}
                  style={{
                    background: `rgb(${rgbValue})`,
                    borderLeft: `15pt solid rgb(${darkRgbValue})`,
                  }}
                >
                  <div className="enrollment-phase-item-left">
                    <h4>{data?.name}</h4>
                    <p>{data?.nickname}</p>
                  </div>
                  <div className="enrollment-phase-item-right">
                    <img className="img-fluid" src={data?.image} />
                  </div>
                </div>
              );
            })}

            {typeOfRender !== "shopping" && (
              <div
                id="hover-btn-area"
                className={
                  comboLearnMoreOpen
                    ? "res-hover-btn-area hover-btn-area"
                    : "hover-btn-area"
                }
              >
                <button
                  data-toggle="modal"
                  // data-target="#myModal" className="learn-more-btn" onClick={()=>addCart(eachObject)}>Learn More</button>
                  data-target="#myModal"
                  className="learn-more-btn"
                  onClick={() => {
                    openCourseDetails(eachObject?.comboName);
                  }}
                >
                  Learn More
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {typeOfRender !== "shopping" && (
        <div className="courseenrollment-section-5 courseenrollment-section-3">
          <p className="bold-heading">${eachObject?.price}</p>
          <p className="bold-sub-heading">
            *${eachObject?.perCoursePrice} Per ARE Course*
          </p>
        </div>
      )}
      {count !== indexValue && (
        <div className="courseenrollment-line custom-line-two"></div>
      )}
    </>
  );
};

export default ComboCourseItem;
