import {getAuth,signInWithEmailAndPassword,sendPasswordResetEmail,GoogleAuthProvider,FacebookAuthProvider,
  signInWithPopup,createUserWithEmailAndPassword,sendEmailVerification,updateProfile, updateEmail, updatePassword} from 'firebase/auth';
  import {collection,addDoc,doc,query,where,getDocs} from 'firebase/firestore'
  import {db} from '../firebase'
  
const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const LOGIN_FAILURE="LOGIN_FAILURE";
const GET_USER_PAYMENT_DETAIL_SUCCESS="GET_USER_PAYMENT_DETAIL_SUCCESS";
const GET_USER_PAYMENT_DETAIL_FAIL="GET_USER_PAYMENT_DETAIL_FAIL";

// const FORGOT_LINK_SUCCESS = "FORGOT_LINK_SUCCESS";
// const FORGOT_LINK_FAILURE="FORGOT_LINK_FAILURE";
const rediectionUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:3000/login' : 'https://www.arelunchbox.com/login'
const initialState = {
     userData:null,
     userPaymentData:null
}
const auth = getAuth();

export function updateUserData(currentAuth)
{
  return {
    type:LOGIN_SUCCESS,
    payload:currentAuth
  }
}

export function loginFireApi({ email,password }) {

    return async (dispatch) => {
      return  new Promise(function(resolve, reject) {
      try {
        //   console.log("loginApi");
         signInWithEmailAndPassword(auth,email,password).then((res)=>{
            // console.log("res",auth);
            if(res?.user?.emailVerified){
              localStorage.setItem('userAccessToken',res.user.accessToken)
              localStorage.setItem('password',password)
              
              // localStorage.setItem('userFullName',res.user.accessToken)
              // console.log(res)
              dispatch({
                type:LOGIN_SUCCESS,
                payload:res?.user
            })
            resolve();
            }else{
              dispatch({
                type:LOGIN_FAILURE,
                payload:null
            })
            reject({code:'Email is not verified'})
            }
            
        }).catch((error)=>{
            dispatch({
                type:LOGIN_FAILURE,
                payload:error
            })
            reject(error)
            // console.log("signerror",error);
          })

      } catch (err) {
        // console.log("outCatch",err?.response?.data);
        reject(err)
        dispatch({
          type: LOGIN_FAILURE,
          payload: err,
        })
      }
    });
    }
  }

  export function forgotPasswordLink({ email }) {

    return async (dispatch) => {
      return  new Promise(function(resolve, reject) {
      try {
        const actionCodeSettings = {
          // After password reset, the user will be give the ability to go back
          // to this page.
          url: rediectionUrl,
          handleCodeInApp: false
        };
        sendPasswordResetEmail(auth, email,actionCodeSettings)
        .then(() => {
        resolve()
        })
        .catch((error) => {
          // const errorCode = error.code;
          // const errorMessage = error.message;
          reject(error);
        });
      } catch (err) {
        // console.log("outCatch",err?.response?.data);
        // dispatch({
        //   type: LOGIN_FAILURE,
        //   payload: err,
        // })
      }
    });
    }
  }

  export function socialSignIn ({provide}){
    return async (dispatch)=>{
      return  new Promise(function(resolve, reject) {
        const provider = provide === 'google' ? new GoogleAuthProvider() : new FacebookAuthProvider();
        // console.log(auth,"provider")
        signInWithPopup(auth, provider)
        .then((result) => {
          // console.log("called")   
          // This gives you a Google Access Token. You can use it to access the Google API.
          const credential = provide === 'google' ? GoogleAuthProvider.credentialFromResult(result) : FacebookAuthProvider.credentialFromResult(result);
          const token = credential.accessToken;
          // console.log("credential",credential)
          // The signed-in user info.
          const user = result.user;

          localStorage.setItem('userAccessToken',token)
          localStorage.setItem('Provider',result.providerId)
          // console.log(result.providerId)
          // console.log(credential)
          
        // console.log(`this is user token ${localStorage.getItem('userAccessToken')}`)
          // console.log("user",auth?.currentUser?.displayName)
          // console.log("user>>>>",result);
          dispatch({
            type:LOGIN_SUCCESS,
            payload:result?.user
          })
          resolve();
          // ...
        }).catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.email;
          // The AuthCredential type that was used.
          // const credential = GoogleAuthProvider.credentialFromError(error);
          // console.log('errorCode>>>',error);
          // ...
        
          dispatch({
            type:LOGIN_FAILURE,
            payload:error
          })
          reject()
        });
      });
    }
  }

  export function sendContactMail(headContent,bodyContent,Email)
  {
    return new Promise(function(resolve,reject){
      addDoc(collection(db, "contact_us"), {
        body:bodyContent,
        email:Email,
        heading:headContent,
        date:new Date(),
        to:'info@arelunchbox.com',
        message: {
          subject:Email + ' - ' + headContent,
          html:bodyContent,
        }
      })
      .then(() => {
        // console.log(headContant)
        resolve();
      })
      .catch((error) => {
        reject();
      });
    })
  }

  export function updateUserEmailId(email)
  {
    return new Promise(function(resolve,reject){
      updateEmail(auth.currentUser, email)
      .then(() => {
        // console.log("success");
        const actionCodeSettings = {
          // After password reset, the user will be give the ability to go back
          // to this page.
          url: rediectionUrl,
          handleCodeInApp: false
        };
        sendEmailVerification(auth.currentUser,actionCodeSettings).then(() => {
          // console.log("successfully sent link");
          resolve();
        });
      })
      .catch((error) => {
        reject();
      });
    })
  }



  export function updateUserProfile(currentUser,fname,lname,phonenumber,profileUrl)
  {
    const pd=currentUser.providerData;
    pd[0].phoneNumber=1234561230;
    return new Promise(function(resolve,reject){
      // console.log(phonenumber,"qwerty");
      updateProfile(currentUser, {
        displayName: `${fname} ${lname}`,
        photoURL: profileUrl,
        phoneNumber :phonenumber,
        providerData : pd,
      }).then(() => {
        // console.log(currentUser.providerData[0].phoneNumber,"qwerty");
        resolve();
      })
      .catch((error) => {
        // console.log(error);
        reject();
        // toast.error(error?.code)
      });
    })
  }

  export function updateUserPassword(newPassword)
  {
    const temp=getAuth();
    // console.log("auth",auth.currentUser)
    return new Promise(function(resolve,reject){
      updatePassword(auth.currentUser, newPassword)
      .then(() => {
        // console.log("sucess");
        resolve();
      })
      .catch((error) => {
        // console.log("errorrrr");
        // console.log(error)
        reject();
      });
    })
  }

  export function createUser ({email,password,firstName,lastName,profileUrl}){
    return async (dispatch)=>{
     
      return  new Promise(function(resolve, reject) {
        createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed in 
      const user = userCredential.user;
      const displayName = firstName+' '+lastName;
      // console.log("uwr",user);
      updateProfile(auth.currentUser, {
        displayName, photoURL: profileUrl
      }).then(() => {
        // Profile updated!
        // console.log("eres",res);
        const actionCodeSettings = {
          url: rediectionUrl,
          handleCodeInApp: false
        }
        sendEmailVerification(auth.currentUser,actionCodeSettings)
    .then(() => {
      // Email verification sent!
      // ...
     resolve()
    });
      }).catch((err)=>{
        reject()
      })
      // ...
    })
    .catch((error) => {
      reject(error)
    });
      });
    }
  }

  export function userPaymentDetails (uid) {
    return async (dispatch) => {
        try{
          const querySnapshot = await getDocs(query(collection(db, "user_payment"),where('userUid',"==",uid)));
          let data =[];
          querySnapshot.forEach((doc) => {
              // doc.data() is never undefined for query doc snapshots
              data.push(doc.data());
          });
          // console.log("data",data);
          dispatch({
              type: GET_USER_PAYMENT_DETAIL_SUCCESS,
              payload: data
          })
        }catch(err){
            dispatch({
                type:GET_USER_PAYMENT_DETAIL_FAIL,
                payload:null
            })
        }
    }
  }

 const authReducer = (state = initialState, action) => { 
    switch (action.type) {

            case LOGIN_SUCCESS : {
                return {
                    ...state,
                    userData:action.payload
                }
            }
            case LOGIN_FAILURE : {
                return {
                    ...state,
                    userData:null
                }
            }
            case GET_USER_PAYMENT_DETAIL_SUCCESS: {
              return {
                ...state,
                userPaymentData : action.payload
              }
            }
            case GET_USER_PAYMENT_DETAIL_FAIL: {
              return {
                ...state,
                userPaymentData : action.payload
              }
            }
    default:
            return {
                ...state,
            }
        }
}
export default authReducer;
