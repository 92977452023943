import React,{useEffect,useState} from 'react';
import {useSelector} from 'react-redux'
import IndividualCourseCard from './IndividualCourseCard';
import { useDispatch } from 'react-redux';
import { addToCartItem } from '../../reducers/coursesReducer';
import { toast } from 'react-toastify';
const IndividualCourses = ({openModule}) => {
  const [list,setList]=useState(null);
  const dispatch =useDispatch();
  // const [addItems,setAddItems]=useState(null);
    const courseList = useSelector((state)=>state?.course?.coursesList);
    const cartItems = useSelector((state)=>state?.course?.cartListData);
    const [learnMoreActive, setLearnMoreActive] = useState([])
    
    useEffect(()=>{
        setList(courseList);
        setLearnMoreActive(new Array(courseList?.length).fill(false))
    },[courseList,cartItems]);

    const onMobileTap=(index)=>{
      const updatedCheckedState = learnMoreActive.map((item, i) =>
      index === i ? !item : false,
       );
      setLearnMoreActive(updatedCheckedState)
      // console.log("mobile tab called")  
} 
    

    return (
      <div className="custom-popup-sm enrollment-phase-one-spacing">
      <div className="row">
          {list ? list.map((each,i)=> <IndividualCourseCard eachObject={each} key={i} openModules={openModule} learnMoreOpen={learnMoreActive[i]} index={i} mobileTap={onMobileTap}/>) : <p>No Individual Courses</p>}   
      </div>
    </div>
      
    );
}

export default IndividualCourses;
