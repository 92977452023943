import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { useHistory } from 'react-router-dom';

const Team = () => {
  
  useEffect(() => {
    window.scroll(0,0);
   }, [])
  const history=useHistory();
    return (
        <>
        <Header />
        <section>
        <div className="common-spacing containerWidth">
        <div className="common-heading-section">
          <h2 className="common-title">Meet the Team</h2>
        </div>
        <div className="common-whole-content-section">
          <div className="common-content-section">
            <h3 className="common-width-space common-bold-title">
            Brian Rohrlick and Mark Besquin are two California-Licensed Architects and Alumni from the <span>USC School of Architecture</span> (Bachelor of Architecture, class of 2018).
            </h3>
            <div className="meetteam-section">

              <div className="meetteam-text">
                <div className="common-content-section">
                  <h3 className="common-bold-title">Brian Rohrlick</h3>
                  <p>
                  Brian Rohrlick, a SoCal native, was born and raised in Ridgecrest, California. Deeply passionate for all things “architecture”, Brian is currently an Architect at Gensler in Los Angeles, where he holds regional leadership positions in professional licensure, design technology, and visualization. His project experience varies in type and scale - from interior design, to modular housing, office complexes, and various civic facilities.
                  </p>
                  <p>
                  In his free time, Brian enjoys immersing himself in outdoor adventures, including surfing, snowboarding, hiking, and traveling in general. You can also find Brian weight-training at the gym or relaxing at home reading science-fiction, practicing piano, or playing with his cat Finn.
                  </p>
                </div>
              </div>
              <div className="meetteam-img">
                <img src="../assets/images/Brian.png" alt="teammember" />
              </div>
            </div>
            <div className="common-border-line1"></div>
            <div className="meetteam-section">

              <div className="meetteam-text">
                <div className="common-content-section">
                  <h3 className="common-bold-title">Mark Besquin</h3>
                  <p>
                  Mark Besquin, also a SoCal native, was born and raised in San Diego, California. Interested in both design and construction, Mark has managed to find a way to gain ample experience in both. Mark is very passionate about residential architecture, but his resume shows a wide variety of experience on various project types. He is currently a Project Engineer at Hill Construction Co. pursuing his Contractor’s license, while building some of the world’s most luxurious custom homes.
                  </p>
                  <p>
                  In his free time, Mark enjoys a variety of outdoor activities, including skiing, climbing, and cycling. When he’s not venturing outdoors, Mark enjoys learning about real estate and watching movies for a relaxing night in.
                  </p>
                </div>
              </div>
              <div className="meetteam-img">
                <img src="../assets/images/Mark.png" alt="teammember1" />
              </div>
            </div>
            {/*  <div className="common-border-line1"></div>
            <div className="meetteam-section">
              <div className="meetteam-img">

              </div>
              <div className="meetteam-text">
                <div className="common-content-section">
                  <h3 className="common-bold-title">Consultants</h3>
                </div>
              </div>
            </div> */}
            <div className=""> <button type="button" className="btn yellow-btn" onClick={()=>history.push('/contactUs')}>Contact Us</button></div>
          </div>
        </div>
      </div>
      </section>
      <Footer />
      </>
    );
}

export default Team;
