import React from "react";
import moment from "moment";

const ViewAccessPeriod = ({ closeModal, eachObject }) => {
  return (
    <div className="backDrop">
      <div
        class="activate-custom-modal modal fade show d-block"
        id="activateModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="activateModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content text-center">
            <h5 className="mt-2">Access Period</h5>
            <div class="activate-modal-body modal-body">
              <p>
                Start Date :{" "}
                {moment
                  .unix(eachObject?.startDate?.seconds)
                  .format("MM-DD-YYYY hh:mm A")}
              </p>
              <p>
                End Date :{" "}
                {moment
                  .unix(eachObject?.endDate?.seconds)
                  .format("MM-DD-YYYY hh:mm A")}
              </p>
            </div>
            <div class="modal-footer">
              <div class="activate-modal-footer text-center">
                <a onClick={closeModal} style={{ width: "100%" }}>
                  Ok
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAccessPeriod;
