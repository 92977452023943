import { getAuth, sendEmailVerification, updateEmail } from "firebase/auth";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { updateUserEmailId } from "../../reducers/authReducer";
import { useHistory } from "react-router";

const ChangeEmail = () => {
  const auth = getAuth();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [saveSuccess,setSaveSuccess]=useState(false);
  const history = useHistory();

  const sumbitChangeEmail = (e) => {
    e.preventDefault();
    if (validation()) {
      updateUserEmailId(email)
        .then(() => {
          setEmail("");
          toast.success("update email successfully");
          setSaveSuccess(true);
          setTimeout(()=>{
            setSaveSuccess(false);
            auth.signOut();
          history.push('/login');
          localStorage.removeItem('userAccessToken');
          localStorage.removeItem('email');
          localStorage.removeItem('Provider');
          },1000)
          
        })
        .catch((error) => {
          toast.error(error?.code);
        });
    } else {
      // console.log("validation error");
    }
  };

  const validation = () => {
    let valid = false;
    if (email) {
      const regex =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      if (regex.test(email)) {
        valid = true;
        setEmailError("");
      } else {
        valid = false;
        setEmailError("Please enter valid email");
      }
    } else {
      valid=false;
      setEmailError("Please enter the email");
    }
    return valid;
  };

  return (
    <div className="profile-details">
      <div className="profile-details-sec1">
        <p>
          Change Email Address{" "}
          {saveSuccess &&<span>
            Saved <i className="fas fa-check"></i>
          </span>}
        </p>
      </div>
      <div className="profile-details-main">
        <form className="mt-0 profile-detail-form">
          <div className="w-100 custom-form-group">
            <label htmlFor="email">New Email Address</label>
            <input
              type="text"
              id="email"
              name="email"
              className="form-control"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {emailError && <span className="text-danger">{emailError}</span>}
          </div>
        </form>
        <p>
          Upon clicking “Save New Email Address”, a confirmation email will be
          sent to verify. In the email, click “Confirm New Email Address” and
          log in using the new email address.
        </p>
      </div>
      <div className="w-100 d-flex justify-content-end email-save-changes-btn">
        <button
          type="button"
          className="btn yellow-btn-sm"
          onClick={(e) => sumbitChangeEmail(e)}
        >
          Save New Email Address
        </button>
      </div>
    </div>
  );
};

export default ChangeEmail;
