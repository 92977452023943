import React from "react";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const CourseProgress = ({ rgbValue, percentage }) => {
  // console.log(typeof(percentage));
  return (
    <div style={{ width: 90, height: 90 }}>
      {percentage === 100 ? (
        <CircularProgressbarWithChildren
          value={percentage}
          strokeWidth={14}
          styles={{
            path: {
              stroke: `rgb(${rgbValue})`,
              // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
              strokeLinecap: "butt",
              transition: 0.5,
              // Text size
            },
            text: {
              fill: "#ffffff",
              fontSize: "22px",
            },
            trail: {
              stroke: "#868484",
            },
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 26.08 18.35"
            width="40px"
          >
            <defs></defs>
            <g id="Layer_2" data-name="Layer 2">
              <g id="Layer_6" data-name="Layer 6">
                <polyline
                  style={{
                    fill: "none",
                    stroke: "#fff",
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "5.5px",
                  }}
                  points="2.75 7.87 10.48 15.6 23.33 2.75"
                />
              </g>
            </g>
          </svg>
        </CircularProgressbarWithChildren>
      ) : (
        <CircularProgressbar
          value={percentage}
          strokeWidth={14}
          text={`${percentage}%`}
          styles={{
            path: {
              stroke: `rgb(${rgbValue})`,
              // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
              strokeLinecap: "butt",
              transition: 0.5,
              // Text size
            },
            text: {
              fill: "#ffffff",
              fontSize: "22px",
            },
            trail: {
              stroke: "#868484",
            },
          }}
        />
      )}
    </div>
  );
};

export default CourseProgress;
