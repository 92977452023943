import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userPaymentDetails } from '../../reducers/authReducer';
import { getUserCourses } from '../../reducers/coursesReducer';
import CurrentSubcription from './CurrentSubcription';
import PaymentHistory from './PaymentHistory';
import PreviousSubcription from './PreviousSubcription';

const Subscription = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state)=>state?.authReducer.userData);
  useEffect(()=>{
    dispatch(userPaymentDetails(userInfo?.uid));
    dispatch(getUserCourses({uid : userInfo?.uid}))
  },[])
    return (
        <>
          <CurrentSubcription />
          <PreviousSubcription />
          <PaymentHistory />  
        </>
    );
}

export default Subscription;
