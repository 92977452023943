import React from "react";

const CourseResetConfirmation = ({ closeModal, updateProgress }) => {
  return (
    <div className="backDrop">
      <div
        class="activate-custom-modal modal fade show d-block"
        id="activateModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="activateModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="activate-modal-body modal-body">
              <h5>Are you sure you want to reset your progress?</h5>
              <p>
                Resetting progress will revert all modules in this course to
                'Unmarked'{" "}
              </p>
            </div>
            <div class="modal-footer">
              <div class="activate-modal-footer">
                <a onClick={closeModal}>No</a>
                <a onClick={updateProgress}>Yes</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseResetConfirmation;
