import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';

const PaymentHistory = () => {

    const userPaymentList = useSelector((state)=>state?.authReducer?.userPaymentData);
    // console.log(userPaymentList)

    const downloadPayReceipt=(url)=>{
        const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "receipt");
          link.setAttribute("target", "_blank"); //or any other extension
          document.body.appendChild(link);
          link.click();
    }
    return (
        <div className="subscription-details">
        <p>Payment History</p>
        <div className="table-responsive">
        <table className="subscription-table"><tbody>
            <tr>
                <th>Date</th>
                {/* <th>Invoice Number</th> */}
                <th>Description</th>
                <th>Amount</th>
                <th></th>
            </tr>
           {userPaymentList?.length > 0 ? userPaymentList?.map((each,i)=>
            <tr key={i}>
                <td>{moment.unix(each?.purchaseDate?.seconds).format("MM-DD-YYYY")}</td>
                {/* <td>-</td> */}
                <td>{each?.description}</td>
                <td>${each?.finalAmount}</td>
                <td className="btn-td">
                    <button type="button" onClick={()=>downloadPayReceipt(each?.invoiceLink)}className="btn">Show Receipt</button>
                </td>
            </tr>
           ) : <tr><td colSpan={5}>No Record Found</td></tr>} 
           </tbody>
        </table>
        </div>
    </div>

    );
}

export default PaymentHistory;
