import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  const navigate=(URL)=>{
    window.location=URL;
  }
    return (
      <footer className="footer">
      <div className="footer-logo">
      <img src="../assets/images/logo.png" />
      </div>
      <div className="footer-social-icons">
        <ul>
          <li onClick={()=>navigate("https://www.instagram.com/are_lunchbox/")}><img src="../assets/images/footer.png"/></li>
          <li onClick={()=>navigate("https://www.facebook.com/arelunchbox")}><img src="../assets/images/footer1.png"/></li>
          <li onClick={()=>navigate("https://www.linkedin.com/company/are-lunchbox")}><img src="../assets/images/footer2.png"/></li>
        </ul>
        </div>
        <div className="footer-links">
          <ul>
            <li><Link to="/termsAndConditions">Terms and Conditions</Link></li>
            <li><Link to="/privacyPolicy">Privacy Policy</Link></li>
            <li><Link to="/disclaimer">Disclaimer</Link></li>
          </ul>
        </div>
        <div className="footer-text">
          <p>ARE Lunchbox &#169; 2021. All Rights Reserved.</p>
        </div>
      </footer>
    );
}

export default Footer;
