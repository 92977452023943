import { WindowsBalloon } from "node-notifier";
import React, { useEffect, useRef, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";



const CourseDocumentView = ({
  closeModal,
  courseDetails,
  courseInfo,
  confidentLevel,
  nextModule,
  prevModule,
}) => {
  const docId = courseDetails ? courseDetails.documentId : null;
  const isCutomStyle=courseDetails["content"] ? true :false;
  

  const confidentLevelData = useSelector(
    (state) => state?.course?.confidentLevelData
  );

  const [confidentValue, setConfidentValue] = useState(0);
  const [error, setError] = useState(false);
  

  const myRef = useRef(null)
  const executeScroll = () => myRef.current.scrollIntoView()

  useEffect(() => {
    setConfidentValue(courseDetails?.confidenceLevel);
    
  }, []);

 
  useEffect(() => {
    setConfidentValue(courseDetails?.confidenceLevel);
    // console.log('------courseDetails-------',courseDetails)
  }, [courseDetails]);

  const nextContant = () => {
    executeScroll();
    nextModule();
    setConfidentValue(0);
    
  };
  const prevContant = () => {
    executeScroll();
    prevModule();
    setConfidentValue(0);
  };




  const progressValue = (val) => {
    switch (val) {
      case 0:
        return [];
      case 1:
        return [1];
      case 2:
        return [1, 2];
      case 3:
        return [1, 2, 3];
      default:
        return [];
    }
  };
  // console.log("mod",totalModules);

  const confidenceLevelProgress = progressValue(confidentValue);
  const onChangeConfident = (value) => {
    if (docId) {
      setError(false);
      setConfidentValue(value);
      confidentLevel(value, docId);
    } else {
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 1000);
    }
  };
  // const conLevel = courseDetails.confidenceLevel;
  const darkRgbValue =
    courseInfo?.darkRgbValues?.rValue +
    "," +
    courseInfo?.darkRgbValues?.gValue +
    "," +
    courseInfo?.darkRgbValues?.bValue;
  // const rgbValue = courseDetails?.rgbValues?.rValue + ',' + courseDetails?.rgbValues?.gValue + ',' + courseDetails?.rgbValues?.bValue;
  return (
    <div className="custom-popup modal show in d-block backDrop">
      
      <div className="modal-dialog">

        <div className={isCutomStyle ? "modal-content":"custom-height-modal-content modal-content"}>
          <div className="modal-header">
            <button
              type="button"
              className="close"
              onClick={() => closeModal()}
            >
              <span>&times;</span>
            </button>
            <div className="popup-heading-arrow">
              {courseDetails?.moduleNo > 1 && (
                <div
                  className="popup-left-arrow"
                  style={{ cursor: "pointer" }}
                  onClick={prevModule}
                >
                  <img src="../assets/images/popupleftarrow.png" />
                </div>
              )}
              <h4 className="modal-title">
                {/* 2.1 <b>Contractual Relationships Flow Chart</b> */}
                {courseDetails?.sectionNo}.{courseDetails?.moduleNo}{" "}
                <b>{courseDetails.moduleName}</b>
              </h4>
              {courseDetails?.totalModules > courseDetails?.moduleNo && (
                <div
                  className="popup-left-arrow"
                  style={{ cursor: "pointer" }}
                  onClick={nextModule}
                >
                  <img src="../assets/images/popuprightarrow.png" />
                </div>
              )}
            </div>
          </div>
          <div
            className="popup-stepper"
            style={{ border: `1pt solid rgb(${darkRgbValue})` }}
          >
            {confidenceLevelProgress &&
              confidenceLevelProgress?.map((each, i) => (
                <div
                  className="popup-stepper-active popup-stepper-part"
                  key={i}
                  style={{ background: `rgb(${darkRgbValue})` }}
                ></div>
              ))}
            {/* <div className="popup-stepper-active popup-stepper-part"></div>
            <div className="popup-stepper-active popup-stepper-part"></div> */}
          </div>
          <div className="modal-body">
            <div  className={isCutomStyle ? "popup-questions-whole-section-scroll respScroll":"custom-height-respscroll popup-questions-whole-section-scroll respScroll"}>
              <div className="popup-small-text" ref={myRef}>
                {ReactHtmlParser(courseDetails["content"])}
              </div>
              <div className="confidence-meter-section">
                <h6>How confident do you feel about the material?</h6>
                <div className="confidence-meter-options">
                  <ul>
                    <li
                      className={`cursor-pointer ${
                        confidentValue === 1 ? "confidence-active" : ""
                      }`}
                      style={{
                        border: `1px solid rgb(${darkRgbValue})`,
                        background: `rgb(${
                          confidentValue === 1 ? darkRgbValue : "255,255,255"
                        })`,
                      }}
                      onClick={() => onChangeConfident(1)}
                    >
                      Low
                    </li>
                    <li
                      className={`cursor-pointer ${
                        confidentValue === 2 ? "confidence-active" : ""
                      }`}
                      style={{
                        border: `1px solid rgb(${darkRgbValue})`,
                        background: `rgb(${
                          confidentValue === 2 ? darkRgbValue : "255,255,255"
                        })`,
                      }}
                      onClick={() => onChangeConfident(2)}
                    >
                      Medium
                    </li>
                    <li
                      className={`cursor-pointer ${
                        confidentValue === 3 ? "confidence-active" : ""
                      }`}
                      style={{
                        border: `1px solid rgb(${darkRgbValue})`,
                        background: `rgb(${
                          confidentValue === 3 ? darkRgbValue : "255,255,255"
                        })`,
                      }}
                      onClick={() => onChangeConfident(3)}
                    >
                      High
                    </li>
                  </ul>
                  {error && (
                    <label className="error text-center">
                      {" "}
                      Course must be purchased/activated to enable confidence
                      meter.
                    </label>
                  )}
                </div>
              </div>
              <div className="confidence-meter-section">
                <div className="previous-next-btn">
                  {courseDetails?.moduleNo > 1 && (
                    <button onClick={prevContant}>
                      <i className="fas fa-angle-double-left"></i>
                      <span>Previous</span>
                    </button>
                  )}
                  {courseDetails?.totalModules > courseDetails?.moduleNo && (
                    <button onClick={nextContant}>
                      <span>Next</span>
                      <i className="fas fa-angle-double-right"></i>
                    </button>
                  )}
                </div>
              </div>
              <div className="see-an-error-text">
                See an error? <Link to="/contactUs">Report / Feedback</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseDocumentView;
