import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import CourseFilterSection from '../../components/Course/CourseFilterSection';
import CourseSectionList from '../../components/Course/CourseSectionList';
import CourseDetails from '../../components/Landing/CourseDetails'
import { useDispatch, useSelector } from 'react-redux';
import { updateCoursePercentage, updateUserModules,getAllCourses,getUserCourses, getUserBasedSection, getCourseDocument, storeCourseId, getAllSections, getCourseDetails, addToCartItem, resetData } from '../../reducers/coursesReducer';
import { toast } from 'react-toastify';
const Courses = () => {
  const [modalShow,setModalShow]=useState(false);
  
  const selectedCourseObj = useSelector((state)=>state?.course?.selectedCourse);
  const courseDetail = useSelector((state)=> state?.course?.courseDetails);
  // console.log("selectedCourseObj===>",selectedCourseObj);
  // console.log("courseDetail===>",courseDetail);

  // const [selectedCourseInfo,setSelectedCourseInfo]=useState(null)
const [userCourseObj, setUserCourseObj] = useState(null)
const [moduleFilterType, setmoduleFilterType] = useState('All')
useEffect(() => {
  setUserCourseObj(selectedCourseObj)
}, [selectedCourseObj])

  const user = useSelector((state)=> state?.authReducer?.userData);
  // console.log("user==>",user)
  const [successText, setSuccessText] = useState('');
  const [errorText, setErrorText] = useState('');
  const cartItems = useSelector((state)=>state?.course?.cartListData);

  const userSectionList = useSelector((state)=> state?.course?.userSectionList);
  const userCourseList = useSelector((state)=> state?.course?.userCoursesList);
  const dispatch=useDispatch();

  const updataPercentage=async(eachObject,type,purpose)=>{

    // console.log('-----purpose----',eachObject);
    const value= (type==="Mark_as_Passed") ? 100 : 0;
   dispatch(getUserBasedSection({courseId:eachObject.courseId,uid:user?.uid,type:"perCalculation"})).then(async(res)=>{
    //  console.log("respose from index==>",res)
   await Promise.all( res && res?.map(async(each,i)=>{       
    await Promise.all(each.modulesList.map((item,index)=> {
      // console.log("modulesList",item)
    dispatch(updateUserModules(type,item.documentId));
        })      
   )})
   )})
  
  
  dispatch(updateCoursePercentage({ id: eachObject.documentId, value: value }));
  // console.log('-----test----',value)
  
  //  dispatch(getAllCourses());
  // dispatch(getUserCourses({uid : user?.uid}))

 dispatch(getCourseDocument(selectedCourseObj?.courseId,selectedCourseObj?.uid)).then((res)=>{
   if(res){

    // console.log("selectedCourseObj",selectedCourseObj,"respon",res)
     const value = {...selectedCourseObj,...res}
     dispatch(storeCourseId(value))

    
   }
 });

  // await dispatch(resetData());
   dispatch(getAllSections(selectedCourseObj?.id))
   dispatch(getUserBasedSection({ courseId: selectedCourseObj?.id, uid: user?.uid }))
 toast.success(value === 0 ? 'Reset Progress successfully' : 'Mark as passed successfully') 
 }



 const courseFilter=(filterType)=>{
  setmoduleFilterType(filterType)
}

const openCourseDetails=(courseId)=>{
  dispatch(getCourseDetails(courseId)).then(()=>{
    // console.log('---coursedetails---',courseDetail)
    setModalShow(true);
  })
}
const courseExist=(courseId)=> {
  return userCourseList?.some(function(course) {
    return course?.courseId === courseId;
  }); 
}

const addToCartMethod=(data)=>{
  setErrorText('');setSuccessText('');
  // console.log(data)
  const isExist=courseExist(data?.id);
  if(user?.uid){
    if(!isExist){
  let existCheck=2;
  // console.log("cardItem>>>",addItems,data);
  const arr =[];
  let cartArr;
  data.uid=user.uid;
  arr.push(data)
  if(cartItems){
    const itemCheck = cartItems && cartItems?.map((each,i)=> each.id !== data.id);
    // console.log("data",itemCheck,itemCheck.includes(false));
    if(itemCheck.includes(false)){
      // toast.error("Aleady you have added this course to cart ");
      setErrorText('Already added to cart')
      cartArr=[...cartItems]
      existCheck=4;
    }else{
      // toast.success("Successfully added this course to cart ");
      setSuccessText('Added to cart')
      cartArr = [...cartItems, ...arr];
    }
   
  }else{
    cartArr=arr;
    // toast.success("Successfully added this course to cart ");
    setSuccessText('Added to cart');
  }
  if(existCheck === 2){      
    dispatch(addToCartItem(arr))}
  }
  else{
    setErrorText('Already purchased');
  }
  }
  else{
    setErrorText('You Must Login First')
  }
  // console.log('-------array data',arr)
}


    return (
        <>
            <Header />
            <section className="courseSection">
              {modalShow && <CourseDetails closeModal={()=>setModalShow(false)} courseDetail={courseDetail} addToCart={addToCartMethod} eachObject={selectedCourseObj} successText={successText} errorText={errorText}/>}
              {/*  */}
      <div className="course-part-main-section">
          <CourseFilterSection modalOpen={openCourseDetails} courseInfo={selectedCourseObj} updataPercentage={updataPercentage} courseFilter={courseFilter}/>
        <div className="course-section-two">
          <CourseSectionList courseInfo={selectedCourseObj} moduleFilterType={moduleFilterType} />
        </div>
      </div>

    </section>
            <Footer />
        </>
    );
}

export default Courses;
