import React, { useState } from "react";
import CourseProgress from "./CourseProgress";

const CourseCardItem = ({
  eachObject,
  updatePercentage,
  viewPeriod,
  redirectPage,
  resetProgress,
}) => {
  const [settingActive, setSettingActive] = useState("");
  const rgbValue =
    eachObject?.rgbValues?.rValue +
    "," +
    eachObject?.rgbValues?.gValue +
    "," +
    eachObject?.rgbValues?.bValue;
  const darkRgbValue =
    eachObject?.darkRgbValues?.rValue +
    "," +
    eachObject?.darkRgbValues?.gValue +
    "," +
    eachObject?.darkRgbValues?.bValue;

  return (
    <div className="col-md-4 col-lg-4 col-xl-4 col-sm-4 col-xs-12">
      <div
        className="
            enrollment-phase-one-item-md enrollment-phase-one-item
            card-bg1
          "
        style={{
          background: `rgb(${rgbValue})`,
          borderLeft: `15pt solid rgb(${darkRgbValue})`,
        }}
      >
        <div className="justify-content-center enrollment-phase-item-left">
          <span className="enrollment-phase-item-left-span">
            {eachObject?.userPaid
              ? eachObject?.isCompleted
                ? "Passed"
                : "Progress"
              : "TRIAL"}
          </span>
          <div className="custom-progress">
            {/* <div className="progress blue" >
                <span className="progress-left">
                  <span className="progress-bar" ></span>
                </span>
                <span className="progress-right">
                  <span className="progress-bar"></span>
                </span>
                <div className="progress-value">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.08 18.35"><defs></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_6" data-name="Layer 6"><polyline style={{fill:'none',stroke:'#fff',strokeLinecap:'round',strokeLinejoin:'round',strokeWidth:'5.5px'}} points="2.75 7.87 10.48 15.6 23.33 2.75"/></g></g></svg>
                  50%
                  </div>
             
              
              </div> */}
            <CourseProgress
              rgbValue={darkRgbValue}
              percentage={
                eachObject?.completionPercentage
                  ? eachObject?.completionPercentage
                  : 0
              }
            />
          </div>
          <h6>{eachObject?.name}</h6>
          <p className="custom-margin-para">{eachObject?.nickname}</p>
        </div>
        <div className="enrollment-phase-item-right">
          {eachObject?.userPaid && (
            <div className="dropdown show dropleft">
              <a
                href="#"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img
                  src="./assets/images/dashboard2/setting-light.png"
                  alt="settings"
                  className="settings-icon"
                />
              </a>

              <div
                className="custom-dropdown-menu dropdown-menu"
                aria-labelledby="dropdownMenuLink"
              >
                <a
                  className={
                    settingActive === "mark"
                      ? "dropdown-item blueColor"
                      : "dropdown-item"
                  }
                  href="#"
                  onClick={() => {
                    // updatePercentage(eachObject,100)
                    setSettingActive("mark");
                  }}
                >
                  <img
                    src="./assets/images/dashboard2/tick.png"
                    className="dd-item-img"
                    alt=""
                  />
                  Mark as passed
                </a>
                <a
                  className={
                    settingActive === "reset"
                      ? "dropdown-item blueColor"
                      : "dropdown-item"
                  }
                  href="#"
                  onClick={() => {
                    resetProgress(eachObject);
                    setSettingActive("reset");
                  }}
                >
                  <img
                    src="./assets/images/dashboard2/refresh.png"
                    className="dd-item-img"
                    alt=""
                  />
                  Reset Progress
                </a>
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={() => viewPeriod(eachObject)}
                >
                  <img
                    src="./assets/images/dashboard2/clock.png"
                    className="dd-item-img"
                    alt=""
                  />
                  Access Period
                </a>
              </div>
            </div>
          )}

          <img
            className="img-fluid"
            src={
              eachObject?.image
                ? eachObject?.image
                : "./assets/images/courseenrollment1.png"
            }
            alt="courseImages"
          />
          {eachObject?.userPaid ? (
            <a
              href="#"
              onClick={() => redirectPage(eachObject)}
              className="open-btn"
            >
              OPEN
            </a>
          ) : (
            <a
              href="#"
              onClick={() => redirectPage(eachObject)}
              className="open-btn"
            >
              ENROLL
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default CourseCardItem;
