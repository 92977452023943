import moment from 'moment';
import React,{useEffect,useState} from 'react';
import { useSelector } from 'react-redux';

const CurrentSubcription = () => {
    const [list, setList] =useState(null)
    const userActiveCourse = useSelector((state)=>state?.course?.userCoursesList);
    useEffect(() => {
        const arr = userActiveCourse?.filter((each) => moment(new Date()).isSameOrBefore(moment.unix(each?.endDate?.seconds)));
        setList(arr);
    }, [userActiveCourse]);


    // const handleTimeDifference = (startDate, endDate) => {
    //     var msDiff = new Date(endDate).getTime() - new Date(startDate).getTime(); //Future date - current date
    //     var totalDays = Math.floor(msDiff / (1000 * 60 * 60 * 24));
    //     return totalDays;
    //   };
      const [percentage, setPercentage] = useState(0);
      const [days, setDays] = useState(0);
      const [months, setMonths] = useState(0);
    
    // console.log("user Active course",userActiveCourse)
    return (
        <div className="subscription-details">
        <p>Current Subscriptions</p>
        <div className="table-responsive">
        <table className="subscription-table">
            <tbody>
            <tr>
                <th></th>
                <th>Description</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th></th>
                <th></th>
                <th></th>
            </tr>
            {list?.length > 0 ? list?.map((data,i) => {
               return <DataComponent each={data} key={i}/>
}) : <tr><td colSpan={7}> No Current Subcriptions</td></tr>}
            {/* <tr>
                <td className="green-circle"><i className="fas fa-circle"></i></td>
                <td>Project Development & Documentation (12-Month Access)</td>
                <td>04-01-2022</td>
                <td>04-01-2023</td>
                <td className="table-progress">
                    <div className="progress">
                        <div className="bar" style={{width: "29%"}}>
                        </div>
                      </div>
                </td>
                <td>50%</td>
                <td>6 Months - 2 Days Left</td>
            </tr> */}
            </tbody>
        </table>
      </div>
    </div>
                   
    );
}






const DataComponent=({each})=>{

    // console.log(each)
    const handleTimeDifference = (startDate, endDate) => {
        var msDiff = new Date(endDate).getTime() - new Date(startDate).getTime(); //Future date - current date
        var totalDays = Math.floor(msDiff / (1000 * 60 * 60 * 24));
        return totalDays;
      };

    const startDate =  moment.unix(each?.startDate?.seconds).format("MM/DD/YYYY");
    const endDate = moment.unix(each?.endDate?.seconds).format("MM/DD/YYYY");
    const totalDays=handleTimeDifference(startDate,endDate);
    
    const end = moment.unix(each?.endDate?.seconds).format("DD/MM/YYYY");
    const currentDate = moment(new Date()).format("DD/MM/YYYY");//changed formate DD-MM-YYYY
    const newCurrentDate = moment(new Date()).format("MM/DD/YYYY");
    const passesDays=handleTimeDifference(startDate,newCurrentDate);
    const balanceDay=handleTimeDifference(newCurrentDate,endDate);
    const balanceMonth=balanceDay/30;
    const balanceSingleDay=balanceDay%30;

    const daysPercentage=(passesDays/totalDays)*100;

    const startDateDiff = moment(currentDate.split('-').reverse().map((each)=>Number(each)));
    const endDateDiff = moment(end.split('-').reverse().map((each)=>Number(each)));
    const monthsValue = moment.duration(new Date(endDate).getTime() - new Date(startDate).getTime()).months();
    const daysValue = moment.duration(new Date(endDate).getTime() - new Date(startDate).getTime()).days();
   
     return <tr>
     <td className="green-circle"><i className="fas fa-circle"></i></td>
     <td>{each?.courseName}</td>
     <td>{startDate}</td>
     <td>{endDate}</td>
     <td className="table-progress">
         <div className="progress">
             <div className="bar" style={{minWidth: daysPercentage ? `${daysPercentage?.toFixed(0)}px` :"0px"}}>
             </div>
           </div>
     </td>
     {daysPercentage ? <td>{Number(daysPercentage?.toFixed(0))}%</td>:<td>0%</td>}
     {(monthsValue || daysValue) ? <td>{parseInt(balanceMonth)} months , {balanceSingleDay} days left</td> :<td>0 months , 0 days left</td>}
 </tr>
}

export default CurrentSubcription;
