import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  getAllSections,
  updateConfidentData,
  getUserBasedSection,
  getConfidentData,
  updateCoursePercentage,
  getUserCourses,
  storeCourseId,
  getCoursePercentage,
  resetData,
} from "../../reducers/coursesReducer";
import CourseDocumentView from "./CourseDocumentView";
import CourseModuleCard from "./CourseModuleCard";
import _ from "lodash";

const CourseSectionList = ({ courseInfo, moduleFilterType }) => {
  // console.log('courseInfo===>',courseInfo)
  const dispatch = useDispatch();
  const [courseViewShow, setCourseViewShow] = useState(false);
  const sections = useSelector((state) => state?.course?.sectionsList);
  const userSections = useSelector((state) => state?.course?.userSectionList);
  const user = useSelector((state) => state?.authReducer?.userData);
  const confidentLevelData = useSelector(
    (state) => state?.course?.confidentLevelData
  );
  const [sectionList, setSectionList] = useState(null);
  const [courseDetails, setcourseDetails] = useState(null);
  const [filtercount, setfiltercount] = useState("All");
  //  console.log('userSections===>',userSections)

  useEffect(() => {
    // console.log('moduleFilterType==>',moduleFilterType)
    if (moduleFilterType === "High") setfiltercount(3);
    else if (moduleFilterType === "Medium") setfiltercount(2);
    else if (moduleFilterType === "Low") setfiltercount(1);
    else if (moduleFilterType === "Unmarked") setfiltercount(0);
    if (moduleFilterType === "All") setfiltercount("All");

    //  console.log('------fil',moduleFilterType)
  }, [moduleFilterType]);

  const openModal = (dataObject) => {
    // console.log('test')
    dispatch(getConfidentData(dataObject));
    setCourseViewShow(true);
    //  console.log('testData',dataObject);
    setcourseDetails(dataObject);
  };
  const closeModal = () => {
    setCourseViewShow(false);
  };

  const updateConfidentLevel = async(level, docId) => {
    // console.log("level=>>",level,"documentid==>>>",docId,"courseInfo===>",courseInfo);
    dispatch(updateConfidentData(level, docId))
    // await coursePercentageCalculation(courseInfo)
    setTimeout(async()=>{
      await coursePercentageCalculation(courseInfo)
      // console.log("setTimeCallae")
      dispatch(getAllSections(courseInfo?.id)).then(()=>{
        dispatch(getUserBasedSection({ courseId: courseInfo?.id, uid: user?.uid }))
      })
      // window.location.reload();
    },500)

    

  };
  const coursePercentageCalculation = async (courseInfo) => {
    if (courseInfo?.uid) {
      let sectionList;
      await dispatch(getAllSections(courseInfo.id,"perCalculation")).then((res) => {
        sectionList = res;
        // console.log("alllsection==>",res)
      });
      const moduleArr =
        sectionList &&
        sectionList?.map((each, i) => {
          return each?.isTrialSection === false ? each.modulesList.length : 0;
        });
      const moduleLenght = moduleArr.length
        ? moduleArr?.reduce((a, b) => a + b)
        : 0;
      let userSectionList;
      await dispatch(
        getUserBasedSection({
          courseId: courseInfo?.courseId,
          uid: user?.uid,
          type:"perCalculation"
        })
      ).then((res) => {
        userSectionList = res;
        // console.log(res)
      });
      const userModuleArr =
        userSectionList &&
        userSectionList?.map((listData, i) => {
          const confidentValue = listData?.modulesList?.map(
            (each, j) => each?.confidenceLevel
          );
          // console.log("confidentValue==>",confidentValue)
          return confidentValue;
        });
        // console.log("modulearr==>",userModuleArr.flat())
      const userModuleLength =
        userModuleArr?.flat().length > 0
          ? userModuleArr?.flat()?.reduce((a, b) => a + b)
          : 0;
      //  console.log('----userModuleLength-----',userModuleArr,'-----moduleLenght---------',moduleLenght)
      const percentage = (userModuleLength / (moduleLenght * 3)) * 100;

      //  console.log('-----peercentage----',percentage,'---name---',courseInfo)
       
       dispatch(updateCoursePercentage({id: courseInfo?.documentId,value: percentage?.toFixed(0)}));

     await dispatch(getCoursePercentage(courseInfo)).then((res) => {
        let test = {
          ...courseInfo,
          completionPercentage: res[0].completionPercentage,
        };
       dispatch(
          storeCourseId({
            ...courseInfo,
            completionPercentage: res[0].completionPercentage,
          })
        );
        //  console.log('courseInfo===test==>',courseInfo,"percentgae==>",res[0].completionPercentage)
      });
    }
  };
  useEffect(() => {
    // console.log("cour>>>",courseInfo?.id);
    dispatch(getAllSections(courseInfo?.id));
    dispatch(getUserBasedSection({ courseId: courseInfo?.id, uid: user?.uid }));
    
  }, [courseInfo]);

  const nextModuleObject = () => {
    
    // const currentObject = courseDetails;
    // console.log('courseDetails==>',courseDetails)
    const currentSectionInd =courseDetails?.isTrialModule ? Number(courseDetails?.sectionNo) - 0 : Number(courseDetails?.sectionNo) - 1;
    const currentModuleInd = Number(courseDetails?.moduleNo) - 1;
    const moduleObject = sectionList[currentSectionInd]?.modulesList;
    if (moduleObject?.length - 1 > currentModuleInd) {
      setcourseDetails({
        ...moduleObject[currentModuleInd + 1],
        sectionNo: courseDetails.sectionNo,
        moduleNo: Number(courseDetails?.moduleNo) + 1,
        totalModules: moduleObject?.length,
      });
    }
    // window.scroll(0,0);
  };

  const previousModuleObject = () => {
    const currentSectionInd =courseDetails?.isTrialModule ? Number(courseDetails?.sectionNo) - 0 : Number(courseDetails?.sectionNo) - 1;
    const currentModuleInd = Number(courseDetails?.moduleNo) - 1;
    const moduleObject = sectionList[currentSectionInd]?.modulesList;
    // console.log("moduleObject.length - 1",moduleObject.length - 1,currentModuleInd,moduleObject.length - 1 < currentModuleInd);
    if (currentModuleInd - 1 >= 0) {
      setcourseDetails({
        ...moduleObject[currentModuleInd - 1],
        sectionNo: courseDetails?.sectionNo,
        moduleNo: Number(courseDetails?.moduleNo) - 1,
        totalModules: moduleObject?.length,
      });
    }
    // window.scroll(0,0);
  };
  useEffect(async() => {
    
    // console.log("sections",sections,userSections);
    const list =
    sections && await Promise.all(
      sections?.map(async(each, i) => {
        let object;
        let sectionStatus = false;
        userSections && await Promise.all(userSections?.map(async(data, j) => {
          // console.log("data?.courseId>>>>>>",data?.courseId,each.id,data?.courseId === each.id ,data);
          // if(data.sectionId=="PR_Risk_Development_of_Practice")
          // console.log("each sectionId",data.sectionId,j,'each completecount',data);

          if (data?.sectionId === each.id) {
            // console.log("datasectionid",data?.sectionId,"each.id",each.id)
            let moduleCompleteArray = [];
            const modulesList =await Promise.all( each?.modulesList?.map((moduleData, k) => {
              let moduleObj;
              let moduleStatus = false;
              data?.modulesList?.map((userModule, l) => {
                if (userModule?.moduleId === moduleData?.id) {
                  moduleObj = {
                    ...moduleData,
                    ...userModule,
                  };
                  if (userModule?.isCompleted) {
                    // if(userModule.moduleId==="PR_Section2_Module07")
                    // {console.log('userModule.sectionId==>',userModule?.moduleId,'==userModule.moduleId==>',moduleData,"==userModule?.isCompleted==>",userModule?.email)}
                    moduleCompleteArray.push("a");
                  }
                  moduleStatus = true;
                }
              });
              return moduleStatus ? { ...moduleObj } : { ...moduleData };
            }));
            // console.log("moduleCompleteArray",moduleCompleteArray)
            object = {
              ...each,
              ...data,
              modulesList,
              completeCount: moduleCompleteArray?.length,
            };
            sectionStatus = true;
          }
        }));
        return sectionStatus ? { ...object } : { ...each };
      }));
    // console.log("sectionStatus",list.filter((data,i)=> !data.isTrialSection));
    const listisTrialFalse =
      courseInfo?.isActivated === true
        ? list?.filter((data, i) => !data.isTrialSection)
        : list;
    const orderList = _.orderBy(listisTrialFalse, "sectionName", "asc");
    setSectionList(orderList);
    // console.log('sections',orderList)
  
  }, [sections, userSections, confidentLevelData, courseInfo]);

  let ischeck = 0;
  const darkRgbValue =
    courseInfo?.darkRgbValues?.rValue +
    "," +
    courseInfo?.darkRgbValues?.gValue +
    "," +
    courseInfo?.darkRgbValues?.bValue;
  return (
    <>
      {courseViewShow && (
        <CourseDocumentView
          closeModal={closeModal}
          courseInfo={courseInfo}
          courseDetails={courseDetails}
          confidentLevel={updateConfidentLevel}
          nextModule={nextModuleObject}
          prevModule={previousModuleObject}
        />
      )}
      {sectionList?.map((each, i) => {
        // console.log('each==>',each)
        const trialMode =
          courseInfo?.isActivated === true ? true : each?.isTrialSection;
        //  console.log('each==>',trialMode);
        return (
          <div className="custom-accordian-section" key={i}>
            {!trialMode && <div className="hideSection"></div>}
            <div
              className="custom-accordian-top"
              data-toggle={trialMode ? "collapse" : ""}
              data-target={trialMode ? `#demo${i}` : ""}
            >
              <div className="custom-accordian-section-left">
                <div className="custom-accordian-section-count">
                  {each?.sectionName}
                </div>
                <div className="custom-accordian-section-heading">
                  <h4>
                    {each?.name}{" "}
                    {!trialMode && (
                      <img
                        src="../assets/images/lock.png"
                        width="13px"
                        alt="lock"
                      />
                    )}
                  </h4>
                </div>
                <div className="custom-accordian-section-subheading">
                  <b>
                    {" "}
                    {each?.completeCount ? each?.completeCount : 0}/
                    <span>{each?.modulesList?.length}</span>
                  </b>{" "}
                  Modules Complete
                </div>
              </div>
              <div className="custom-accordian-section-right">
                <img src="../assets/images/accordion-down-arrow.png" alt="" />
              </div>
            </div>
            <div id={`demo${i}`} className="collapse" key={i}>
              <div className="row">
                {/* {console.log(each.modulesList)} */}
                {each?.modulesList.length > 0 ? (
                  each?.modulesList?.map((data, k) =>
                    data.confidenceLevel === filtercount ||
                    filtercount === "All" ? (
                      <>
                        {" "}
                        <CourseModuleCard
                          openModal={openModal}
                          dataObject={data}
                          sectionNo={each?.isTrialSection ? i : i + 1}
                          // sectionNo={i + 1}
                          key={k}
                          index={k}
                          trialStatus={each?.isTrialSection}
                          moduleNo={k + 1}
                          darkRgbValue={darkRgbValue}
                          totalModules={each?.modulesList.length}
                        />
                        <p style={{ display: "none" }}>{++ischeck}</p>
                      </>
                    ) : (
                      <></>
                    )
                  )
                ) : filtercount === "All" ? (
                  <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12 col-xs-12">
                    <p className="text-center">No modules available</p>
                  </div>
                ) : (
                  <></>
                )}
                {/* {ischeck === 0 && (
                  <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12 col-xs-12">
                    <p className="text-center">All modules are hidden</p>
                  </div>
                )} */}
                 {(ischeck === 0 && each?.modulesList.length > 0)&&(
                  <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12 col-xs-12">
                    <p className="text-center">All modules are hidden</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
        // })
      })}
    </>
  );
};

export default CourseSectionList;
