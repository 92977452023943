import React, { useEffect, useState } from 'react';
import CourseDetails from './CourseDetails';
import {addToCartItem, getAllCourses, getCourseDetails} from '../../reducers/coursesReducer'
import {useDispatch,useSelector} from 'react-redux'
import { toast } from 'react-toastify';
import { Link, useHistory } from 'react-router-dom';

const CourseAndRoadMapSection = () => {
  const dispatch = useDispatch();
    const [modalShow,setModalShow]=useState(false);
    const [list,setList]=useState(null)
    const courseList = useSelector((state)=>state?.course?.coursesList);
    const user = useSelector((state)=> state?.authReducer?.userData);
    const [successText, setSuccessText] = useState('');
    const [errorText, setErrorText] = useState('');
    const [eachData, setEachData] = useState(null);
    const cartItems = useSelector((state)=>state?.course?.cartListData);
    const courseDetail = useSelector((state)=> state?.course?.courseDetails);
    const userCourseList = useSelector((state)=> state?.course?.userCoursesList); 
    const history=useHistory();
    const [learnMoreActive, setLearnMoreActive] = useState([])
    // console.log('-----------------',courseDetail,'-----------')
    const modalOpen=()=>{
        setModalShow(true);
        // console.log('modelopen from landing')
    }
    const closeModal=()=>{
      const updatedCheckedState = learnMoreActive?.map((item, i) =>
      false
       );
      // setLearnMoreActive(true);
      // console.log("updatedCheckedState") 
      setLearnMoreActive(updatedCheckedState)
        setModalShow(false)
    }
    useEffect(()=>{
      dispatch(getAllCourses());
    },[])

    useEffect(() => {
      setList(courseList);
      setLearnMoreActive(new Array(courseList?.length).fill(false))
    }, [courseList]);

    const courseExist=(courseId)=> {
      return userCourseList?.some(function(course) {
        return course?.courseId === courseId;
      }); 
    }

    const addToCartMethod=(data)=>{
      setErrorText('');setSuccessText('');
      const isExist=courseExist(data?.id);
     if(user?.uid){    
      if(!isExist){
          let existCheck=2;
          // console.log("cardItem>>>",addItems,data);
          const arr =[];
          let cartArr;
          data.uid=user.uid;
          arr.push(data)
          if(cartItems){
            const itemCheck = cartItems && cartItems?.map((each,i)=> each.id !== data.id);
            // console.log("data",itemCheck,itemCheck.includes(false));
            if(itemCheck.includes(false)){
              // toast.error("Aleady you have added this course to cart ");
              setErrorText('Already added to cart')
              cartArr=[...cartItems]
              existCheck=4;
            }else{
              // toast.success("Successfully added this course to cart ");
              setSuccessText('Added to cart')
              cartArr = [...cartItems, ...arr];
            }
          
          }else{
            cartArr=arr;
            // toast.success("Successfully added this course to cart ");
            setSuccessText('Added to cart');
          }
          if(existCheck === 2){      
            dispatch(addToCartItem(arr))}
     }
     else{
      setErrorText('Already purchased')
     }
          }
          
            else{
            setErrorText('You must login first')
            }
      // console.log('-------array data',arr)
    }

    const openCourseDetails=(courseObj,index)=>{
      dispatch(getCourseDetails(courseObj?.id)).then(()=>{
        // console.log('---coursedetails---',courseDetail)
        setEachData(courseObj);
        
      setModalShow(true)
      const updatedCheckedState = learnMoreActive?.map((item, i) =>
      false
       );
       setLearnMoreActive(updatedCheckedState);
       const test=learnMoreActive[index] ? null:setModalShow(true);
      })
    }
const test=()=>{}


    const navigateToDashboard=()=>{
      if(user?.uid){
      history.push('/dashboard')
      }
      else{
        history.push('/login')
      }
    }
    const mobileTap=(index)=>{
      const updatedCheckedState = learnMoreActive.map((item, i) =>
      index === i ? !item : false,
       );
      setLearnMoreActive(updatedCheckedState)
      // console.log("mobile tab called")  
} 

const mobileTap1=()=>{
  const updatedCheckedState = learnMoreActive.map((item, i) =>
  false
   );
  // setLearnMoreActive(true);
  // console.log("updatedCheckedState") 
  setLearnMoreActive(updatedCheckedState)  
} 


    return (
        <>
         
        {/* <div data-aos="fade-up" className="home-page-section-3"> */}
        <div className="home-page-section-3">
        <div className="home-page-section-3-content">
      <div className="home-page-section-3-left">
        <h2 className="common-title">Courses</h2>
        <div className="home-page-section-3-whole-item">
        {modalShow && <CourseDetails closeModal={closeModal} courseDetail={courseDetail} addToCart={addToCartMethod} eachObject={eachData} successText={successText} errorText={errorText}/>}
       {list && list?.map((each,i)=>{
          const rgbValue = each?.rgbValues?.rValue + ',' + each?.rgbValues?.gValue + ',' + each?.rgbValues?.bValue;
          const darkRgbValue = each?.darkRgbValues?.rValue + ',' + each?.darkRgbValues?.gValue + ',' + each?.darkRgbValues?.bValue;
          return (<>
          
          <div  className="enrollment-phase-one-item card-bg1"  key={i} style={{ background: `rgb(${rgbValue})`, borderLeft: `15pt solid rgb(${darkRgbValue})` } }  onClick={()=>{
            learnMoreActive[i] ? test() : mobileTap(i)
            setErrorText('')
            }
            }
            onTouchStart={()=>{learnMoreActive[i] ? test() : mobileTap(i)}}
              >
                

          <div className="enrollment-phase-item-left" key={i}>
            <h4 className="mob-sm-text">{each?.name}</h4>
              <p className="mob-sm-text">{each?.nickname}</p>
    
          </div>
          <div className="enrollment-phase-item-right">
            <img className="img-fluid" alt="" src={each?.image ? each?.image : "../assets/images/courseenrollment1.png"}/>
          </div>
          <div id="hover-btn-area" className={learnMoreActive[i] ? "res-hover-btn-area hover-btn-area":"hover-btn-area"}
          
          >
            <button 
             className="learn-more-btn" onClick={()=>{
              openCourseDetails(each,i)
              setErrorText('')
            }}>Learn More</button>
          </div>
        </div></>)
       })}   
      
          {/* <div onTouchStart="myFunction2(event)" className="enrollment-phase-one-item card-bg2">
            <div className="enrollment-phase-item-left">
              <h4 className="mob-sm-text">Project<br/>
                Management</h4>
                <p className="mob-sm-text">PjM</p>
            </div>
            <div className="enrollment-phase-item-right">
              <img className="img-fluid" src="../assets/images/courseenrollment2.png"/>
            </div>
            <div id="hover-btn-area2" className="hover-btn-area">
              <button 
              onClick={()=>modalOpen()} className="learn-more-btn">Learn More</button>
            </div>
          </div>
          <div onTouchStart="myFunction3(event)" className="enrollment-phase-one-item card-bg3">
            <div className="enrollment-phase-item-left">
              <h4 className="mob-sm-text">Programming <br/>
                &amp; Analysis</h4>
                <p className="mob-sm-text">PA</p>
            </div>
            <div className="enrollment-phase-item-right">
              <img className="img-fluid" src="../assets/images/courseenrollment3.png"/>
            </div>
            <div id="hover-btn-area3" className="hover-btn-area">
              <button 
              onClick={()=>modalOpen()} className="learn-more-btn">Learn More</button>
            </div>
          </div>
          <div onTouchStart="myFunction4(event)" className="enrollment-phase-one-item card-bg4">
            <div className="enrollment-phase-item-left">
              <h4 className="mob-sm-text">Project Planning<br/>
                &amp; Design</h4>
                <p className="mob-sm-text">PPD</p>
            </div>
            <div className="enrollment-phase-item-right">
              <img className="img-fluid" src="../assets/images/courseenrollment4.png"/>
            </div>
            <div id="hover-btn-area4" className="hover-btn-area">
              <button 
              onClick={()=>modalOpen()} className="learn-more-btn">Learn More</button>
            </div>
          </div>
          <div onTouchStart="myFunction5(event)" className="enrollment-phase-one-item card-bg5">
            <div className="enrollment-phase-item-left">
              <h4 className="mob-sm-text">Project<br/>
                Development &amp;<br/>
                Documentation</h4>
                <p className="mob-sm-text">PDD</p>
            </div>
            <div className="enrollment-phase-item-right">
              <img className="img-fluid" src="../assets/images/courseenrollment5.png"/>
            </div>
            <div id="hover-btn-area5" className="hover-btn-area">
              <button 
              onClick={()=>modalOpen()} className="learn-more-btn">Learn More</button>
            </div>
          </div>
          <div onTouchStart="myFunction6(event)" className="enrollment-phase-one-item card-bg6">
            <div className="enrollment-phase-item-left">
              <h4 className="mob-sm-text">Construction<br/>
                &amp; Evaluation</h4>
                <p className="mob-sm-text">PcM</p>
            </div>
            <div className="enrollment-phase-item-right">
              <img className="img-fluid" src="../assets/images/courseenrollment6.png"/>
            </div>
            <div id="hover-btn-area6" className="hover-btn-area">
              <button 
              onClick={()=>modalOpen()} className="learn-more-btn">Learn More</button>
            </div>
          </div> */}
      
        </div>
        <div className="home-page-section-2-button">
          <button type="button" onClick={()=>navigateToDashboard()}>Free Trial</button>
        </div>
        <div className="already-member-link">
          <Link to='/enrollment'>See All Pricing  </Link>
        </div>
      </div>
      <div className="home-page-section-3-right">
        <h2 className="common-title">Development Roadmap</h2>
        <div className="history-tl-container">
          <ul className="tl">
            <li className="tl-item blue-color-text" >
              <div className="timestamp">
                Practice Management
              </div>
              <div className="item-title">Complete</div>
            </li>
            <li className="tl-item purple-color-text" >
              <div className="timestamp">
                Project Management
              </div>
              <div className="item-title">Q1 2022</div>
            </li>
            <li className="tl-item orange-color-text" >
              <div className="timestamp">
                Construction & Evaluation
              </div>
              <div className="item-title">Q1 2022</div>
            </li>
            <li className="tl-item darkblue-color-text" >
              <div className="timestamp">
                Programming & Analysis
              </div>
              <div className="item-title">Q2 2022</div>
            </li>
            <li className="tl-item litegreen-color-text" >
              <div className="timestamp">
                Project Planning & Design
              </div>
              <div className="item-title">Q4 2022</div>
            </li>
            <li className="tl-item darkred-color-text" >
              <div className="timestamp">
                Project Development &
                Documentation
              </div>
              <div className="item-title">Q4 2022</div>
            </li>
            {/* <!-- <li className="tl-item" ng-repeat="item in retailer_history">
              <div className="timestamp">
      
              </div>
              <div className="item-title"></div>
              <div className="item-detail"></div>
            </li> --> */}
      
          </ul>
      
        </div>
      </div>
      </div>
      </div>
  </>
    );
}

export default CourseAndRoadMapSection;
