import React from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import AboutSection from '../../components/Landing/AboutSection';
import BannerSection from '../../components/Landing/BannerSection';
import CourseAndRoadMapSection from '../../components/Landing/CourseAndRoadMapSection';
import HowItWorkSection from '../../components/Landing/HowItWorkSection';
import { useEffect } from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
// import {useHistory} from 'react-router-dom'

const LandingPage = () => {
    useEffect(() => {
        // console.log("object")
        AOS.init({
          duration:600
        });
      }, []);
    // const history = useHistory();
    return (
        <>
        <Header />
        <section>
            <div className="homepage-whole-section containerWidth">
            <BannerSection />
            <AboutSection />
            <CourseAndRoadMapSection />
            <HowItWorkSection />
            </div>
        </section>
        <Footer />
        </>
    );
}

export default LandingPage;
