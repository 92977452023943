import _ from 'lodash'
export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state')
    if (serializedState === null) {
      return undefined
    }
    return JSON.parse(serializedState)
  } catch (err) {
    return undefined
  }
}

export const saveState = (state) => {
  try {
    const serializeState = JSON.stringify(state)
    localStorage.setItem('state', _.cloneDeep(serializeState))
  } catch (err) {
    //ignore errors
  }
}