import React from 'react';
import ChangeEmail from './ChangeEmail';
import ChangePassword from './ChangePassword';
import ProfileDetails from './ProfileDetails';
import { getAuth} from "firebase/auth";

const ProfileInfo = () => {
    // console.log(getAuth.getInstance().getCurrentUser().getProviders())
     const Provider=localStorage.getItem('Provider')

     
    return (
        <>
        <ProfileDetails />
        {(Provider != 'google.com' && Provider != 'facebook.com')  &&
        <><ChangePassword />
        <ChangeEmail /></>
        }
        </>
    );
}

export default ProfileInfo;
