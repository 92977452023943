import React, { useState } from "react";
import { getAuth, updatePassword } from "firebase/auth";
import { toast } from "react-toastify";
import { updateUserPassword } from "../../reducers/authReducer";
const ChangePassword = () => {
  const auth = getAuth();
  const [newPassword, setnewPassword] = useState("");
  const [oldPassword, setoldPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [oldPasswordError, setoldPasswordError] = useState("");
  const [newPasswordError, setnewPasswordError] = useState("");
  const [confirmPasswordError, setconfirmPasswordError] = useState("");
  const [comparePasswordError, setcomparePasswordError] = useState("");
  const [saveSuccess,setSaveSuccess]=useState(false)

  //   const user = useSelector((state) => state?.authReducer?.userData);
  const sumbitSavePassword = (e) => {
    e.preventDefault();
    if (validation()) {
      if (newPassword != "")
        updateUserPassword(newPassword)
          .then(() => {
            setnewPassword("");
            setoldPassword("");
            setConfirmPassword("");
            setSaveSuccess(true);
            setTimeout(()=>{
            setSaveSuccess(false)
          },1000)
            toast.success("password Changed");
          })
          .catch((error) => {});
    }
  };

  const validation = () => {
    let valid = false;
    let istrue = true;
    if (oldPassword) {
      if (oldPassword.length > 5) {
        if (oldPassword == localStorage.getItem("password")) {
          valid = true;
          setoldPasswordError("");
        } else {
          valid = false;
          istrue=false;
          setoldPasswordError("your old password not match");
          // return valid;
        }
      } else {
        valid = false;
        istrue=false;
        setoldPasswordError("Password must be at least 6 characters");
        // return valid;
      }
    } else {
      valid=false;
      istrue=false;
      setoldPasswordError("Please enter the old password");
      // return valid;
    }

    if (newPassword) {
      if (newPassword.length > 5) {
        valid = true;
        setnewPasswordError("");
      } else {
        valid = false;
        istrue=false;
        setnewPasswordError("Password must be at least 6 characters");
        // return valid;
      }
    } else {
      valid=false;
      istrue=false;
      setnewPasswordError("Please enter the new password");
      // return valid;
    }

    if (confirmPassword) {
      if (confirmPassword.length > 5) {
        valid = true;
        setconfirmPasswordError("");
      } else {
        valid = false;
        istrue=false;
        setconfirmPasswordError("Password must be at least 6 characters");
      }
    } else {
      valid=false;
      istrue=false;
      setconfirmPasswordError("Please enter the confirm password");
      // return valid;
    }

    if (newPassword.length > 5 && confirmPassword.length > 5) {
      if (confirmPassword == newPassword) {
        valid = true;
        setcomparePasswordError("");
      } else {
        valid=false;
        istrue=false;
        setcomparePasswordError(
          "New Password and Confirm password doesnot matched"
        );
        // return valid;
      }
    }
    if(istrue){
      return true;
    }
    else{return false}
    
  };

  return (
    <div className="profile-details">
      <div className="profile-details-sec1">
        <p>
          Change Password{" "}
          {saveSuccess && <span>
            Saved <i className="fas fa-check"></i>
          </span>}
        </p>
      </div>
      <div className="profile-details-main">
        <form className="mt-0 profile-detail-form">
          <div className="w-100 custom-form-group">
            <label htmlFor="ePWD">Existing Password</label>
            <input
              type="password"
              id="ePWD"
              className="form-control"
              name="extPassword"
              value={oldPassword}
              onChange={(e) => setoldPassword(e.target.value)}
            />
            {oldPasswordError && (
              <span className="error-text text-danger">{oldPasswordError}</span>
            )}
          </div>
          <div className="w-100 custom-form-group">
            <label htmlFor="newPWD">New Password</label>
            <input
              type="password"
              id="newPWD"
              className="form-control"
              value={newPassword}
              name="newPassword"
              onChange={(e) => setnewPassword(e.target.value)}
            />
            {newPasswordError && (
              <span className="error-text text-danger">{newPasswordError}</span>
            )}
          </div>
          <div className="w-100 custom-form-group">
            <label htmlFor="CNP">Confirm New Password</label>
            <input
              type="password"
              id="CNP"
              className="form-control"
              name="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            {confirmPasswordError && (
              <span className="error-text text-danger">{confirmPasswordError}</span>
            )}
            {comparePasswordError && (
              <span className="error-text text-danger">{comparePasswordError}</span>
            )}
          </div>
        </form>
      </div>
      <div className="w-100 d-flex justify-content-end password-save-changes-btn">
        <button
          type="button"
          className="btn yellow-btn-sm"
          onClick={(e) => sumbitSavePassword(e)}
        >
          Save New Password
        </button>
      </div>
    </div>
  );
};

export default ChangePassword;
