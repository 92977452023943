import { trim } from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";

const ComboCourseDetails = ({
  closeModal,
  addToCart,
  eachObject,
  successText = "",
  errorText = "",
  camboData
}) => {
  let totalModule = 0;
  const user = useSelector((state) => state?.authReducer?.userData);
  const [userError, setUserError] = useState(errorText);
  const [userSuccess, setUserSuccess] = useState(successText);
  const history = useHistory();
  const [comboCourseData, setComboCourseData] = useState(null);
  const [addToCartActive, setAddToCartActive] = useState(false);
  const userCourseList = useSelector((state) => state?.course?.userCoursesList);
  // const [headData, setHeadData] = useState(metaData);
  const combocourse = useSelector((state) => state?.course?.comboCourseDetails);


  // console.log('----combo data----',headData)

  // const isCutomStyle=(combocourse?.length > 0 && metaData[0]?.description) ? true :false;
  const isCutomStyle=(1) ? true :false;
  // console.log('usecustom',isCutomStyle)

  const navigate = () => {
    // if (user?.uid) {
      
    //   setUserError("");
    // } else {
    //   setUserError("You must Login First");
    // }
    closeModal();
      history.push("/enrollment");
  };

  useEffect(() => {
    setComboCourseData(camboData);
    setUserError(errorText);
    setUserSuccess(successText);
  }, []);

  const courseExist = (courseId) => {
    if(user?.uid){
          return userCourseList?.some(function (course) {
      return course?.comboName === courseId;
    });
  }else{
    return false;
  }
  };

  const navigateToDashboard = () => {
    // alert('text');
    if (user?.uid) {
      history.push("/dashboard");
      setUserError("");
    } else {
      // setUserError("You must Login First");
      history.push("/login");
      // console.log(userError)
    }
  };
  // console.log("------detailpage cambo data----", metaData);
  return (
    <div className="custom-popup-sm">
    <div className="custom-popup modal show d-block backDrop">
      <div className="modal-dialog">
        <div className={isCutomStyle ? "modal-contant-2 modal-content" :"modal-contant-auto modal-content"}>
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={() => {
                closeModal();
                setUserError("");
                setUserSuccess("");
              }}
            >
              <span>&times;</span>
            </button>
            <div className="popup-heading-arrow">
              <h4 className="modal-title">
                <b className="text-center">
                  {camboData ? camboData[0]?.courseId : ""} ({camboData ? trim(camboData[0]?.shortName) : ""})
                </b>
              </h4>
            </div>
          </div>

          <div className="modal-body">
            <div
              className="homepage-bluebg popup-stepper"
              style={{
                background: "rgb(250 174 28)",
                border: "1px solid rgb(250 174 28)",
              }}
            ></div>
            <div className={isCutomStyle ? "popup-scroll-section popup-questions-whole-section-scroll":"modal-contant-auto popup-questions-whole-section-scroll"}>
            <div className="landing-page-popup-content">
            {ReactHtmlParser(camboData[0]?.content)}
            </div>
              {/* <div class="landing-page-popup-content">
                <div class="landing-page-popup-section">
                  <div class="landing-page-popup-heading">
                    Course Description:
                  </div>
                  <div class="landing-page-popup-para">
                    {metaData ? metaData[0]?.description : ""}
                  </div>
                </div>
                {comboCourseData?.map((cambo, i) => {
                  totalModule = 0;
                  return (
                    <div class="landing-page-popup-section" key={i}>
                      <div class="landing-page-popup-heading">
                        {cambo?.courseId
                          .replaceAll("_", " ")
                          .replaceAll("_&_", " & ")}
                      </div>
                      {cambo &&
                        cambo?.sections.map((data, j) => {
                          totalModule =
                            totalModule + Number(data?.totalModules);
                         
                          return (
                            <div class="landing-page-popup-para">
                              - Section {++j}: {data?.name} (
                              {data?.totalModules} modules)
                            </div>
                          );
                        })}
                    
                      <div class="landing-page-popup-section">
                        <div class="landing-page-popup-para"></div>
                        <div class="landing-page-popup-para"></div>
                      </div>
                      <div class="landing-page-popup-section">
                        <div class="landing-page-popup-para">
                          - {+totalModule} Modules Total
                        </div>
                        <div class="landing-page-popup-para">
                          - 10 Practice Exams: 20 Questions Each (200 Questions
                          Total)
                        </div>
                      </div>
                    </div>
                  );
                })}

                <div class="landing-page-popup-section">
                  <div class="landing-page-popup-heading">
                    Price: ${metaData && metaData[0]?.price} Total ( $
                    {metaData && metaData[0]?.perCoursePrice} per course )
                  </div>
                </div>

                <div class="landing-page-popup-section">
                  <div class="landing-page-popup-para">
                   
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div className="modal-footer">
            <div className="landing-page-button-section">
              <div className="landing-page-popup-left">
                <a
                  href="#"
                  className="popup-blue-btn"
                  onClick={
                    courseExist(camboData[0]?.courseId)
                      ? null
                      : navigateToDashboard
                  }
                  style={{
                    background: "rgb(250 174 28)",
                    border: "1px solid rgb(250 174 28)",
                  }}
                >
                  {courseExist(camboData[0]?.courseId)
                    ? "Purchased"
                    : "Free Trial"}
                </a>
                <a
                  href="#"
                  className="popup-gray-btn"
                  style={
                    addToCartActive
                      ? { background: `rgb(250 174 28)`, color: "#ffffff" }
                      : {}
                  }
                  onClick={() => {
                    addToCart(eachObject);
                    setAddToCartActive(true);
                    setUserError(user?.uid ? "" : "You must Login First");
                  }}
                >
                  Add to Cart
                </a>

                {successText && (
                  <span className="text-success warning-text" style={{ margin: "auto" }}>
                    {successText}{" "}
                  </span>
                )}
                {errorText && (
                  <span className="text-danger warning-text" style={{ margin: "auto" }}>
                    {errorText}
                  </span>
                )}
                {/* {userError &&
                      <span className="text-danger">{userError}</span>
                    } */}
              </div>
              <div className="landing-page-popup-right">
                <a
                  href="#"
                  className="popup-gray-outline-btn"
                  onClick={() => navigate()}
                >
                  See All Pricing
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default ComboCourseDetails;
