import React from 'react';
import {Link} from 'react-router-dom';
import { useHistory } from "react-router-dom";

const BannerSection = () => {

  const history=useHistory();

  const test = () => {
    // console.log('called');
    // history.push("/login");
  };

    return (
        <div className="home-page-section-1">
        <div className="landing-page-banner-logo">
          <img className="img-fluid" src="../assets/images/landingpagebanner_logo.png"/>
        </div>
        <div data-aos="fade-up" className="home-page-section-1-content">
      
      <div className="home-page-section-1-text">
        A modular approach for the most <span>efficient</span> and <span>convenient</span> ARE study guide on the web:
      </div>
      <div className="home-page-section-1-button">
        <Link className="start-here-btn" to="/signUp">Start Here</Link>
      </div>
      <div className="already-member-link">
        <Link className="start-here-btn" to="/login">Already a Member?</Link>
      </div>
      </div>
      </div>
    );
}

export default BannerSection;
