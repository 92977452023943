import React from "react";

const CourseModuleCard = ({
  openModal,
  dataObject,
  index,
  sectionNo,
  moduleNo,
  trialStatus,
  darkRgbValue,
  totalModules,
}) => {
  // console.log('courseModule',key)
  const progressValue = (val) => {
    switch (val) {
      case 0:
        return [];
      case 1:
        return [1];
      case 2:
        return [1, 2];
      case 3:
        return [1, 2, 3];
      default:
        return [];
    }
  };
  // console.log("mod",totalModules);
  const confidenceLevelProgress = progressValue(dataObject?.confidenceLevel);
  return (
    <div className="col-md-3 col-lg-3 col-xl-3 col-sm-3 col-xs-12" key={index}>
      <div
        onClick={() =>
          openModal({
            ...dataObject,
            sectionNo,
            moduleNo,
            totalModules,
            trialStatus,
          })
        }
        className="accordion-inner-box"
        style={{ border: `1.5pt solid rgb(${darkRgbValue})` }}
      >
        <div className="accordion-inner-heading">
          <div className="accordion-left-number">
            {sectionNo}.{moduleNo}
          </div>
          <div className="accordion-right-heading">{dataObject?.moduleName}</div>
        </div>
        <div
          className="accordion-inner-stepper"
          style={{ borderTop: `1pt solid rgb(${darkRgbValue})` }}
        >
          {confidenceLevelProgress?.map((each, i) => (
            <div
              className={`accordion-inner-step accordion-inner-step${i + 1}`}
              key={i}
              style={{
                background: `rgb(${darkRgbValue})`,
                borderRight: `1pt solid rgb(${darkRgbValue})`,
              }}
            ></div>
          ))}

          {/* <div
              className="accordion-inner-step accordion-inner-step2"
            ></div> */}
          {/* <div
              className="accordion-inner-step accordion-inner-step3"
            ></div> */}
        </div>
      </div>
    </div>
  );
};

export default CourseModuleCard;
