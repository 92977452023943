import React, { useEffect, useState } from "react";
import { getAuth, updateProfile } from "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { toast } from "react-toastify";
import { updateUserData, updateUserProfile } from "../../reducers/authReducer";

const ProfileDetails = () => {
  const [profileUrl, setProfileUrl] = useState(null);
  const auth = getAuth();
  const [fname, setfname] = useState("");
  const [lname, setlname] = useState("");
  const [companyName, setcompanyName] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  const [fnameError, setfnameError] = useState("");
  const [lnameError, setlnameError] = useState("");
  const [companyNameError, setcompanyNameError] = useState("");
  const [phoneNumberError, setphoneNumberError] = useState("");
  const [saveSuccess,setSaveSuccess]=useState(false)
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.authReducer?.userData);
  //   console.log(user);
  // console.log(user);

  useEffect(() => {
    if (user !== null) {
      setProfileUrl(user?.photoURL);
      setphoneNumber(user?.providerData[0]?.phoneNumber);
      const fullname = user?.displayName?.split(" ");
      setfname(fullname && fullname[0]);
      setlname(fullname && fullname[1]);
    }
  }, [user]);

  const uploadProfileImage = (e) => {
    // console.log("e.target>>>>", e.target.files[0]);
    if (e.target.files) {
      // console.log("e.target", e.target.files[0].name);
      const storage = getStorage();
      const file = e.target.files[0];
      // Create the file metadata
      const metadata = {
        contentType: file?.type,
      };

      // Upload file and metadata to the object 'images/mountains.jpg'
      const storageRef = ref(storage, "images/" + file?.name);
      const uploadTask = uploadBytesResumable(storageRef, file, metadata);

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          // console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              // console.log("Upload is paused");
              break;
            case "running":
              // console.log("Upload is running");
              break;
          }
        },
        (error) => {
          switch (error.code) {
            case "storage/unauthorized":
              break;
            case "storage/canceled":
              break;
            case "storage/unknown":
              break;
          }
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            // console.log("File available at", downloadURL);
            setProfileUrl(downloadURL);
          });
        }
      );
    }
  };

  const validation = () => {
    let valid = false;
    let istrue=true;
    if (fname.trim()) {
      valid = true;
      setfnameError("");
    } else {
      valid = false;
      istrue=false;
      setfnameError("Please enter the FirstName");
    }
    if (lname.trim()) {
      valid = true;
      setlnameError("");
    } else {
      valid = false;istrue=false;
      setlnameError("Please enter the LastName");
    }

    if(istrue)
    {
      return true;
    }
    else{return false}
    // if (companyName.trim()) {
    //   valid = true;
    //   setcompanyNameError("");
    // } else {
    //   valid = false;
    //   setcompanyNameError("Please enter the CompanyName");
    // }

    // if (phoneNumber) {
    //   if (phoneNumber.length == 10) {
    //     valid = true;
    //     setphoneNumberError("");
    //   } else {
    //     valid = false;
    //     setphoneNumberError("Phone must be at least 10 Numbers");
    //   }
    // } else {
    //   setphoneNumberError("Please enter the Phonenumber");
    //   valid = false;
    // }
    // return valid;
  };

  const sumbitSignUp = (e) => {
    e.preventDefault();
    if (validation()) {
      updateUserProfile(auth.currentUser, fname, lname, phoneNumber, profileUrl)
        .then(() => {
          //   console.log("success");
          //   console.log(auth.currentUser);
            dispatch(updateUserData(auth.currentUser))
          // setfname("");
          // setlname("");
          // setcompanyName("");
          // setphoneNumber("");
          setSaveSuccess(true);
          setTimeout(()=>{
            setSaveSuccess(false)
          },1000)
          toast.success("Profile Details Updated");
        })
        .catch((error) => {
          //   console.log(error);
          toast.error(error?.code);
        });
    }
  };

  return (
    <div className="profile-details">
      <div className="profile-details-sec1">
        <p>
          Profile Details{" "}
         {saveSuccess && <span>
            Saved <i className="fas fa-check"></i>
          </span>}
        </p>
      </div>
      <div className="profile-details-main">
        <div className="profile-img-sec">
          {profileUrl ? <img
            src={profileUrl}
          /> : <div className="circle_img"></div> }
          <p>Profile Picture</p>
          {/* <button type="button" className="btn white-btn" onChange={(e)=>uploadProfileImage(e)}>Choose File</button> */}
          <input
            type="file"
            id="profileUploadImage"
            // className="btn white-btn"
            onChange={(e) => uploadProfileImage(e)}
            style={{display:'none'}}
          />
          <label className="btn white-btn" htmlFor="profileUploadImage">Choose File</label>
        </div>
        <form className="profile-detail-form">
          <div className="form-inline justify-content-between">
            <div className="custom-form-group">
              <label htmlFor="fname">First Name</label>
              <input
                type="text"
                id="fname"
                name="firstName"
                className="form-control"
                value={fname}
                onChange={(e) => setfname(e.target.value)}
              />
              {fnameError && <span className="error-text text-danger">{fnameError}</span>}
            </div>
            <div className="custom-form-group">
              <label htmlFor="lname">Last Name</label>
              <input
                type="text"
                id="lname"
                name="lastName"
                value={lname}
                className="form-control"
                onChange={(e) => setlname(e.target.value)}
              />
              {lnameError && <span className="error-text text-danger">{lnameError}</span>}
            </div>
          </div>
          {/* <div className="w-100 custom-form-group">
            <label for="cname">Company Name</label>
            <input
              type="text"
              id="cname"
              name="companyName"
              className="form-control"
              value={companyName}
              onChange={(e) => setcompanyName(e.target.value)}
              disabled={true}
            />
            {companyNameError && (
              <span className="error-text">{companyNameError}</span>
            )}
          </div>
          <div className="w-100 custom-form-group">
            <label for="no">Phone Number</label>
            <input
              type="number"
              id="no"
              name="phoneNumber"
              value={phoneNumber}
              className="form-control"
              onChange={(e) => setphoneNumber(e.target.value)}
              disabled={true}
            />
            {phoneNumberError && (
              <span className="error-text">{phoneNumberError}</span>
            )}
          </div> */}
        </form>
      </div>
      <div className="w-100 d-flex justify-content-end profile-save-changes-btn">
        <button
          type="button"
          className="btn yellow-btn-sm"
          onClick={(e) => sumbitSignUp(e)}
        >
          Save Changes
        </button>
      </div>
    </div>
  );
};

export default ProfileDetails;
