import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addToCartItem, getCourseDetails } from "../../reducers/coursesReducer";
import CourseDetails from "../Landing/CourseDetails";

const IndividualCourseCard = ({
  eachObject,
  typeOfRender,
  learnMoreOpen,
  index,
  mobileTap = function temp() {},
}) => {
  const rgbValue =
    eachObject?.rgbValues?.rValue +
    "," +
    eachObject?.rgbValues?.gValue +
    "," +
    eachObject?.rgbValues?.bValue;
  const darkRgbValue =
    eachObject?.darkRgbValues?.rValue +
    "," +
    eachObject?.darkRgbValues?.gValue +
    "," +
    eachObject?.darkRgbValues?.bValue;

  const [modalShow, setModalShow] = useState(false);
  const courseDetail = useSelector((state) => state?.course?.courseDetails);
  const user = useSelector((state) => state?.authReducer?.userData);
  const [successText, setSuccessText] = useState("");
  const [errorText, setErrorText] = useState("");
  const cartItems = useSelector((state) => state?.course?.cartListData);
  const userCourseList = useSelector((state) => state?.course?.userCoursesList);
  // const [learnMoreActive, setLearnMoreActive] = useState(false)

  const dispatch = useDispatch();

  const openCourseDetails = (courseId) => {
    dispatch(getCourseDetails(courseId)).then(() => {
      // console.log('---coursedetails---',courseDetail)
      setModalShow(true);
      setErrorText("");
      setSuccessText("");
    });
  };

  const courseExist = (courseId) => {
    return userCourseList?.some(function (course) {
      return course?.courseId === courseId;
    });
  };

  const addToCartMethod = (data) => {
    const isExist = courseExist(data?.id);
    // console.log('courseExist==>',isExist)
    setErrorText("");
    setSuccessText("");
    if (user?.uid) {
      if (!isExist) {
        let existCheck = 2;
        // console.log("cardItem>>>",addItems,data);
        const arr = [];
        let cartArr;
        data.uid = user.uid;
        arr.push(data);
        if (cartItems) {
          const itemCheck =
            cartItems && cartItems?.map((each, i) => each.id !== data.id);
          // console.log("data",itemCheck,itemCheck.includes(false));
          if (itemCheck.includes(false)) {
            // toast.error("Aleady you have added this course to cart ");
            setErrorText("Already added to cart");
            cartArr = [...cartItems];
            existCheck = 4;
          } else {
            // toast.success("Successfully added this course to cart ");
            setSuccessText("Added to cart");
            cartArr = [...cartItems, ...arr];
          }
        } else {
          cartArr = arr;
          // toast.success("Successfully added this course to cart ");
          setSuccessText("Added to cart");
        }
        if (existCheck === 2) {
          dispatch(addToCartItem(arr));
        }
      } else {
        setErrorText("Already purchased");
      }
    } else {
      setErrorText("You must login");
    }
    // console.log('-------array data',arr)
  };

  const test = () => {};

  // console.log('----eachobject----',eachObject.id)
  return (
    <>
      {modalShow && (
        <CourseDetails
          closeModal={() => {
            setModalShow(false);
          }}
          courseDetail={courseDetail}
          addToCart={addToCartMethod}
          eachObject={eachObject}
          successText={successText}
          errorText={errorText}
        />
      )}
      <div
        className={`${
          typeOfRender !== "shopping"
            ? "col-md-4 col-lg-4 col-xl-4 col-sm-4 col-xs-12 "
            : "col-md-12 col-lg-12 col-xl-12 col-sm-12 col-xs-12"
        }`}
        onClick={() => (learnMoreOpen ? test() : mobileTap(index))}
        onTouchStart={() => (learnMoreOpen ? test() : mobileTap(index))}
      >
        <div
          className="enrollment-phase-one-item card-bg1"
          style={{
            background: `rgb(${rgbValue})`,
            borderLeft: `15pt solid rgb(${darkRgbValue})`,
          }}
        >
          <div className="enrollment-phase-item-left">
            <h4>{eachObject?.name}</h4>
            <p>{eachObject?.nickname}</p>
            {typeOfRender !== "shopping" ? (
              <div className="enrollment-phase-price">${eachObject?.price}</div>
            ) : (
              <div className="enrollment-phase-price"></div>
            )}
          </div>
          <div className="enrollment-phase-item-right">
            <img className="img-fluid" src={eachObject?.image} />
          </div>
          {typeOfRender !== "shopping" && (
            <div
              className={
                learnMoreOpen
                  ? "res-hover-btn-area hover-btn-area"
                  : "hover-btn-area"
              }
            >
              <button
                data-toggle="modal"
                // data-target="#myModal" className="learn-more-btn" onClick={()=>addCart(eachObject)}>Learn More</button>
                data-target="#myModal"
                className="learn-more-btn"
                onClick={() => {
                  openCourseDetails(eachObject.id);
                }}
              >
                Learn More
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default IndividualCourseCard;
