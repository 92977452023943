import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import {sendContactMail} from '../../reducers/authReducer'
import { toast } from 'react-toastify';

const ContactUs = () => {

  
  useEffect(() => {
    window.scroll(0,0);
    setEmail(user?.email)
   }, [])

  const [email, setEmail] = useState('')
  const [headContant, setheadContant] = useState('')
  const [bodyContant, setbodyContant] = useState('')
  const [headContantError, setheadContantError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [bodyContantError, setbodyContantError] = useState('')
  const user = useSelector((state) => state?.authReducer?.userData);
  const sendEmail = (e) => {
    // e.preventDefault();
    if (validation()) {
     
      //  console.log("emaill==>",email)
        sendContactMail(headContant,bodyContant,email)
          .then(() => {
            // console.log('success')
            setheadContant('');
            setbodyContant('');
            user?.email ? setEmail(user?.email) : setEmail('');
            toast.success('Mail Sent')
          })
          .catch((error) => {});
    }
  };

  const validation = () => {
    let valid = false;
    let isTrue=true;
    if (email) {
      const regex =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      if (regex.test(email)) {
        valid = true;
        setEmailError("");
      } else {
        valid = false;
        isTrue=false;
        setEmailError("Please enter valid email");
        // return valid;
      }
    } else {
  valid = false;
  isTrue=false;
  setEmailError("Please enter the E-mail");
  // return valid;
  }

    if (headContant) {
          valid = true;
          setheadContantError("");
    } else {
      valid = false;
      setheadContantError("Please enter the Head Details");
      // return valid;
      isTrue=false;
    }

    if (bodyContant) {
      valid = true;
      setbodyContantError("");
    } else {
      valid= false;isTrue=false;
      setbodyContantError("Please enter the Body Details");
      // return valid;
    }
    if(isTrue){return true;}
    else{
      return false;
    }
    
  };

    return (
        <>
            <Header />
            <section>
      <div className="common-spacing containerWidth">
        <div className="common-heading-section">
          <h2 className="common-title-dark common-title">Contact Us</h2>
        </div>
        <div className="contact-us-title">
          <p>
            Please reach out to us if you have any questions, comments, or
            concerns. You can either use the messaging window below or email us directly
            at
            <a href="mailto:info@arelunchbox.com"> info@arelunchbox.com</a>
          </p>
        </div>
        <form>
        <div className="form-group">
            <input
              type="text"
              className="custom-form-control form-control"
              id="Email"
              placeholder="E-mail"
              value={user?.email ? user?.email : email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={user?.email ? true : false}
            />
             {emailError && (
              <span className="error-text text-danger">{emailError}</span>
            )}
          </div>
          <div className="form-group">
            <input
              type="text"
              className="custom-form-control form-control"
              id="heading"
              placeholder="Heading"
              value={headContant}
              onChange={(e) => setheadContant(e.target.value)}
            />
             {headContantError && (
              <span className="error-text text-danger">{headContantError}</span>
            )}
          </div>
          <div className="form-group">
            <textarea
              rows="7"
              cols="10"
              className="custom-form-control form-control"
              placeholder="Body"
              value={bodyContant}
              onChange={(e) => setbodyContant(e.target.value)}
            ></textarea>
            {bodyContantError && (
              <span className="error-text text-danger">{bodyContantError}</span>
            )}
          </div>
          <button type="button" className="btn yellow-btn" onClick={(e) => sendEmail(e)} >Send</button>
        </form>
      </div>
    </section>
            <Footer />
        </>
    );
}

export default ContactUs;
