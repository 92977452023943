import React from 'react';

const Google = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M43.611 20.083H42V20H24V28H35.303C33.654 32.657 29.223 36 24 36C17.373 36 12 30.627 12 24C12 17.373 17.373 12 24 12C27.059 12 29.842 13.154 31.961 15.039L37.618 9.382C34.046 6.053 29.268 4 24 4C12.955 4 4 12.955 4 24C4 35.045 12.955 44 24 44C35.045 44 44 35.045 44 24C44 22.659 43.862 21.35 43.611 20.083Z" fill="#FFC107"/>
          <path d="M6.30664 14.691L12.8776 19.51C14.6556 15.108 18.9616 12 24.0006 12C27.0596 12 29.8426 13.154 31.9616 15.039L37.6186 9.382C34.0466 6.053 29.2686 4 24.0006 4C16.3186 4 9.65664 8.337 6.30664 14.691Z" fill="#FF3D00"/>
          <path d="M24.0003 44.0002C29.1663 44.0002 33.8603 42.0232 37.4093 38.8082L31.2193 33.5702C29.1439 35.1486 26.6078 36.0023 24.0003 36.0002C18.7983 36.0002 14.3813 32.6832 12.7173 28.0542L6.19531 33.0792C9.50531 39.5562 16.2273 44.0002 24.0003 44.0002Z" fill="#4CAF50"/>
          <path d="M43.611 20.083H42V20H24V28H35.303C34.5142 30.2164 33.0934 32.1532 31.216 33.571L31.219 33.569L37.409 38.807C36.971 39.205 44 34 44 24C44 22.659 43.862 21.35 43.611 20.083Z" fill="#1976D2"/>
          </svg>
    );
}

export default Google;
