import React from 'react';

const RegisterSuccessModal = ({closeModal}) => {
    return (
        <div className="backDrop">
        <div class=" modal fade show d-block">
      <div class="modal-dialog modal-lg" role="document" style={{top:'calc(100% / 2 - 130px)'}}>
        <div class="modal-content">
        <div className="modal-header justifyContentCenter">
                <h4 className="modal-title"> Complete Account Setup </h4>
            </div>
      <p className="registerParagraph">
We’ve sent a confirmation email to your inbox. Please verify your email address in order to complete your account
setup and gain access to your personalized dashboard. Please wait a couple of minutes if you do not receive an email
immediately and make sure to check your junk mail.</p>
<p className="registerParagraph">Congrats! You’re now on your way to licensure!</p>
          <button type="button" onClick={closeModal} className="successBtn">Okay</button>
        </div>
      </div>
    </div>
    </div>
        
    );
}

export default RegisterSuccessModal;
