import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CourseCardList from '../../components/Course/CourseCardList';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { getAllCourses, getUserCourses, removeUserCourse, resetData, storeCourseId } from '../../reducers/coursesReducer'

const Dashboard = () => {
  const user = useSelector((state) => state?.authReducer?.userData);
  const [userInfo, setUserInfo] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    // console.log("user>>>>",user?.email);
    setUserInfo(user)
  }, [user]);

  useEffect(() => {
    window.scroll(0,0);
    // dispatch(removeUserCourse()).then(()=>{});
    dispatch(getAllCourses());
    dispatch(getUserCourses({ uid: user?.uid }))
    dispatch(storeCourseId(null))
    dispatch(resetData())
    // console.log("data>>>>course>>>>useEffect[]");
  }, []);
 

  return (
    <>
      <Header />
      <section>
        <div className="common-spacing containerWidth">
          <div className="text-center courseenrollment-whole-section welcome-dashboard-spacing">
            {user?.photoURL ? <img
              src={user?.photoURL}
              alt="profile"
              className="profile-img"
            /> 
            : <><div className="circle_img"></div></>}
            <p className="text-center bold-sub-heading">
              Welcome back, <br />
              {user?.displayName}
            </p>

            <div className="container-fluid">
              <div className="courseenrollment-line"></div>
              <h5 className="course-title">Courses:</h5>
              <div className="enrollment-phase-one">

                <CourseCardList userInfo={userInfo} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Dashboard;
