import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import CourseCardItem from "./CourseCardItem";
import { useHistory } from "react-router";
import {
  updateCoursePercentage,
  getUserCourses,
  storeCourseId,
  getAllSections,
  getUserBasedSection,
  updateUserModules,
  resetData,
  removeUserModules,
  removeUserSection,
  removeUserCourse,
} from "../../reducers/coursesReducer";
import { toast } from "react-toastify";
import ViewAccessPeriod from "./ViewAccessPeriod";
// import { rest } from 'lodash';
import moment from "moment";
import CourseResetConfirmation from "./CourseResetConfirmation";

const CourseCardList = ({ userInfo }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [list, setList] = useState(null);
  const [resetCourseObj, setResetCourseObj] = useState(null);
  const [resetConfirmShow, setResetConfirmShow] = useState(false);
  const courseList = useSelector((state) => state?.course?.coursesList);
  const userCourseList = useSelector((state) => state?.course?.userCoursesList);
  const user = useSelector((state) => state?.authReducer?.userData);
  
  const [selectedObject, setSelectedObject] = useState(null);
  const [viewModal, setViewModal] = useState(false);

  

  // console.log('userCourseList==>',userCourseList)

  const handleTimeDifference = (startDate, endDate) => {
    var msDiff = new Date(endDate).getTime() - new Date(startDate).getTime(); //Future date - current date
    var totalDays = Math.floor(msDiff / (1000 * 60 * 60 * 24));
    // console.log(totalDays)
    return totalDays;
  };
  
  const removeCourse=async()=>{
    // let userSectionForDelete=0;
    await userCourseList?.map(async(data,i)=>{
    const endDate =  moment.unix(data?.endDate?.seconds).format("MM/DD/YYYY");
    const currentDate = moment(new Date()).format("MM/DD/YYYY");
    const daysValue = moment.duration(new Date(endDate).getTime() - new Date(currentDate).getTime()).days();
    const dayCheck=handleTimeDifference(currentDate,endDate)
    // console.log(daysValue,"daysValue");
    if(endDate && currentDate){
      if(0 > dayCheck){
      let removeUserSectionList;
          await dispatch(
            getUserBasedSection({ courseId: data.courseId, uid: data.uid, type:"perCalculation" })
          ).then(async(res) => {
            removeUserSectionList = res;
            // console.log(removeUserSectionList,"removeUserSectionList")
            await res?.map(async(eachSection,i)=>{
                await eachSection?.modulesList?.map(async(module,i)=>{
                  dispatch(removeUserModules(module?.documentId))
                  // console.log("module",module)
                  // console.log("moduleId",module?.moduleId,"documentId",module?.documentId)
                })
                // console.log(eachSection?.documentId)
                dispatch(removeUserSection(eachSection?.documentId))
            })
            dispatch(removeUserCourse({docId:data?.documentId,currentObject:data}));
            dispatch(getUserCourses({ uid: user?.uid }));
          });     
    }
  }
  });
  }

  const updatePercentageVal = async (eachObject, value) => {
    // // console.log('------',typeof(value));
    const type = value == 100 ? "Mark_as_Passed" : "Reset_Progress";

    // // console.log('-------usersectionlist-----',userSectionList)

    dispatch(
      getUserBasedSection({
        courseId: eachObject.courseId,
        uid: user?.uid,
        type:"perCalculation"
      })
    ).then((res) => {
      res &&
        res?.map((each, i) => {
          each.modulesList.map((item, index) => {
            dispatch(updateUserModules(type, item.documentId)).then((res) => {
              dispatch(getUserCourses({ uid: userInfo?.uid }));
            });
          });
        });
    });

    dispatch(updateCoursePercentage({ id: eachObject.documentId, value }))
      .then((res) => {
        dispatch(getUserCourses({ uid: userInfo?.uid }));
        toast.success(
          value === 0
            ? "Reset Progress successfully"
            : "Mark as passed successfully"
        );
      })
      .catch((err) => {
        toast.error(err?.code);
      });
  };
  // // console.log("user section",userSectionList)


  const percentageCalculation = (list) => {
    list &&
      list.map(async (data, index) => {
        
        if (data.uid) {
          let sectionList;
          await dispatch(getAllSections(data.id,"perCalculation")).then((res) => {
            sectionList = res;
          });

          const moduleArr =
            sectionList &&
            sectionList?.map((each, i) => {
              return each.modulesList.length;
            });

          const moduleLenght = moduleArr.length
            ? moduleArr?.reduce((a, b) => a + b)
            : 0;
            
           
                  
          let userSectionList;
          await dispatch(
            getUserBasedSection({ courseId: data.id, uid: data.uid, type:"perCalculation" })
          ).then((res) => {
            userSectionList = res;
          });

         //changed formate DD-MM-YYYY

          // console.log("endDate",endDate,"currentDate",currentDate)
          const userModuleArr =
            userSectionList &&
            userSectionList?.map((listData, i) => {
              // // console.log('listData===>',listData.modulesList)
              const confidentValue = listData?.modulesList?.map(
                (each, j) => each?.confidenceLevel
              );
              return confidentValue;
            });

          const userModuleLength =
            userModuleArr?.flat().length > 0
              ? userModuleArr?.flat()?.reduce((a, b) => a + b)
              : 0;

          //    // console.log('----userModuleLength-----',userModuleLength,'-----moduleLenght---------',moduleArr)
          const percentage =
            moduleLenght > 0
              ? (userModuleLength / (moduleLenght * 3)) * 100
              : 0;

          //    // console.log('-----peercentage----',percentage,'---name---',data.name)
          dispatch(
            updateCoursePercentage({
              id: data?.documentId,
              value: percentage?.toFixed(0),
            })
          );
        }
        // console.log("userSectionForDelete==>",userSectionForDelete)
      });
  };

  
  useEffect(() => {
    userCourseList &&
      userCourseList?.map((each, i) => {
        dispatch(getAllSections(each.courseId,"perCalculation"));
        dispatch(getUserBasedSection({ courseId: each.courseId, uid: each.uid,type:"perCalculation" }));
      });

      // console.log("useeffect")
    const list =
      courseList &&
      courseList?.map((each, i) => {
        let object;
        let userPaid = false;
        userCourseList?.map((data, i) => {
          // // console.log("data?.courseId>>>>>>",data?.courseId,each.id,data?.courseId === each.id ,data);
          if (data?.courseId === each.id) {
            if (data?.endDate) {
              if (
                moment(new Date()).isSameOrBefore(
                  moment.unix(data?.endDate?.seconds)
                )
              ) {
                object = {
                  ...each,
                  ...data,
                };
                userPaid = true;
              } else {
                userPaid = false;
              }
            } else {
              object = {
                ...each,
                ...data,
              };
              userPaid = true;
            }
          }
        });
        return userPaid ? { ...object, userPaid } : { ...each, userPaid };
      });
    // console.log("list>>>>>",list)
    //// console.log('-----useEffectupdate-----')
    setList(list);
    percentageCalculation(list);
    removeCourse();
  }, [courseList,userCourseList]);



  // useEffect(() => {
  //  dispatch(resetData())
  // }, [])

  const viewPeriodObjectGet = (data) => {
    // // console.log("----------view Port data---------",data)
    setSelectedObject(data);
    setViewModal(true);
  };
  const routeToSectionsPage = (id) => {
    // console.log("===>id",id)
     dispatch(storeCourseId(id))
      history.push("/courseSection");
  };
  const updateResetPercentage = () => {
    setResetConfirmShow(false);
    updatePercentageVal(resetCourseObj, 0);
  };

  const resetProgressMethod = (data) => {
    setResetConfirmShow(true);
    setResetCourseObj(data);
  };
  return (
    <div className="row">
      {viewModal && (
        <ViewAccessPeriod
          closeModal={() => setViewModal(false)}
          eachObject={selectedObject}
        />
      )}
      {resetConfirmShow && (
        <CourseResetConfirmation
          closeModal={() => setResetConfirmShow(false)}
          updateProgress={updateResetPercentage}
        />
      )}
      {list ? (
        list.map((each, i) => (
          <CourseCardItem
            eachObject={each}
            updatePercentage={updatePercentageVal}
            resetProgress={resetProgressMethod}
            viewPeriod={(data) => viewPeriodObjectGet(data)}
            redirectPage={routeToSectionsPage}
            key={i}
          />
        ))
      ) : (
        <p> No Courses Available</p>
      )}
    </div>
  );
};

export default CourseCardList;
