import React, { useEffect } from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { useHistory } from 'react-router-dom';

const AboutUs = () => {
  
  useEffect(() => {
    window.scroll(0,0);
   }, [])
  const history=useHistory();
    return (
        <>
        <Header />
        <section>
      <div className="common-spacing containerWidth">
        <div className="common-heading-section">
          <h2 className="common-title">About ARE Lunchbox</h2>
        </div>
        <div className="common-whole-content-section">
          <div className="common-width-space common-content-section">
            <h3 className="common-bold-title">
              ARE Lunchbox's mission is to provide an easier and more efficient
              way to tackle the ARE exam content.
            </h3>
            <p>
            We designers know the struggle. We invest money and years to learn about the magical craft that is architecture. We graduate with our respective degrees and confidently look forward to providing our professional contributions, until we are hit with the one acronym that may send us into a flurry of panic: the <b>ARE's</b>.
            </p>
            <p>
            The Architect Registration Examinations (ARE's) are overwhelming enough to induce fear in even the most skilled and experienced of aspiring architects. Although necessary to establish oneself as a master of the architectural discipline, this professional rite of passage can be quite an intimidating endeavor. Aspiring architects must brace themselves for a total of six challenging licensing exams. Fortunately, we are here to help.
            </p>

          </div>
          <div className="common-border-line"></div>
          <div className="common-width-space common-content-section">
            <h3 className="common-bold-title">
              Our objective is to provide you with a solid framework that clearly structures the required knowledge you need for each exam.

            </h3>
            <p>
              The dense study guides and ample, unorganized resources on the web could make it feel like you are sifting through a large pile of rubble. Our approach to studying the ARE's is to break down the necessary materials into smaller, more digestible components, then organizing those components into a coherent and intuitive learning structure. Similar to a lunchbox that you bring to school or work everyday (hence the name), we set out to be one of the most accessible ARE study guides that you can incrementally and efficiently snack on at your convenience. No more having to block out significant chunks of time to drudge through an extensive 400+ page study guide that is as effective as drafting by hand is efficient.
            </p>

          </div>
          <div className="common-border-line"></div>
          <div className="common-width-space common-content-section">
            <h3 className="common-bold-title">
              Your study journey here on ARE Lunchbox is personalized to work for YOU and your approach to studying.
            </h3>
            <p>
              Through the modular structure of our ARE Lunchbox courses, you are in greater control of the pacing and efficiency of your studying! We know how much material there is to cover, so we do the organizing in order to help you stay on top of it all. Be it one module a day or an entire section of modules in one sitting, you will always be able to keep track of your own progress.
            </p>
            <p>
              We have also incorporated the use of a Confidence Rating System. Think of it like a videogame: each module (or level) completed gives you more experience points in order to level up, but you can always go back and replay the same levels again if you feel you didn’t absorb everything the first time around! Only in this context, you are the one who determines your confidence in any module. This will assist you in efficiently visualizing what material you feel strong in, and what material you will need more time studying.
            </p>
            <p>
              To really put your knowledge to the test, ARE Lunchbox offers quizzes at the end of each section. Don’t be afraid, NCARB isn’t keeping track of these scores. Retake the quizzes as many times as you need, and don’t be afraid to fail!

            </p>


          </div>
          <div className="common-border-line"></div>
          <div className="common-width-space common-content-section">
            <h3 className="common-bold-title">
              ARE Lunchbox strives to create better architects in a fun and enjoyable manner!

            </h3>
            <p>
              We are here to help you in every way we can along the journey. Please feel free to reach out and ask us any questions related to the ARE's or the study material. We are continually adding more content and looking to improve ARE Lunchbox to be the best it can possibly be. Our intentions are to help alleviate the stress associated with taking the ARE’s by creating the study guide that WE would have wanted when we were studying - so help us help you in your journey to be the best architect you can possibly be!

            </p>



          </div>
          <div className="common-border-line"></div>
          <div className=""><button type="button" className="btn yellow-btn" onClick={()=>history.push('/team')}>Meet the Team</button></div>
        </div>
      </div>
    </section>
        <Footer />
        </>
    );
}

export default AboutUs;
