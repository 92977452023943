import React from 'react';

const Info = () => {
    return (
      <svg  data-toggle="modal"
      data-target="#homeModal"
        width="30"
        height="30"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.7496 15.7498C21.043 15.749 21.3241 15.6321 21.5315 15.4247C21.7389 15.2172 21.8558 14.9361 21.8566 14.6428V13.5328C21.8566 12.9278 21.3596 12.4258 20.7496 12.4258C20.1396 12.4258 19.6406 12.9278 19.6406 13.5328V14.6428C19.6406 15.2518 20.1396 15.7498 20.7496 15.7498Z"
          fill="#fff"
        />
        <path
          d="M18.5367 20.1808H19.6417V26.4588C19.6417 27.0678 20.1407 27.5658 20.7507 27.5658C21.0441 27.565 21.3252 27.4481 21.5326 27.2407C21.7401 27.0333 21.8569 26.7522 21.8577 26.4588V19.0728C21.8569 18.7795 21.7401 18.4984 21.5326 18.2909C21.3252 18.0835 21.0441 17.9666 20.7507 17.9658H18.5367C17.9257 17.9658 17.4277 18.4648 17.4277 19.0728C17.4277 19.6828 17.9257 20.1808 18.5367 20.1808Z"
          fill="#fff"
        />
        <path
          d="M20 33.75C12.418 33.75 6.25 27.582 6.25 20.001C6.25 12.419 12.418 6.25 20 6.25C27.582 6.25 33.75 12.419 33.75 20.001C33.75 27.582 27.582 33.75 20 33.75ZM20 7.25C12.97 7.25 7.25 12.97 7.25 20.001C7.25 27.031 12.97 32.75 20 32.75C27.03 32.75 32.75 27.031 32.75 20.001C32.75 12.97 27.03 7.25 20 7.25Z"
          fill="#fff"
        />
      </svg>
   
    );
}

export default Info;
