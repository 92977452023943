import React from 'react';
import { useHistory } from 'react-router-dom';
const AboutSection = () => {
    const history = useHistory();
    return (
        <div className="home-page-section-2">
        <div  data-aos="fade-up" className="home-page-section-2-content">
      <div className="home-page-section-2-text">
        <h2 className="common-title">What is ARE LUNCHBOX?</h2>
        <p className="mt-40">ARE Lunchbox is a study guide with a mission: to provide aspiring architects with the necessary study tools to confidently tackle the ARE's® (Architect Registration Examinations®). Our approach breaks down the necessary study content into smaller, more digestible components, and then organizes those components into a coherent and intuitive learning structure. Optimized for desktop and mobile, we aim to provide users an online study resource that is simple, convenient, and easy-to-use.
        </p>
        <p className="mt-40">Our modular approach and user-friendly interface allows for a study experience that is easy to navigate and digest. No more having to spend time drudging through an extensive 400+ page study guide that is as effective as drafting by hand is efficient. You can easily keep track of all the necessary information you need - all at an affordable cost.
        </p>
      <p className="mb-40">Similar to a lunchbox that you bring to school or work everyday, we set out to be one of the most accessible ARE study guides that enables you to incrementally and efficiently snack on at your convenience.
      </p>  
          <div className="home-page-section-2-button">
            <button onClick={()=>history.push('/aboutUs')}>About Us</button>
          </div>
      </div>
      <div className="home-page-section-2-img">
      <img className="d-desktop" src="../assets/images/home2.png" />
      <img className="d-mobile" src="../assets/images/homemob4.png" />
      </div>
      </div>
      </div>

    );
}

export default AboutSection;
