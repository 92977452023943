import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";

const CourseDetails = ({
  closeModal,
  courseDetail,
  addToCart,
  eachObject,
  successText,
  errorText,
}) => {
  let totalModule = 0;
  const [modulesCount, setmodulesCount] = useState(0);
  const comboList = useSelector((state) => state?.course?.comboCoursesList);
  const user = useSelector((state) => state?.authReducer?.userData);
  const [userError, setUserError] = useState(errorText);
  const [userSuccess, setUserSuccess] = useState(successText);
  const [addToCartActive, setAddToCartActive] = useState(false);
  const userCourseList = useSelector((state) => state?.course?.userCoursesList);
  const history = useHistory();

  // const [darkRgbValue, setdarkRgbValue] = useState(darkRgbValues);

  // console.log("====>", courseDetail);
  const isCutomStyle=(courseDetail?.description && courseDetail?.sections.length > 0) ? false :true;

  const navigate = () => {
    closeModal();
    history.push("/enrollment");
  };
  const courseExist = (courseId) => {
    if(user?.uid){
          return userCourseList?.some(function (course) {
      return course?.courseId === courseId;
    });
  }else{
    return false
  }

  };


  const navigateToDashboard = () => {
    if (user?.uid) {
      history.push("/dashboard");
      setUserError("");
    } else {
      history.push("/login");
      // console.log(userError)
    }
  };
  const darkRgbValue =
    courseDetail[0]?.darkRgbValues?.rValue +
    "," +
    courseDetail[0]?.darkRgbValues?.gValue +
    "," +
    courseDetail[0]?.darkRgbValues?.bValue;
  // console.log('darkRgbValue==>from details==>',darkRgbValue)
  return (
    <div className="custom-popup-sm">
    <div className="custom-popup modal show d-block backDrop">
      <div className="modal-dialog">
        <div className={isCutomStyle ? "modal-contant-2 modal-content" :"modal-contant-auto modal-content"}>
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={() => {
                closeModal();
                setUserError("");
                setUserSuccess("");
              }}
            >
              <span>&times;</span>
            </button>
            <div className="popup-heading-arrow">
              <h4 className="modal-title">
                <b>{courseDetail[0]?.courseId?.replaceAll("_", " ")} </b>
              </h4>
            </div>
          </div>

          <div className="modal-body">
            <div
              className="homepage-bluebg popup-stepper"
              style={{
                background: `rgb(${darkRgbValue})`,
                border: `1px solid rgb(${darkRgbValue})`,
              }}
            ></div>
            <div className={isCutomStyle ? "popup-scroll-section popup-questions-whole-section-scroll":"modal-contant-auto popup-questions-whole-section-scroll"}>
              <div className="landing-page-popup-content">
              {ReactHtmlParser(courseDetail[0]?.content)}
              </div>
              {/* <div class="landing-page-popup-content">
                <div class="landing-page-popup-section">
                  <div class="landing-page-popup-heading">
                    Course Description:
                  </div>
                  <div class="landing-page-popup-para">
                    {courseDetail[0]?.description}
                  </div>
                </div>
                <div class="landing-page-popup-section">
                  <div class="landing-page-popup-heading">Course Content:</div>
                  {courseDetail[0] &&
                    courseDetail?.map((each, i) => {
                      return each.sections.map((data, j) => {
                        totalModule = totalModule + Number(data?.totalModules);
                        {
                          // console.log("----totalmodule--", data?.totalModules);
                        }
                        return (
                          <div class="landing-page-popup-para">
                            - Section {j++}: {data?.name} ({data?.totalModules}{" "}
                            modules)
                          </div>
                        );
                      });
                    })}

                </div>
                <div class="landing-page-popup-section">
                  <div class="landing-page-popup-para">
                    - {+totalModule} Modules Total
                  </div>
                  <div class="landing-page-popup-para">
                    - 10 Practice Exams: 20 Questions Each (200 Questions Total)
                  </div>
                </div>
                <div class="landing-page-popup-section">
                  <div class="landing-page-popup-heading">
                    Price: ${courseDetail[0]?.price} for 4 Months Access
                  </div>
                </div>
                <div class="landing-page-popup-section">
                  <div class="landing-page-popup-heading">
                    Bundle this Course with:
                  </div>

                  {comboList &&
                    comboList.map((each, i) => {
                      let temp;
                      return each.courses.map((data, j) => {
                        if (data == courseDetail[0]?.courseId) {
                          let course = JSON.stringify(each?.courses);
                          let courses = `${course
                            .replaceAll("_", " ")
                            .replaceAll("_&_", " & ")
                            .replaceAll("[", "")
                            .replaceAll("]", "")} in <b class="landing-page-popup-heading">${each?.comboName} </b> for ${
                            each?.perCoursePrice
                          } per Course`;

                          return (
                            <div class="landing-page-popup-section">
                              <div class="landing-page-popup-para">
                                {ReactHtmlParser(courses)}
                              </div>
                            </div>
                          );
                        }
                      });
                    })}
                </div>

                <div class="landing-page-popup-section">
                  <div class="landing-page-popup-para"></div>
                </div>
              </div> */}
            </div>
          </div>
          <div className="modal-footer">
            <div className="landing-page-button-section">
              <div className="landing-page-popup-left">
                <a
                  href="#"
                  className="popup-blue-btn"
                  onClick={
                    courseExist(courseDetail[0]?.courseId)
                      ? null
                      : navigateToDashboard
                  }
                  style={{
                    background: `rgb(${darkRgbValue})`,
                    border: `1px solid rgb(${darkRgbValue})`,
                  }}
                >
                  {courseExist(courseDetail[0]?.courseId)
                    ? "Purchased"
                    : "Free Trial"}
                </a>
                <a
                  href="#"
                  className="popup-gray-btn"
                  style={
                    addToCartActive
                      ? { background: `rgb(${darkRgbValue})`, color: "#ffffff" }
                      : {}
                  }
                  onClick={() => {
                    addToCart(eachObject);
                    setAddToCartActive(true);
                    setUserError(user?.uid ? "" : "You must Login First");
                  }}
                >
                  Add to Cart
                </a>
                {successText && (
                  <span className="text-success warning-text" style={{ margin: "auto" }}>
                    {successText}{" "}
                  </span>
                )}

                {errorText && (
                  <span className="text-danger warning-text" style={{ margin: "auto" }}>
                    {errorText}
                  </span>
                )}
                {/* {userError && (
                      <span className="text-danger">{userError}</span>
                    )} */}
              </div>
              <div className="landing-page-popup-right">
                <a
                  href="#"
                  className="popup-gray-outline-btn"
                  onClick={() => navigate()}
                >
                  See All Pricing
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default CourseDetails;
