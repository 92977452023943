
import './App.css';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import Login from './pages/Login'
import SignUp from './pages/SignUp';
import AboutUs from './pages/AboutUs';
import Dashboard from './pages/Dashboard';
import LandingPage from './pages/LandingPage';
import Enrollment from './pages/Enrollment';
import Courses from './pages/Courses';
import Team from './pages/Team';
import Profile from './pages/Profile';
import Cart from './pages/Cart';
import ForgotPassword from './pages/ForgotPassword';
import ContactUs from './pages/ContactUs';
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import PrivateRoute from './components/PrivateRouter';
import PaymentSuccess from './pages/PaymentSuccess';
import PaymentCancel from './pages/PaymentCancel';
import TermAndConditions from './pages/TermAndConditions';
import CookiePolicy from './pages/CookiePolicy';
import Disclaimer from './pages/Disclaimer';
import PrivacyPolicy from './pages/PrivacyPolicy';
import { useEffect } from 'react';
// import AOS from "aos";
// import "aos/dist/aos.css";

function App() {
  
  // useEffect(() => {
  //   console.log("object")
  //   AOS.init({
  //     duration:500
  //   });
  // }, []);
  
  return (
    <>
      <ToastContainer />
      <Router>
        <Switch>
          {/* <Redirect exact path='/'/> */}
          {/* <PrivateRouter path='/home' component={Home}/> */}
          <Route exact path='/' component={LandingPage} />
          <Route exact path='/login' component={Login} />
          <Route path='/signUp' component={SignUp} />
          <Route path='/aboutUs' component={AboutUs} />
          {/* <Route path='/dashboard' component={Dashboard} /> */}
          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <Route path='/enrollment' component={Enrollment} />
          <PrivateRoute path='/courseSection' component={Courses} />
          <Route path='/team' component={Team} />
          <PrivateRoute path='/profile' component={Profile} />
          <PrivateRoute path='/shopping' component={Cart} />
          <Route path='/forgotPassword' component={ForgotPassword} />
          <Route path='/contactUs' component={ContactUs} />
          <PrivateRoute path='/success' component={PaymentSuccess} />
          <PrivateRoute path='/cancel' component={PaymentCancel} />
          <Route path='/termsAndConditions' component={TermAndConditions} />
          <Route path='/disclaimer' component={Disclaimer} />
          <Route path='/privacyPolicy' component={PrivacyPolicy} />
          <Route path='/cookiePolicy' component={CookiePolicy} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
