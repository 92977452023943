import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import ProfileInfo from '../../components/ProfileInfo';
import Subscription from '../../components/Subscription';
import { useHistory } from 'react-router';

const Profile = () => {
  const [profileDivShow,setProfileDivShow]=useState(true);
  const history = useHistory();
  useEffect(()=>{
    // console.log("hist",history.location.search);
    const search = history.location.search;
    if(search === "?show=true"){
        setProfileDivShow(true)
    }else{
      setProfileDivShow(false)
    }
  },[history.location.search]);
  const profileClick =()=>{
    setProfileDivShow(true)
    history.push('/profile?show=true')
  }

  const subscriptionClick =()=>{
    setProfileDivShow(false)
    history.push('/profile?show=false')
  }
    return (
        <>
        <Header />
        <section>
        <div className="custom-w-95 d-flex justify-content-end" style={{marginTop:'15px'}}>
            <button type="button" className="btn white-btn" onClick={()=>history.push('/dashboard')}>Go Back to Dashboard</button>
        </div>
      <div className="common-spacing containerWidth">

        <div className="text-center courseenrollment-whole-section">

            <div className="custom-nav-tabs">
            <ul className="nav nav-tabs" id="myTab" role="tablist">

                <li className="nav-item">
                  <a className={`nav-link ${profileDivShow ? 'active' : ''}`}  onClick={()=>profileClick()} id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Profile</a>
                </li>
                <li className="nav-item">
                  <a className={`nav-link ${!profileDivShow ? 'active' : ''}`}  onClick={()=>subscriptionClick()} id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">Subscriptions</a>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div className={`tab-pane fade ${profileDivShow ? 'active show' : ''} `} id="profile" role="tabpanel" aria-labelledby="profile-tab">
                  <ProfileInfo />
                </div>
                <div className={`tab-pane fade ${!profileDivShow ? 'active show' : ''} `} id="contact" role="tabpanel" aria-labelledby="contact-tab">
                    
                  <Subscription />
                </div>
              </div>
            </div>
        </div>
      </div>
    </section>
    </>
    );
}

export default Profile;
