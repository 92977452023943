import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CourseActiveModal from "./CourseActiveModal";
import Info from "./Icons/Info";
import ViewAccessPeriod from "./ViewAccessPeriod";
import { addToCartItem, courseActivate } from "../../reducers/coursesReducer";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import CourseResetConfirmation from "./CourseResetConfirmation";
function CourseFilterSection({
  modalOpen,
  courseInfo,
  updataPercentage,
  courseFilter,
}) {
  const [activeModalShow, setActiveModalShow] = useState(false);
  const [resetConfirmShow, setResetConfirmShow] = useState(false);
  const [settingActive, setSettingActive] = useState(false);
  const [filterSectionShow, setFilterSectionShow] = useState(false);
  const [showSettings, setShowSettings] = useState(false)
  const user = useSelector((state) => state?.authReducer?.userData);
  const userCourseList = useSelector((state) => state?.course?.userCoursesList);
  const [test, settest] = useState(null)
  const activeModalOpen = () => {
    setActiveModalShow(true);
  };

  // console.log('courseinfofromfilter section---',courseInfo)

  const [filterObject, setfilterObject] = useState({
    All: false,
    Low: false,
    Medium: false,
    High: false,
    Unmarked: false,
  });


  const setfilterFalse=()=>{
    setFilterSectionShow(false);
    // console.log("functionClaled")
  }

  // console.log('-----courseInfo-------',courseInfo)
  useEffect(() => {
    setfilterObject(() => ({
      All: true,
    }));
  }, []);

useEffect(() => {
  settest(courseInfo)
}, [courseInfo])


// console.log("courseInfo==>",test)
  // useEffect(() => {
   
  // }, [filterSectionShow]);

  const onChangeFilter = (filter) => {
    // console.log('filter type=>',filter)
    if (filter !== "All") {
      courseFilter(filter);
      setfilterObject(() => ({
        All: false,
        [filter]: true,
      }));
    } else {
      courseFilter(filter);
      setfilterObject(() => ({
        [filter]: true,
      }));
    }
  };

  const history = useHistory();
  const cartItems = useSelector((state) => state?.course?.cartListData);

  // console.log(userCourseList,"userCourseList")
  const courseExist = (courseId) => {
    return userCourseList?.some(function (course) {
      return course?.courseId === courseId;
    });
  };


 

useEffect(() => {
  let myElementToCheckIfClicksAreInsideOf = document.querySelector('#filterDropDown');
  let myElementToCheckIfClicksAreInsideOfsetting = document.querySelector('#settingDropDown');
  document.body.addEventListener('click', function (event) {
  // let a=2;
  if ((myElementToCheckIfClicksAreInsideOf.contains(event.target)) || (myElementToCheckIfClicksAreInsideOfsetting.contains(event.target))) { 
    // console.log('clicked both inside',++a); 
 } 
 else {
  // console.log('clicked both outside');
    setFilterSectionShow(false);
    setSettingActive(false);
}
  
});

}, [])

  const addToCartMethod = (data) => {
    let existCheck = 2;
    console.log(data)
    const isExist = courseExist(data.comboName);
    // console.log("cardItem>>>",addItems,data);
    const arr = [];
    let cartArr;
    data.uid = user.uid;
    arr.push(data);
    if (!isExist) {
      if (cartItems) {
        const itemCheck =
          cartItems && cartItems?.map((each, i) => each.id !== data.id);
        // console.log("data",itemCheck,itemCheck.includes(false));
        if (itemCheck.includes(false)) {
          toast.error("Already added to cart");
          cartArr = [...cartItems];
          existCheck = 4;
        } else {
          toast.success("Added to cart");
          cartArr = [...cartItems, ...arr];
        }
      } else {
        cartArr = arr;
        toast.success("Added to cart");
      }
    } else {
      toast.error("Already purchased");
    }
    if (existCheck === 2) {
      dispatch(addToCartItem(arr));
    }
    // console.log('-------array data',arr)
  };

  // console.log(courseInfo);
  const dispatch = useDispatch();
  const [viewModal, setViewModal] = useState(false);

  // const updataPercentage=(type)=>{
  //   userSectionList && userSectionList?.map((each,i)=>{
  //     each.modulesList.map((item,index)=> {
  //         dispatch(updateUserModules(type,item.documentId))
  //     })
  //  })
  //  const value= (type=="Mark_as_Passed") ? 100 :0;
  //  userCourseList && userCourseList?.map((each,i)=>{
  //    console.log(each.documentId)
  //    dispatch(updateCoursePercentage({id:each.documentId,value:value}))
  //  })
  //  toast.success(value === 0 ? 'Reset Progress successfully' : 'Mark as passed successfully')
  // }
  const onActivateCourse = () => {
    const date = new Date();
    const afterFourMonth = date.setMonth(date.getMonth() + 4);
    const endDate = new Date(afterFourMonth);
    const req = {
      documentId: courseInfo?.documentId,
      uid: courseInfo?.uid,
      startDate: new Date(),
      endDate,
      currentObject: courseInfo,
    };
    dispatch(courseActivate({ ...req }));
    // console.log("couseinfoi",courseInfo,courseInfo?.documentId,new Date(),new Date(afterFourMonth));
    setActiveModalShow(false);
  };
  const viewPeriodObjectGet = (data) => {
    setViewModal(true);
  };

  const updateResetPercentage = () => {
    setResetConfirmShow(false);
    updataPercentage(courseInfo, "Reset_Progress","All");
  };


  const darkRgbValue =
    courseInfo?.darkRgbValues?.rValue +
    "," +
    courseInfo?.darkRgbValues?.gValue +
    "," +
    courseInfo?.darkRgbValues?.bValue;
  const rgbValue =
    courseInfo?.rgbValues?.rValue +
    "," +
    courseInfo?.rgbValues?.gValue +
    "," +
    courseInfo?.rgbValues?.bValue;

  return (
    <>
      {viewModal && (
        <ViewAccessPeriod
          closeModal={() => setViewModal(false)}
          eachObject={courseInfo}
        />
      )}
      {activeModalShow && (
        <CourseActiveModal
          closeModal={() => setActiveModalShow(false)}
          activateYes={onActivateCourse}
        />
      )}
      {resetConfirmShow && (
        <CourseResetConfirmation
          closeModal={() => setResetConfirmShow(false)}
          updateProgress={updateResetPercentage}
        />
      )}
      <div className="course-section-one">
        <h4>
          {courseInfo?.name} <span>({courseInfo?.nickname})</span>
          <span onClick={() => modalOpen(courseInfo?.id)}>
            <Info />
          </span>
          {/* course id */}
        </h4>
        <p className="course-mobile-section-text">
          Click below to activate your <b> 4-Month Access</b> to this course
        </p>
        {courseInfo?.isActivated && (
          <div className="progress" style={{ background: `rgb(${rgbValue})` }}>
            <div
              className="bar"
              style={{
                width: `${
                  courseInfo?.completionPercentage
                    ? courseInfo?.completionPercentage
                    : 0
                }%`,
                background: `rgb(${darkRgbValue})`,
              }}
            >
              <p className="percent">
                {courseInfo?.completionPercentage
                  ? courseInfo?.completionPercentage
                  : 0}
                %
              </p>
            </div>
          </div>
        )}
        <div className="filter-settings-section">
          {/* {(courseInfo?.isActivated && !courseInfo?.userPaid) && <button type="button" class="btn blue-btn" onClick={()=>addToCartMethod(courseInfo)}>Enroll</button>}
     {(!courseInfo?.userPaid && courseInfo?.isActivated) &&}*/}
          {courseInfo?.isActivated ? (
            <></>
          ) : courseInfo?.userPaid ? (
            <button
              type="button"
              className="btn blue-btn"
              style={{ background: `rgb(${darkRgbValue})` }}
              onClick={activeModalOpen}
            >
              Activate
            </button>
          ) : (
            <button
              type="button"
              className="btn blue-btn"
              style={{ background: `rgb(${darkRgbValue})` }}
              onClick={() => history.push("/enrollment")}
            >
              Enroll
            </button>
          )}
          <div className="dropdown" id="filterDropDown">
            <button
              className={`filter-btn btn ${filterSectionShow ? "active" : ""}`}
              type="button"
              id="filterDD"
              // data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="true"
              onClick={()=>{setSettingActive(false);setFilterSectionShow(!filterSectionShow)}}
              // onclick="activeClass()"
            >
              <svg
                className="dropdown-icon"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 7.84 8.09"
              >
                <defs></defs>
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <path
                      className="settings-cls"
                      d="M2.73,4.75V3.55a.18.18,0,0,0-.07-.15L.49,1.28A3.17,3.17,0,0,1,.15.89.84.84,0,0,1,0,.62.39.39,0,0,1,.23.14,1.13,1.13,0,0,1,.67,0C1.38,0,2.1,0,2.82,0L7,0a2.7,2.7,0,0,1,.49,0c.22,0,.36.16.37.34a.48.48,0,0,1-.07.29A1.7,1.7,0,0,1,7.5,1L5,3.58A.25.25,0,0,0,5,3.77V7.4a2.69,2.69,0,0,1,0,.4.3.3,0,0,1-.5.22,1.43,1.43,0,0,1-.23-.2L3,6.62a1,1,0,0,1-.29-.75ZM4.41,7.33h0V7.23c0-1.2,0-2.4,0-3.6a.36.36,0,0,1,.12-.28L7.11.68,7.24.55v0H6.91L3.19.54H.75l-.2,0,0,0L.82.91l2.3,2.23a.38.38,0,0,1,.12.29V5.8a.7.7,0,0,0,.21.54c.12.1.22.22.33.33Z"
                    />
                  </g>
                </g>
              </svg>
              <span>Filter</span>
            </button>
            {filterSectionShow && (
              <div
                className="filters-dd-menu filterDropPosition"
                aria-labelledby="filterDD"
              >
                <div className="filters-dd-menu-header text-center">
                  <h4>
                    {courseInfo?.name} <span>({courseInfo?.nickname})</span>
                  </h4>
                  {courseInfo?.isActivated && (
                    <div
                      className="progress"
                      style={{ background: `rgb(${rgbValue})` }}
                    >
                      <div
                        className="bar"
                        style={{
                          width: `${
                            courseInfo?.completionPercentage
                              ? courseInfo?.completionPercentage
                              : 0
                          }%`,
                          background: `rgb(${darkRgbValue})`,
                        }}
                      >
                        <p className="percent">
                          {courseInfo?.completionPercentage
                            ? courseInfo?.completionPercentage
                            : 0}
                          %
                        </p>
                      </div>
                    </div>
                  )}
                  <div className="mob-filter-btn">
                    <button
                      className="btn mob-dd-btn"
                      type="button"
                      id="filterDD"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      disabled={!courseInfo?.isActivated}
                    >
                      <i className="fas fa-filter"></i>
                      <span>Filter</span>
                    </button>
                    <button
                      className="btn mob-dd-btn"
                      disabled={!courseInfo?.isActivated}
                      onClick={setfilterFalse}
                      type="button"
                    >
                      <img
                        className="img-fluid"
                        src="images/settings.png"
                        alt=""
                      />
                      <span>Settings</span>
                    </button>
                  </div>
                </div>
                
                <p className="mt-4 mb-4">confidence:</p>
                <div className="custom-toggle-switch">
                  <input
                    type="checkbox"
                    id="toggleAll"
                    checked={filterObject.All}
                    onChange={() => onChangeFilter("All")}
                  />
                  <label htmlFor="toggleAll">
                    {" "}
                    <span>All</span>
                  </label>
                </div>
                <div className="custom-toggle-switch">
                  <input
                    type="checkbox"
                    id="toggleLow"
                    checked={filterObject.Low}
                    onChange={() => onChangeFilter("Low")}
                  />
                  <label htmlFor="toggleLow">
                    {" "}
                    <span>low</span>
                  </label>
                </div>
                <div className="custom-toggle-switch">
                  <input
                    type="checkbox"
                    id="toggleMed"
                    checked={filterObject.Medium}
                    onChange={() => onChangeFilter("Medium")}
                  />
                  <label htmlFor="toggleMed">
                    {" "}
                    <span>medium</span>
                  </label>
                </div>
                <div className="custom-toggle-switch">
                  <input
                    type="checkbox"
                    id="toggleHigh"
                    checked={filterObject.High}
                    onChange={() => onChangeFilter("High")}
                  />
                  <label htmlFor="toggleHigh">
                    {" "}
                    <span>high</span>
                  </label>
                </div>
                <div className="custom-toggle-switch">
                  <input
                    type="checkbox"
                    id="toggleUnMark"
                    checked={filterObject.Unmarked}
                    onChange={() => onChangeFilter("Unmarked")}
                  />
                  <label htmlFor="toggleUnMark">
                    {" "}
                    <span>unmarked</span>
                  </label>
                </div>
              </div>
            )}
          </div>
          <div className="dropdown" id="settingDropDown">
            <button
              
              className={`settings-btn btn ${settingActive ? "active" : ""}`}
              type="button"
              onClick={()=>{setSettingActive(!settingActive);setFilterSectionShow(false)}}
              id="settingsDD"
              // data-toggle="dropdown"
              // aria-haspopup="true"
              aria-expanded="false" 
              disabled={!courseInfo?.isActivated}
            >
              <svg
                className="dropdown-icon"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 9.95 10.07"
              >
                <defs></defs>
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <path
                      className="settings-cls"
                      d="M8.9,4.23l1-.59L8.76,1.52l-1,.58a3.93,3.93,0,0,0-1.47-.87l0-1.2L3.82,0l0,1.2A4.14,4.14,0,0,0,3,1.53a5.18,5.18,0,0,0-.7.5l-1-.61L0,3.52l1,.61A4.1,4.1,0,0,0,1,5.84L0,6.43,1.19,8.55l1-.58a4.19,4.19,0,0,0,1.48.87l0,1.2,2.44,0V8.87a3.7,3.7,0,0,0,.8-.34,3.87,3.87,0,0,0,.7-.5l1,.62,1.24-2.1-1-.61A3.94,3.94,0,0,0,8.9,4.23ZM6.14,7.11a2.38,2.38,0,1,1,.91-3.24A2.38,2.38,0,0,1,6.14,7.11Z"
                    />
                  </g>
                </g>
              </svg>
              {/* this settings */}
              <span>Settings</span>
            </button>
            {settingActive && (  <div
              className=" settingDropPosition settings-dd-menu custom-dropdown-menu"
              aria-labelledby="settingsDD"
            >
              <div className="filters-dd-menu-header text-center">
                <h4>
                  {courseInfo?.name} <span>({courseInfo?.nickname})</span>
                </h4>
                <div className="progress">
                  <div
                    className="bar"
                    style={{
                      width: `${
                        courseInfo?.completionPercentage
                          ? courseInfo?.completionPercentage
                          : 0
                      }`,
                    }}
                  >
                    <p className="percent">
                      {courseInfo?.completionPercentage
                        ? courseInfo?.completionPercentage
                        : 0}
                    </p>
                  </div>
                </div>
                <div className="mob-filter-btn">
                  <button
                    className="btn mob-dd-btn"
                    type="button"
                    id="filterDD"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    disabled={!courseInfo?.isActivated}
          
                  >
                    <i className="fas fa-filter"></i>
                    <span>Filter</span>
                  </button>
                  <button
                    className="btn mob-dd-btn"
                    disabled={!courseInfo?.isActivated}
                    onClick={setfilterFalse}
                    type="button"
                  >
                    <img
                      className="img-fluid"
                      src="images/settings.png"
                      alt=""
                    />
                    <span>Settings</span>
                  </button>
                </div>
              </div>
              <a
                className={
                  settingActive === "mark"
                    ? "dropdown-item blueColor"
                    : "dropdown-item"
                }
                href="#"
                onClick={() => {
                  setSettingActive("mark");
                  // updataPercentage(courseInfo,"Mark_as_Passed","All")
                }}
              >
                <img
                  src="./assets/images/dashboard2/tick.png"
                  className="dd-item-img"
                  alt=""
                />
                Mark as passed
              </a>
              <a
                className={
                  settingActive === "reset"
                    ? "dropdown-item blueColor"
                    : "dropdown-item"
                }
                href="#"
                onClick={() => {
                  setResetConfirmShow(true);
                  setSettingActive("reset");
                }}
              >
                <img
                  src="./assets/images/dashboard2/refresh.png"
                  className="dd-item-img"
                  alt="Reset Progress"
                />
                Reset Progress
              </a>
              <a
                className="dropdown-item"
                href="#"
                onClick={() => viewPeriodObjectGet(courseInfo)}
              >
                <img
                  src="./assets/images/dashboard2/clock.png"
                  className="dd-item-img"
                  alt="Access Period"
                />
                Access Period
              </a>
            </div>
          )}</div>
        </div>
      </div>
    </>
  );
}

export default CourseFilterSection;
