import React,{useEffect,useState} from 'react';
import {useHistory} from 'react-router-dom';
import { collection, getDocs } from "firebase/firestore"; 
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Google from '../../components/SocialIcons/Google';
import Facebook from '../../components/SocialIcons/Facebook';
import {Link} from 'react-router-dom'
import {loginFireApi,socialSignIn} from '../../reducers/authReducer'
import { useDispatch,useSelector } from 'react-redux';
// import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { getAuth} from "firebase/auth";
import { toast } from 'react-toastify';


const Login = () => {
    const history = useHistory();
    const dispatch = useDispatch();
const [userList, setuserList] = useState(null);
 const [email, setEmail] = useState('');
 const [password, setPassword] = useState('');
 const [emailError, setEmailError] = useState('');
 const [passwordError, setPasswordError] = useState('');
 const [rememberMe, setrememberMe] = useState(false);

 useEffect(() => {
    window.scroll(0,0);
  const ifCheck = localStorage.getItem('rememberMe') === 'true';
  const user = ifCheck ? localStorage.getItem('email') : '';
  setEmail(user);
  setrememberMe(ifCheck); 
 },[])

 
// const userDetail = useSelector((state)=>state.auth.userData);

// useEffect(() => {
// //     const docRef = collection(db,'courses').get();
// // console.log("docRef",docRef);
// const getDocumentUser = async ()=>{
//     const querySnapshot = await getDocs(collection(db, "courses"));
//     const array = [];
//     querySnapshot.forEach((doc) => {
//     //   console.log(doc.data(),"doc>>>>>");
//       array.push(doc.data());
//     });
//     setuserList(array)
// }
// getDocumentUser()
    
// }, []);
// const [isSignedIn, setIsSignedIn] = useState(false); // Local signed-in state.
// Listen to the Firebase Auth state and set the local state.

const auth = getAuth();

const googleClick =()=>{
  // const provider = new GoogleAuthProvider();
    dispatch(socialSignIn({provide:'google'})).then((res)=> {
        history.push('/dashboard')
    }).catch((err)=>{
      // toast.error("username or password incorrect")
      toast.error(err)
    });
}
const facebookClick =()=>{
  // const provider = new GoogleAuthProvider();
    dispatch(socialSignIn({provide:'facebook'})).then((res)=> {
        history.push('/dashboard')
    }).catch((err)=>{
      toast.error(err)
    });
}

const validation=(e)=>{
    let valid = false
    if(email){
      const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if(regex.test(email)){
            valid = true
            setEmailError('')
        }else{
          valid = false
          setEmailError('Please enter valid email');
        }
    }else{
      setEmailError('Please enter the email');
    }
    
    if(password){
        if(password.length > 5){
            valid=true;
            setPasswordError('')
        }else{
          valid=false;
          setPasswordError('Password must be at least 6 characters')
        }
    }else{
      valid=false;
      setPasswordError('Please enter the password')
    }

    return valid
}

const submitLogin = async (e)=>{
  
    e.preventDefault();
    
    if(validation()){
      localStorage.setItem('rememberMe', rememberMe);
      localStorage.setItem('email', rememberMe ? email : '');
      
       // console.log("email>>>>",email,password);
        dispatch(loginFireApi({email,password})).then((res)=>{
            history.push('/dashboard')
        }).catch((error)=>{
          // console.log("error",error);
         // console.log("error",error);
          toast.error(error?.code)
        })
        
    }
        

}
    return (
        <>
        <Header />
        <section>
<div className="common-spacing containerWidth">
<div className="register-section">
<div className="register-section-heading">
  <h3>Welcome Back !</h3>
  <hr/>
</div>
<div className="mt-4 register-section-body">
<div className="border-0 register-body-content">
  <div className="login-body-form register-body-form">

    <div className="register-body-right">
      <form>
        <div className="row">
      <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12 col-xs-12">
        <div className="form-group">
          <label>Email</label>
          <input placeholder="Email" type="email" id="email" name="email" className="form-control" value={email} onChange={(e)=>setEmail(e.target.value)}/>
          {/* <span className="error-text">Invalid Email or Password.</span> */}
          {emailError && <span className="error-text">{emailError}</span>}
        </div>
      </div>
      <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12 col-xs-12">
        <div className="form-group">
          <label>Password</label>
          <input placeholder="Password" type="password" id="pwd" name="pwd" value={password} onChange={(e)=>setPassword(e.target.value)} className="form-control" />
          {passwordError && <span className="error-text">{passwordError}</span>}
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center col-md-12 col-lg-12 col-xl-12 col-sm-12 col-xs-12">
        <div className="custom-checkbox form-group">
          <input type="checkbox" id="agree" name="agree" checked={rememberMe} onChange={()=>setrememberMe(!rememberMe)}/>
          <span>Remember me</span>
        </div>
        <Link to='/forgotPassword' className="forgot-pwd">Forgot Password</Link>
      </div>
      <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12 col-xs-12">
       <div className="signup-btn">
        <a href="" className="btn yellow-btn" type="button" 
        onClick={(e)=>submitLogin(e)}
        // onClick={()=>history.push('/dashboard')}
        >Sign In</a>
       </div>
      </div>
</div>
      </form>

    </div>
    <div className="or-sign-with"> <div className="signup-hr"></div>
    <span>  Or sign in with</span>
    <div className="signup-hr"></div>

    </div>
    {/* <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} /> */}
    <div className="signin-register-footer">
      <div className="register-footer-link">
        <a onClick={()=> googleClick()}>
        {/* <a href=""> */}
            <Google />
          </a>
        <a onClick={()=>facebookClick()}>
            <Facebook />
          </a>
      </div>
      <Link to="/signUp">Create a new account!</Link>
    </div>
    </div>
    </div>

     </div>

</div>
</div>

</section>
        <Footer />
        </>

    );
}

export default Login;
