import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  addToCartItem,
  deleteCartItem,
  getCoupen,
  selectCartItem,
  updateCouponData,
} from "../../reducers/coursesReducer";
import ComboCourseItem from "../Enrollment/ComboCourseItem";
import IndividualCourseCard from "../Enrollment/IndividualCourseCard";
import _ from "lodash";
// import {functions} from '../../firebase';
import { httpsCallable, getFunctions } from "firebase/functions";
import { loadStripe } from "@stripe/stripe-js";
import { toast } from "react-toastify";


const ShoppingCart = () => {
  const cartData = useSelector((state) => state?.course?.cartListData);
  const couponAmount = useSelector((state) => state?.course?.couponData);
  const userData = useSelector((state) => state?.authReducer?.userData);
  const [cartList, setCartList] = useState(null);
  const [couponApplyStatus, setCouponApplyStatus] = useState(false);
  const [couponInfo, setCouponInfo] = useState(null);
  const [couponCode, setCouponCode] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);
  const [couponError, setcouponError] = useState(null);
  const [docID, setDocID] = useState('');
  const [isNotGnrlCoupon, setIsNotGnrlCoupon] = useState(true)
  const dispatch = useDispatch();
  const history = useHistory();
  const stripe = loadStripe(process.env.REACT_APP_STRIPE_SCRIPT_KEY);
  const [removeDataSet, setRemoveDataSet] = useState([]);
  const user = useSelector((state)=> state?.authReducer?.userData);
  const [checkBoxList, setcheckBoxList] = useState([])
  const [dataArr, setdataArr] = useState([])
  const [couponVisible, setcouponVisible] = useState(true)

  // let dataArr = [];
  let checkArr=[] ;
  useEffect(() => {
    setCartList(cartData);
    //console.log('-----cartdata',cartData)
    let total = 0;
    total = cartData?.reduce(function (prev, cur) {
      return Number(prev) + Number(cur.price);
    }, 0);
    setTotalPrice(total);

    setcheckBoxList(new Array(cartData?.length).fill(false))
    setdataArr([])
  }, [cartData]);

  // console.log(checkBoxList)

  useEffect(() => {
    dispatch(selectCartItem(userData?.uid));
  }, []);

  const proceedCheckout = async () => {

    // console.log(totalPrice)
    if (cartList.length > 0) {
      let courseId = [];
      let courseDescription = [];
      cartList?.map((each, i) => {
        if (each.comboName) {
          courseId = [...courseId, ...each.courses];
          courseDescription.push(each.comboName);
        } else {
          courseId.push(each.id);
          courseDescription.push(each.name);
        }
      });
      let coursesMetadata = {};
      let course = courseId && _.uniq(courseId);
      course.map((id) => {
        coursesMetadata[id] = "true";
      });
      const courseInfo = {
        courseId: coursesMetadata,
        courseDescription: courseDescription.toString(),
        coupon: couponInfo ? couponInfo?.coupon : "",
        couponAmount: couponInfo ? couponInfo?.amount : 0,
        email: userData?.email,
      };
      
      //  const courseDetails = JSON.stringify(courseInfo);
      //console.log("course>>>>",JSON.stringify(courseInfo),Object.keys(coursesMetadata))
      // console.log("cartList",cartList,courseId,courseDescription.toString());
      const functions = getFunctions();
      const createCheckoutStripe = httpsCallable(
        functions,
        "createCheckoutStripe"
      );
      
      const req = {
        amount: totalPrice,
        uid: userData?.uid,
        courseInfo: courseInfo,
      };
      // console.log("res",req);
      const { data } = await createCheckoutStripe(req);
      
      // When the customer clicks on the button, redirect them to Checkout.
      const result = (await stripe).redirectToCheckout({
        sessionId: data.id,
      });
      
      // console.log('reached');
      if (result.error) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
        toast.error(result.error.message);
      }
      if(couponCode && isNotGnrlCoupon){
      updateCoupon();
      }
    } else {
      toast.error("Please select the Item");
    }
  };
  const removeCartItem = (documentId) => {
    // console.log(checkBoxList)
    dispatch(deleteCartItem({ docId: documentId })).then(()=>{dispatch(selectCartItem(userData?.uid));});
  
  };
  const onChangeRemove = (e, data,index) => {
       
    if (e.target.checked) {
      // dataArr = [...dataArr, data.documentId];
      setdataArr([...dataArr,data.documentId])
      const test=checkBoxList?.map((item,i)=>{
         return i===index ? !item : item
      })  
      setcheckBoxList(test)
    }
    if (!e.target.checked) {
      let newarray = dataArr.filter((element) => element !== data.documentId);
      // dataArr = newarray;
      setdataArr(newarray)
      const test=checkBoxList?.map((item,i)=>{
       return i===index ? !item : item
    })  
    setcheckBoxList(test)
    }
    // console.log(checkBoxList)
    
       
  };
  // console.log(dataArr);
  const removeCheckedValues = () => {
    // setRemoveDataSet(dataArr)
    if (dataArr.length) {
      dataArr &&
        dataArr.map((each, i) => {
          // console.log(each);
          removeCartItem(each);
        });
      toast.success("Selected items are removed");
    } else {
      toast.error("Please select item to remove");
    }
  };

  const checkCoupon = () => {
    // console.log('couponcode===>',couponCode)
    if (couponCode.trim()) {
      setcouponError("");
      dispatch(getCoupen(couponCode)).then((res) => {
        if (res) {
          // console.log('-------',res[0])
          setDocID(res[0]?.documentId)
          if(res[0]?.userUid){
            if(res[0]?.userUid === user?.uid){
            if(!res[0]?.isUsed){
              setCouponInfo(res[0]);
              let discount=0;
              discount=(totalPrice/100)*Number(res[0]?.discount);
              // console.log('----discount----',discount)
              let price = 0;
              price=(Number(totalPrice) - Number(discount)).toFixed(2);
              setTotalPrice(price);
              setCouponApplyStatus(true);
            }
            else{setcouponError("coupon already applied");
            setcouponVisible(true)}
          }
          else{
            setcouponError("coupon not in list");
            setcouponVisible(true)
          }
        }
        else{
          if(res[0]?.isActive){
            setIsNotGnrlCoupon(false);
            setCouponInfo(res[0]);
            let discount=0;
            discount=(totalPrice/100)*Number(res[0]?.discount);
            // console.log('----discount----',discount)
            let price = 0;
            price=(Number(totalPrice) - Number(discount)).toFixed(2);
            setTotalPrice(price);
            setCouponApplyStatus(true);
            setcouponVisible(false)
          }
          else{setcouponError("Coupon Expired");
          setcouponVisible(true)}
        }
        
        }
        else{
          setcouponVisible(true)
          setcouponError("Enter Valid CouponCode");
        }
      });
    } else {
      setcouponVisible(true)
      setCouponApplyStatus(false);
      setcouponError("Enter Coupon code");
    }

    // if(couponCode.trim())
    // {
    //   if(couponAmount == "Invalid_Coupon"){
    //     setcouponError('Invalid Coupon')
    //   }
    //   else{
    //     setcouponError('')
    //   }
    //  if(couponAmount>=0){
    //   setcouponError('')
    //  }
    // }
    // else{
    //   setcouponError('Enter Coupon code')
    // }
  };


  const updateCoupon=()=>{
    dispatch(updateCouponData(docID))
  }

  return (
    <div class="shopping-cart-table">
      <div class="table-responsive">
        <table class="table-bordered">
          <thead>
            <tr>
              <th>Remove</th>
              <th></th>
              <th>Product</th>
              <th>Cost</th>
            </tr>
          </thead>
          <tbody>
            {cartList ? (
              cartList?.map((each,i) => (
                <tr>
                  <td>
                    <form>
                      <input
                        type="checkbox"
                        id="remove"
                        class="form-control"
                        onChange={(e) => {onChangeRemove(e, each,i)
                        }}
                        checked={checkBoxList[i]}
                      />
                    </form>
                  </td>
                  <td>
                    {each?.comboName ? (
                      <ComboCourseItem
                        eachObject={each}
                        typeOfRender={"shopping"}
                      />
                    ) : (
                      <IndividualCourseCard
                        eachObject={each}
                        typeOfRender={"shopping"}
                        onClick={false}
                      />
                    )}
                  </td>
                  <td class="text-area">
                    {" "}
                    {each?.comboName ? each?.comboName : each?.courseName}{" "}
                    <br />
                    {each?.comboName ? (
                      each?.courseListArr?.map((each, i) => (
                        <span>
                          {" "}
                          {each.name}({each.nickname}) ,{" "}
                        </span>
                      ))
                    ) : (
                      <span>
                        {each?.name} ({each?.nickname}){" "}
                      </span>
                    )}
                  </td>
                  <td class="text-center">${each?.price}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4}>No Items</td>
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr>
              {couponApplyStatus ? (
                // <td colSpan={2}> {couponCode} is appiled</td>
                <td colSpan={2}> Coupon Applied</td>
              ) : (
                <>
                  <td>
                    {" "}
                    <input
                      type="text"
                      class="form-control"
                      value={couponCode}
                      onChange={(e) => setCouponCode(e.target.value)}
                    /><br/>
                    {couponError && (
                      <span className="error-text text-danger couponMsg">{couponError}</span>
                    )}
                  </td>
                  <td>
                    {" "}
                    <button
                      type="button"
                      class="btn yellow-btn"
                      onClick={()=>checkCoupon()}
                    >
                      Apply Coupon
                    </button>{" "}
                  </td>
                </>
              )}
              <td></td>
              <td>
                {" "}
                <button
                  type="button"
                  class="btn yellow-btn"
                  onClick={removeCheckedValues}
                >
                  Update Cart
                </button>{" "}
              </td>
            </tr>
            <tr>
              <td>

              </td>
              <td>
                
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div class="mobile-cart-area">
      <div class="mobile-cart-area-left">
      {couponError && <span className="error-text text-danger">{couponError}</span>}
      <span></span>
      </div>
        <div class="mobile-cart-area-left">
          <input
            type="text"
            class="form-control"
            value={couponCode}
            onChange={(e) => setCouponCode(e.target.value)}
          />
          <br/>
          {/* {couponError && <span className="error-text text-danger">{couponError}</span>} */}
          {/* <input type="text" class="form-control" placeholder="ADD PROMO CODE" /> */}
          {couponVisible && (<button type="button" class="btn yellow-btn" onClick={checkCoupon}>
            
            Apply Coupon
          </button>)
          }
          {/* {couponError && <span className="error-text text-danger">{couponError}</span>} */}
        </div>
        <div class="mobile-cart-area-right">
          <button
            type="button"
            class="btn yellow-btn"
            onClick={removeCheckedValues}
          >
            Update Cart
          </button>
        </div>
       
      </div>
      

      <div class="shopping-cart-checkout-section">
        <ul class="total-checkout-amt">
          <li>Total</li>
          <li>${totalPrice ? totalPrice : 0}</li>
        </ul>
        <button type="button" class="btn yellow-btn" onClick={()=>proceedCheckout()}>
          Proceed to checkout
        </button>
      </div>
    </div>
  );
};

export default ShoppingCart;
