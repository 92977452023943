import { collection, addDoc, getDocs,setDoc,doc,updateDoc,query,where,getDoc,orderBy,deleteDoc } from 'firebase/firestore'
import {db} from '../firebase'
import { getAuth } from 'firebase/auth'
import _ from 'lodash';
import moment from 'moment'

const COURSES_LIST_SUCCESS = 'COURSES_LIST_SUCCESS';
const COURSES_LIST_FAILURE = 'COURSES_LIST_FAILURE';
const USER_COURSES_LIST_SUCCESS = 'USER_COURSES_LIST_SUCCESS';
const USER_COURSES_LIST_FAILURE = 'USER_COURSES_LIST_FAILURE';
const COMBO_COURSES_LIST_SUCCESS='COMBO_COURSES_LIST_SUCCESS';
const COMBO_COURSES_LIST_FAILURE='COMBO_COURSES_LIST_FAILURE';
const SECTIONS_LIST_SUCCESS = 'SECTIONS_LIST_SUCCESS';
const SECTIONS_LIST_FAILURE = 'SECTIONS_LIST_FAILURE';
const MODULES_LIST_SUCCESS = 'MODULES_LIST_SUCCESS';
const MODULES_LIST_FAILURE = 'MODULES_LIST_FAILURE';
const STORE_COURSE_ID = 'STORE_COURSE_ID';
const USER_SECTION_LIST_SUCCESS ='USER_SECTION_LIST_SUCCESS';
const USER_SECTION_LIST_FAILURE ='USER_SECTION_LIST_FAILURE';
const CART_ADD_ITEMS ='CART_ADD_ITEMS';
const GET_COUPON='GET_COUPON';
const GET_COUPON_FAILURE='GET_COUPON_FAILURE';
const GET_CONFIDENT_DATA='GET_CONFIDENT_DATA';
const GET_DOCUMENT_ID='GET_DOCUMENT_ID';
const UPDATE_CONFIDENT_DATA='UPDATE_CONFIDENT_DATA';
const UPDATE_USER_MODULES='UPDATE_USER_MODULES';
const GET_COURSE_DETAILS='GET_COURSE_DETAILS';
const GET_COMBO_COURSE_DETAILS='GET_COMBO_COURSE_DETAILS';
const GET_COMBO_COURSE_DATA='GET_COMBO_COURSE_DATA';
const RESET_COURSE_SECTION_DATA='RESET_COURSE_SECTION_DATA';
const USER_PREVIOUS_COURSES_LIST_SUCCESS='USER_PREVIOUS_COURSES_LIST_SUCCESS';
const initialState = {
    coursesList: null,
    userCoursesList:null,
    comboCoursesList:null,
    sectionsList:null,
    modulesList:null,
    userSectionList:null,
    userModuleList:null,
    selectedCourse:null,
    cartListData:null
}

export function courseActivate({documentId,uid,startDate,endDate,currentObject}) {
    return async(dispatch)=>{
        try {
            const DocRef = doc(db, "user_courses", documentId);
            updateDoc(DocRef, {
              "startDate": startDate,
              "endDate":endDate,
              "isActivated":true
          })
          const updatedCourse =await getDoc(DocRef);
          const value= {...currentObject,...updatedCourse.data()};
        //   console.log("value>>>>>>",value,updatedCourse.data());
          dispatch(storeCourseId(value))
        }catch(err){
                // console.error(err)
        }
    }
}

//karthikeyan
export function getCoupen(couponId){
    // console.log(couponId)
    return async (dispatch) => {
        return new Promise(async(resolve,reject)=>{
        
        try {
            const querySnapshot = await getDocs(query(collection(db, "coupons"),where('coupon',"==",couponId)));
            const couponData = [];
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                couponData.push({...doc.data(),documentId:doc.id})
                // console.log(doc.id, " => ", doc.data());
                // console.log(couponData[0].amount)
            });
            // console.log('---coupondat',couponData)
            dispatch({
                type: GET_COUPON,
                payload: couponData
            })
            resolve(couponData)

        }
        catch (err) {
            dispatch({
                type: GET_COUPON_FAILURE,
                payload: err
            })
            reject(err)
            // console.log('error on getting coupon',err)
        }
            
    })
    }
}

export  function removeUserCourse({docId,currentObject}){
    return async(dispatch)=>{
        try {
            const DocRef = doc(db, "user_courses", docId);
            updateDoc(DocRef, {
              "isDelete":true,
              "isActivated":false
          })
          const updatedCourse =await getDoc(DocRef);
          const value= {...currentObject,...updatedCourse.data()};
        //   console.log("value>>>>>>",value,updatedCourse.data());
          dispatch(storeCourseId(value))
        }catch(err){
                // console.error(err)
        }
    }
}   


export  function removeUserSection(docId){
    return async (dispatch)=>{    
        await deleteDoc(doc(db, "user_sections",docId));
}   
}

export  function removeUserModules(docId){
    return async (dispatch)=>{    
        await deleteDoc(doc(db, "user_modules",docId));
}   
}


export function updateCouponData(docId){
    // console.log("lev",level,docId);
    return async (dispatch) => {
        try {
            const DocRef = doc(db, "coupons", docId);
            updateDoc(DocRef, {
              "isUsed": true
          })
            dispatch({
                type: GET_COUPON,
                payload: docId
            })
        }
        catch (err) {
            // console.log('error on getting coupon',err)
        }
    }
}


export function getConfidentData(dataObject){
    // console.log('uid from reducer',dataObject.uid)
    return async (dispatch) => {
        try {
            const querySnapshot = await getDocs(query(collection(db, "user_modules"),where('uid',"==",dataObject?.uid)));
            let data ='';
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                data=doc.data();
                // console.log(doc.id, " => ", doc.data());
                // console.log(couponData[0].amount)
            });
            dispatch({
                type: GET_CONFIDENT_DATA,
                payload: data
            })
        }
        catch (err) {
            // console.log('error on getting coupon',err)
        }
    }
}

export function updateConfidentData(level,docId){
    // console.log("lev",level,docId);
    return async (dispatch) => {
        try {
            const DocRef = doc(db, "user_modules", docId);
            updateDoc(DocRef, {
              "confidenceLevel": level,
              "isCompleted":level==3 ? true :false
          })
            dispatch({
                type: GET_CONFIDENT_DATA,
                payload: level
            })
        }
        catch (err) {
            // console.log('error on getting coupon',err)
        }
    }
}

export function updateUserModules(type,docId){
    // console.log('---userModules---',docId,type);
    return async (dispatch) => {
        try {
            if(type==="Mark_as_Passed"){
                const DocRef = doc(db, "user_modules", docId);
            updateDoc(DocRef, {
              "confidenceLevel": 3,
              "isCompleted": true 
            })
            // console.log('----mark')
            }
            else if(type==="Reset_Progress"){
                // console.log('Reset_Progress----',docId)
                const DocRef = doc(db, "user_modules", docId);
            updateDoc(DocRef, {
              "confidenceLevel": 0,
              "isCompleted":false
            })
            // console.log('----reset')
            }
            
            dispatch({
                type: UPDATE_USER_MODULES,
                payload: 'success'
            })
        }
        catch (err) {
            // console.log('error on getting coupon',err)
        }
    }
}

const auth = getAuth();
export function getAllCourses() {
    // console.log('------getAllcourses-----')
    return async (dispatch) => {
        try {
            const querySnapshot = await getDocs(query(collection(db, "courses"),orderBy('order')));
            const listData = [];
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                listData.push(doc.data())
                // console.log(doc.id, " => ", doc.data());

            });

            // console.log('all courses',listData);
            dispatch({
                type: COURSES_LIST_SUCCESS,
                payload: listData
            })


        }
        catch (err) {
            dispatch({
                type: COURSES_LIST_FAILURE,
                payload: err
            })
        }
    }
}

export function getCourseDetails(courseId) {
    // courseId='Practice_Management';
    // console.log('----courseId---',courseId)
    return async (dispatch) => {
        try {
            const querySnapshot = await getDocs(query(collection(db, "course_details"),where('courseId',"==",courseId)));
            const listData = [];
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                listData.push(doc.data())
                // console.log('loop')
            });
            // console.log(listData)
            dispatch({
                type: GET_COURSE_DETAILS,
                payload: listData
            })

            // console.log('coursedetails',listData)
        }
        catch (err) {
            dispatch({
                type: GET_COURSE_DETAILS,
                payload: err
            })
        }
    }
}
export function getComboCourseDeta(comboName) {
    // courseId='Practice_Management';
    const listData = [];
    return (dispatch) => {
        return new Promise (async (resolve,reject)=>{
        try {
            const querySnapshot =await getDocs(query(collection(db, "course-combo"),where('comboName',"==",comboName)));
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                listData.push(doc.data())
            });
        }
        catch (err) {
        }
        // console.log('metadtata',listData)
        dispatch({
            type: GET_COMBO_COURSE_DATA,
            payload: listData
        })
        resolve(listData)
    })
    
}     
}

export function getCoursePercentage(courseData) {
    // console.log('',courseData)
    // courseId='Practice_Management';
    const listData = [];
    return (dispatch) => {
        return new Promise (async (resolve,reject)=>{
        try {
            const querySnapshot =await getDocs(query(collection(db, "user_courses"),where('courseId',"==",courseData?.courseId),where('uid',"==",courseData?.uid)));
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                listData.push(doc.data())
            });
            resolve(listData)
        }
        catch (err) {
            reject(err)
        }
        
        
    })   

    
}     
}

export function getComboCourseDetails(courseIds) {
    // courseId='Practice_Management';
    // console.log(courseIds)
    
    return (dispatch) => {
        return new Promise (async (resolve,reject)=>{
            let listData = [];
            const list=await Promise.all( courseIds && courseIds?.map(async(dataId,i)=>{
            // console.log('----courseId---',dataId)
            let data;
            try {
                const querySnapshot =await getDocs(query(collection(db, "course_details"),where('courseId',"==",dataId)));
                querySnapshot.forEach((doc) => {
                   
                    data=doc.data()
                });
            }
            catch (err) {
            }
            return data;
    }))
    // console.log('courseDetails-----',list)
    dispatch({
        type: GET_COMBO_COURSE_DETAILS,
        payload: list
    })
    resolve(list)
})
} 
    
}


export function getComboCoursesList() {

    return async (dispatch) => {
        try {
            const querySnapshot = await getDocs(collection(db, "course-combo"));
            const listData = [];
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                listData.push({...doc.data(),documentId:doc.id})
                // console.log(doc.id, " => ", doc.data());
            });
            dispatch({
                type: COMBO_COURSES_LIST_SUCCESS,
                payload: listData
            })
        }
        catch (err) {
            dispatch({
                type: COMBO_COURSES_LIST_FAILURE,
                payload: err
            })
        }
    }
}

export function getCourseDocument(courseId,uid){
    // console.log("courseId",uid)
    return async (dispatch) => {
        return new Promise (async (resolve,reject)=>{
        try {
            const querySnapshot = await getDocs(query(collection(db, "user_courses"),where('courseId',"==",courseId),where('uid',"==",uid)));
            let data ={};
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                data={...doc.data(),documentId:doc.id};
                // console.log(doc.id, " => ", doc.data());
                // console.log(couponData[0].amount)
            });
            dispatch({
                type: GET_DOCUMENT_ID,
                payload: data
            })
            resolve(data)
        }
        catch (err) {
            // console.log('error on getting coupon',err)
            reject(err)
        }
    })
    }
}

export function updateCoursePercentage({id,value}) {
    // console.log('id,value from reducer',id,value)
    return async (dispatch) => {
    return new Promise ((resolve,reject)=>{
        const completedStatus = +value === 100 ? true : false;
        // console.log('completedStatus',completedStatus,value);
        const frankDocRef = doc(db,"user_courses",id);
        // console.log('valuew',value)
        updateDoc(frankDocRef, {
          "completionPercentage": +value,
          "isCompleted": completedStatus
      }).then((res)=>{
        //   console.log('success')
        resolve();
      }).catch((err)=>{
        reject(err);
        // console.log(err);
      })
    })
}
}

export function getUserCourses({uid}) {
    // console.log("uid>>>>",uid);
    return async (dispatch) => {
        try {
            const querySnapshot = await getDocs(query(collection(db, "user_courses"),where('uid',"==",uid),where('isDelete',"==",false)));
            const listData = [];
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                listData.push({...doc.data(),documentId:doc.id})
                // console.log(doc.id, " => ", doc.data());
            });
            // const userCourse = listData ? listData.filter((each,i)=> each.email === email) : [];
            // console.log("use>>>>>>",listData);
            dispatch({
                type: USER_COURSES_LIST_SUCCESS,
                payload: listData
            })

            // console.log('list data -----',listData)
        }
        catch (err) {
            dispatch({
                type:  USER_COURSES_LIST_FAILURE,
                payload: err
            })
        }
    }
}


export function getUserPreviousCourses({uid}) {
    // console.log("uid>>>>",uid);
    return async (dispatch) => {
        try {
            const querySnapshot = await getDocs(query(collection(db, "user_courses"),where('uid',"==",uid),where('isDelete',"==",true)));
            const listData = [];
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                listData.push({...doc.data(),documentId:doc.id})
                // console.log(doc.id, " => ", doc.data());
            });
            // const userCourse = listData ? listData.filter((each,i)=> each.email === email) : [];
            // console.log("use>>>>>>",listData);
            dispatch({
                type: USER_PREVIOUS_COURSES_LIST_SUCCESS,
                payload: listData
            })

            // console.log('list data -----',listData)
        }
        catch (err) {
            
        }
    }
}

export function getAllSections(id,type) {

    return async (dispatch) => {
        return new Promise (async(resolve,reject)=>{
            // console.log("data");
        try {
            const querySnapshot = await getDocs(query(collection(db, "sections"), where("courseId", "==", id)));
            const listData = [];
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                listData.push(doc.data())
                // console.log(doc.id, " => ", doc.data());
            });
            const dataList = listData && _.orderBy(listData,'order','asc');
            const dataArray = await Promise.all(dataList.map(async (each,i)=>{
                let modulesArray = await getAllModules(each.id);
                return {
                    ...each,
                    modulesList : modulesArray
                }
            }))
            // console.log("dataArray>>>>>",dataArray,listData);
            if(type!="perCalculation"){
                // console.log("type===>",type)
                dispatch({
                    type: SECTIONS_LIST_SUCCESS,
                    payload: dataArray
                })
            }
            else{
                // console.log("type===>empty")
                resolve(dataArray)
            }
            
            // console.log("getAllSection===>",dataArray)
            
        }
        catch (err) {
            dispatch({
                type: SECTIONS_LIST_FAILURE,
                payload: err
            })
        }
        })
    }

}
export async function  getAllModules(id) {
        let data;
        try {
            const querySnapshot = await getDocs(query(collection(db, "modules"), where("sectionId", "==", id)));
            const listData = [];
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                listData.push(doc.data())
                // console.log(doc.id, " => ", doc.data());
            });
            // dispatch({
            //     type: MODULES_LIST_SUCCESS,
            //     payload: listData
            // })
           data = listData && _.orderBy(listData,'order','asc');
           
        }
        catch (err) {
            // dispatch({
            //     type: MODULES_LIST_FAILURE,
            //     payload: err
            // })
            // reject()
        }
     return data       

}

export function getUserBasedSection({courseId,uid,type}){
    // console.log("uid",uid)
    return async (dispatch) => {
        return new Promise (async(resolve,reject)=>{
        try {
            const querySnapshot = await getDocs(query(collection(db, "user_sections"), where("courseId", "==", courseId),where("uid", "==", uid)));
            const listData = [];
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                listData.push({...doc?.data(),documentId:doc?.id})
                // console.log(doc.id, " => ", doc.data());
            });
            const dataArray = await Promise.all(listData.map(async (each,i)=>{
                // console.log("user>>>>>>",each);
                let modulesArray = await getUserBasedModules({id:each.sectionId,uid:uid});
                return {
                    ...each,
                    modulesList : modulesArray
                }
            }))
            // console.log("dataArray>>>>>",dataArray);
            if(type != "perCalculation"){
                dispatch({
                    type: USER_SECTION_LIST_SUCCESS,
                    payload: dataArray
                })
            }
            else{
                resolve(dataArray)
            }
            
            // console.log("getUserSection===>",dataArray)
            
        }
        catch (err) {
            dispatch({
                type: USER_SECTION_LIST_FAILURE,
                payload: err
            })
        }
    })
    }
}

export async function getUserBasedModules({id,uid}){
    // console.log("id????",id,uid);
    let data;
    try {
        const querySnapshot = await getDocs(query(collection(db, "user_modules"), where("sectionId", "==", id),where('uid','==',uid)));
        const listData = [];
        querySnapshot.forEach((doc) => {
            listData.push({...doc?.data(),documentId:doc?.id})
        });
       
       data = listData;
    }
    catch (err) {
       
    }
 return data    
}

export function storeCourseId(value){
    // console.log("data>>>>",value);
    return {
        type:STORE_COURSE_ID,
        payload:value
    }
}

export function resetData(){
    // console.log("data>>>>",value);
    return {
        type:RESET_COURSE_SECTION_DATA,
        
    }
}
export  function deleteCartItem({docId}){
    // console.log(docId)
    return async (dispatch)=>{    
        await deleteDoc(doc(db,"user_shopping_cart",docId));
}   

}

export function selectCartItem(uid){

// console.log('uid from selectCartItem-----',uid)
// console.log("seledctt>>>>>")
let data;
return async(dispatch)=>{    
    try {
    // console.log('object')
    const querySnapshot =await getDocs(query(collection(db, "user_shopping_cart"),where('uid','==',uid)));
    const listData = [];
    querySnapshot.forEach((doc) => {
        listData.push({...doc?.data(),documentId:doc?.id})
    });
   data = listData;
//    console.log('----cart data from reducer',data)

   dispatch({
    type:CART_ADD_ITEMS,
    payload:data
})
}
catch (err) {
    // console.log(err)
}    
}       

}

export function addToCartItem(data,arr){
  

        return (dispatch)=>{
        return new Promise (async(resolve,reject)=>{
        if(data[0].comboName){
            // console.log("comboname")
            addDoc(collection(db, "user_shopping_cart"),{
                comboDescription:data[0].comboDescription,
                comboName:data[0].comboName,
                courseListArr:data[0].courseListArr,
                courses:data[0].courses,
                cartDocumentId:data[0].documentId,
                image:data[0].image,
                price:data[0].price,
                uid:data[0].uid
            })
            .then(() => {
            //   console.log("success from add data")
              resolve();
            })
            .catch((error) => {
              reject();
            });
        }
        else{
            addDoc(collection(db, "user_shopping_cart"),{
            darkRgbValues:data[0]?.darkRgbValues,
            id:data[0]?.id,
            image:data[0]?.image,
            name:data[0]?.name,
            nickname:data[0]?.nickname,
            price:data[0]?.price,
            rgbValues:data[0]?.rgbValues,
            summary:data[0]?.summary,
            uid:data[0]?.uid
        })
        .then(() => {
        //   console.log("success from add data")
          resolve();
        })
        .catch((error) => {
          reject();
        });
    }
    dispatch(selectCartItem(data[0].uid));  
    })
    
    }

    
}



const coursesReducer = (state = initialState, action) => { 
    switch (action.type) {

            case COURSES_LIST_SUCCESS : {
                return {
                    ...state,
                    coursesList:action.payload
                }
            }
            case COURSES_LIST_FAILURE : {
                return {
                    ...state,
                    coursesList:action.payload
                }
            }
            case USER_COURSES_LIST_SUCCESS:{
                return {
                    ...state,
                    userCoursesList : action.payload
                }
            }
            case USER_COURSES_LIST_FAILURE:{
                return {
                    ...state,
                    userCoursesList:null
                }
            }
            case COMBO_COURSES_LIST_SUCCESS:{
                return {
                    ...state,
                    comboCoursesList : action.payload
                }
            }
            case COMBO_COURSES_LIST_FAILURE:{
                return {
                    ...state,
                    comboCoursesList:null
                }
            }
            case SECTIONS_LIST_SUCCESS:{
                return {
                    ...state,
                    sectionsList : action.payload
                }
            }
            case SECTIONS_LIST_FAILURE:{
                return {
                    ...state,
                    sectionsList:null
                }
            }case MODULES_LIST_SUCCESS:{
                return {
                    ...state,
                    modulesList : action.payload
                }
            }
            case MODULES_LIST_FAILURE:{
                return {
                    ...state,
                    modulesList:null
                }
            }
            case STORE_COURSE_ID : {
                return{
                    ...state,
                    selectedCourse:action.payload
                }
            }
            case USER_SECTION_LIST_SUCCESS : 
            return {
                ...state,
                userSectionList:action.payload
            }
            case USER_SECTION_LIST_FAILURE : 
            return {
                ...state,
                userSectionList:null
            }
            case CART_ADD_ITEMS:
                return {
                    ...state,
                    cartListData:action.payload
                }
            case GET_COUPON:
                return {
                    ...state,
                    couponData:action.payload
                }
            case GET_COUPON_FAILURE:
                return{
                    ...state,
                    couponData:'Invalid_Coupon'
                }
                case GET_CONFIDENT_DATA:
                return {
                    ...state,
                    confidentLevelData:action.payload
                }
                case UPDATE_CONFIDENT_DATA:
                return{
                    ...state,
                    confidentLevelData:action.payload
                      }
                case GET_DOCUMENT_ID:
                return{
                    ...state,
                    documentId:action.payload
                      }
                case UPDATE_USER_MODULES:
                return{
                    ...state,
                    userModuleStatus:action.payload
                      }      
                case GET_COURSE_DETAILS: {
                return {
                    ...state,
                    courseDetails:action.payload
                        }
                    }
                case GET_COMBO_COURSE_DETAILS: {
                return {
                    ...state,
                    comboCourseDetails:action.payload
                        }
                    }
                case GET_COMBO_COURSE_DATA: {
                return {
                    ...state,
                    comboCourseMetaData:action.payload
                        }
                    }
                case RESET_COURSE_SECTION_DATA:{
                    // console.log("called")
                    return{
                        ...state,
                        userSectionList:_.cloneDeep(null),
                        sectionsList:_.cloneDeep(null)
                    }
                }
                case USER_PREVIOUS_COURSES_LIST_SUCCESS:{
                    return {
                        ...state,
                        userPreviousCoursesList : action.payload
                    }
                }
    default:
            return {
                ...state,
            }
        }
}
export default coursesReducer;