import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import {forgotPasswordLink} from '../../reducers/authReducer';
import {toast} from 'react-toastify'

const ForgotPassword = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [email,setEmail]=useState(null);
    const [emailError,setEmailError]=useState('')
    const validation=()=>{
        let valid = false
        if(email){
          const regex =
          /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
            if(regex.test(email)){
                valid = true
                setEmailError('')
            }else{
              valid = false
              setEmailError('Please enter valid email');
            }
        }else{
          setEmailError('Please enter the email');
        }
        return valid;
    }
    const submitHandle=(e)=>{
        e.preventDefault();
        if(validation()){
            dispatch(forgotPasswordLink({email})).then((res)=>{
                toast.success("Successfully sent password reset link to your email")
                history.push('/login')
            }).catch((err)=>{
                // console.log("ettrp<<<<",err.name,err.message, err.code);
                toast.error( err.code)
            })
        }
        
    }
    return (
        <>
            <Header />
            <section>
<div className="common-spacing containerWidth">
<div className="register-section">
<div className="register-section-heading">
  <h3>Forgot your Password ?</h3>
  <hr/>
</div>
<div className="mt-4 register-section-body">
<div className="border-0 register-body-content">
  <div className="login-body-form register-body-form">

    <div className="register-body-right">
        <p className="forgot-pwd-text">It happens to the best of us. Please enter the email you signed up with and we'll send you instructions to reset your password</p>
      <form>
        <div className="row">
      <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12 col-xs-12">
        <div className="form-group">
          <label>Email Address: </label>
          <input placeholder="Email" type="email" id="email" name="email"  onChange={(e)=>setEmail(e.target.value)} className="form-control" />
                                                        {emailError && <span className="error-text">{emailError}</span>}
        </div>
      </div>
      <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12 col-xs-12">
       <div className="signup-btn">
        <a  className="btn yellow-btn" type="button" onClick={(e)=>submitHandle(e)}>Reset</a>
       </div>
      </div>
</div>
      </form>

    </div>

    </div>
    </div>

     </div>

</div>
</div>

</section>
            <Footer />
        </>
    );
}

export default ForgotPassword;
