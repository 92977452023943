import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore'
import {getAuth} from 'firebase/auth'
import { getFunctions } from "@firebase/functions";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain:process.env.REACT_APP_AUTH_DOMAIN,
    projectId:process.env.REACT_APP_PROJECT_ID,
    storageBucket:process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId:process.env.REACT_APP_MESSAGE_SENDER_ID,
    appId:process.env.REACT_APP_APPID ,
    measurementId:process.env.REACT_APP_MEASUREMENT_ID 
  };
  // const firebaseConfig = {
  //   apiKey: "AIzaSyAPp_-xrsgY-cKO9qwkLLYAUEwbrqp-vyA",
  //   authDomain: "myarelunchbox.firebaseapp.com",
  //   databaseURL: "https://myarelunchbox-default-rtdb.asia-southeast1.firebasedatabase.app",
  //   projectId: "myarelunchbox",
  //   storageBucket: "myarelunchbox.appspot.com",
  //   messagingSenderId: "993605523729",
  //   appId: "1:993605523729:web:ae6cff3c66456c273714b0",
  //   measurementId: "G-WDQ6C0B7WG"
  // };
  
  // console.log("firebaseConfig",firebaseConfig,process.env);
 export const fire = initializeApp(firebaseConfig)
 export const db = getFirestore();
 export const functions = getFunctions(fire);

