import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment'
import { getUserPreviousCourses } from '../../reducers/coursesReducer';

const PreviousSubcription = () => {
    const [previousList,setPreviousList]=useState(null)
    const userCourse = useSelector((state)=>state?.course?.userPreviousCoursesList);
    const user = useSelector((state) => state?.authReducer?.userData);

    const dispatch=useDispatch();
    useEffect(()=>{
         dispatch(getUserPreviousCourses({uid:user?.uid}));
        const array = userCourse?.filter((each)=> {
            // console.log(moment(new Date()).isSameOrAfter(moment.unix(each.endDate.seconds)));
            return moment(new Date()).isSameOrAfter(moment.unix(each?.endDate?.seconds));
         });
        setPreviousList(array)
    },[userCourse])
    return (
        <div className="subscription-details">
                        <p>Previous Subscriptions</p>
                        <div className="table-responsive">
                        <table className="subscription-table">
                            <tbody>
                            <tr>
                                <th></th>
                                <th>Description</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th style={{width:'151px'}}></th>
                                <th style={{width:'51px'}}></th>
                                <th style={{width:'191px'}}></th>
                            </tr>
                           {previousList?.length > 0 ? previousList?.map((each,i)=>{
                           const startDate =  moment.unix(each?.startDate?.seconds).format("MM/DD/YYYY");
                           const endDate = moment.unix(each?.endDate?.seconds).format("MM/DD/YYYY");
                           return (<tr key={i}>
                                <td className="grey-circle"><i className="fas fa-circle"></i></td>
                                <td>{each?.courseName}</td>
                                <td>{startDate}</td>
                                <td>{endDate}</td>
                                <td></td>
                                {/* <td></td>
                                <td></td>  */}
                                </tr>)} ) : <tr><td colSpan={7}>No Previous Subscription</td></tr> }
                                </tbody>
                        </table>
                      </div>
                    </div>

    );
}

export default PreviousSubcription;
