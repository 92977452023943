import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import IndividualCourses from '../../components/Enrollment/IndividualCourses';
import ComboCourses from '../../components/Enrollment/ComboCourses';
import {getAllCourses, getCourseDetails} from '../../reducers/coursesReducer'
import { useDispatch, useSelector } from 'react-redux';
import CourseDetails from '../../components/Landing/CourseDetails';

const Enrollment = () => {

  const dispatch = useDispatch();
  useEffect(()=>{
    window.scroll(0,0);
    dispatch(getAllCourses());
  },[])

    return (
        <>
        <Header />
<section>
<div className="common-spacing containerWidth">
<div className="courseenrollment-whole-section">
<p className="mobile-bold-heading bold-heading">A la Carte:
</p>
<p className="mobile-bold-sub-heading bold-sub-heading">Individual Course</p>
<div className="container-fluid">
<div className="enrollment-phase-one">
  <IndividualCourses/>
  <div className="courseenrollment-line custom-line-one" style={{marginBottom:'100px'}}></div>
 <ComboCourses />
  </div>

</div>
</div>
</div>
</section>
        <Footer />
        </>
    );
}

export default Enrollment;
