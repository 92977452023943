import React from 'react';

const HowItWorkSection = () => {
    return (
        <div data-aos="fade-up" className="home-page-section-4">
        <h2 className="custom-d-mobile d-mobile common-title">How it works:</h2>
        <h6 className="mobile-landing-num-title d-mobile">1</h6>
        <div className="home-page-section-4-content">
      <div className="home-page-section-4-text">
        <h2 className="d-desktop common-title">How it works:</h2>
        <h6 className="d-desktop">1</h6>
        <p>We have designed ARE Lunchbox with simplicity in mind. Each course contains all major topics associated with its respective ARE 5.0 exam - as designated by NCARB (including additional sections for added clarity). When you enroll in any given course, you can explore the subject at your own convenience until you pass.
        </p>
        <p>The courses provided include Practice Management (PcM), Project Management (PjM), Programming and Analysis (PA), Project Planning and Design (PPD), Project Development and Documentation (PDD), and Construction and Evaluation (CE). These can be purchased individually or bundled with other courses. If you want access to a greater selection at a discounted price, we highly recommend you enroll in our Bento Box 1 (PcM, PjM, CE) or Bento Box 2 (PA, PPD, PDD). If you want affordable access to all six courses, you may also purchase The Lunchbox (PcM, PjM, PA, PPD, PDD, CE) for an even further discounted price per course.
        </p>
        <p className="mb-40">Not sure you're ready to commit? We understand. That's why we're offering a free trial to all candidates who are curious to explore what we offer. After all, we want you to sample our content, and make sure it's a good fit for you!
</p>

      </div>
      <div className="home-page-section-4-img">
      <img src="../assets/images/home4.png" />
      </div>
      </div>
      {/* <!-- <div className="d-mobile">
      <img className="img-fluid" src="../assets/images/homemob2.png"/>
      </div> --> */}
      <div className="home-page-section-5-content home-page-section-4-content">
        <h6 className="mobile-landing-num-title">2</h6>
        <div className="home-page-section-4-img">
          <img src="../assets/images/home5.png" />
          </div>
        <div className="home-page-section-4-text">
          <h6 className="d-desktop text-right">2</h6>
          <p>Every course is a collection of modules that are organized into different sections for efficient studying and effective prioritizing. Structuring our courses in this manner allows users to absorb the necessary information in smaller, digestible bits. Therefore, material can be studied on an incremental basis (especially “on the go” using our mobile web app), or all-at-once in dedicated study sessions (using our desktop interface). All the while, users can visually track their progress through the course percentage completion bar and from the modules themselves.
          </p>
          <p className="mb-40">At the end of every course, there is a section dedicated to practice problems. If you feel you have mastered most of the content in a course, but are still unsure of a concept or two, you can utilize the practice tests to make sure you are comfortable with the content before sitting for your exam. No stress, either! NCARB is not keeping track of how many times you practice these problems. Review them as needed to become familiar with the types of questions you may see on an ARE 5.0 exam.</p>

        </div>

        </div>
        {/* <div className="d-mobile">
          <img className="img-fluid" src="../assets/images/homemob5.png"/>
          </div> */}
          <h6 className="mobile-landing-num-title">3</h6>
        <div className="home-page-section-6-content home-page-section-5-content home-page-section-4-content">

          <div className="home-page-section-4-text">
            <h6 className="d-desktop">3</h6>
            <p>Many of us can relate to feeling intimidated by how much material there is to absorb for an exam. We spend hours cramming information, only to become overwhelmed when trying to prioritize what to study. This is made even more challenging when we aren’t even sure of what we don’t know. The team at ARE Lunchbox has developed a feature to assist you with this issue!
            </p>
            <p>Our Confidence Rating System allows you to assess your own confidence within each individual module of information. Once you have completed a module, you can rank your level of confidence on the material – Low, Medium, or High – before moving on to the next module. If you find your confidence in a module is lacking, you can revisit it later.
            </p>
            <p>The array of modules allows you to gain a strategic visual of where you are in the study process. You can also filter by confidence rating, which allows you to clearly see what needs to be improved on versus what you are already familiar with. Having the modules clearly organized in a simple interface helps to optimize study efficiency as well as remove any clutter or doubt!
            </p>
            <p className="mb-40">With all of these tools at your disposal, you are ready to take on the ARE's!</p>

          </div>
          <div className="home-page-section-4-img">
            <img src="../assets/images/home6.png" />
            </div>
          </div>

      </div>

    );
}

export default HowItWorkSection;
