import React,{useState,useEffect} from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Facebook from '../../components/SocialIcons/Facebook';
import Google from '../../components/SocialIcons/Google';
import {Link} from 'react-router-dom'
import {collection,addDoc,doc} from 'firebase/firestore'
import {getAuth} from 'firebase/auth'
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useHistory } from 'react-router-dom';
import {loginFireApi,socialSignIn,createUser} from '../../reducers/authReducer'
import { useDispatch,useSelector } from 'react-redux';
// import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { toast } from 'react-toastify';
import RegisterSuccessModal from './RegisterSuccessModal';

const SignUp = () => {
  
  const [fields, setFields] = useState(null);
  const [errorFields,setErrorFields]=useState(null);
  const [profileUrl,setProfileUrl]=useState(null);
  const [registerSuccess,setRegisterSuccess]=useState(false);
  const auth = getAuth();
  const history = useHistory();
  const dispatch = useDispatch();
  const validation=()=>{
    let valid = [];
    let fieldObject = errorFields;
    if(fields?.email){
      const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if(regex.test(fields?.email)){
            valid.push(true)
          fieldObject = {...fieldObject,'email':''}
        }else{
          valid.push(false)
          fieldObject = {...fieldObject,'email':'E-Mail is not valid'}
        }
    }else{
      valid.push(false)
      fieldObject = {...fieldObject,'email':'Required Field'}
      
    }
    
    if(fields?.password){
        if(fields?.password.length > 5){
          valid.push(true)
            fieldObject = {...fieldObject,password:''}
        }else{
          valid.push(false);
          fieldObject = {...fieldObject,password:'Password must be at least 6 characters'}
        }
        }else{
          valid.push(false)
          fieldObject = {...fieldObject,password:'Required Field'}
        }
        if(fields?.lastName){
          valid.push(true);
          fieldObject = {...fieldObject,lastName:''}
      }else{
        valid.push(false)
        fieldObject = {...fieldObject,lastName:'Required Field'}
      }
      if(fields?.agree){
        valid.push(true);
          fieldObject = {...fieldObject,agree:''}
      }else{
        valid.push(false)
        fieldObject = {...fieldObject,agree:'Please check the checkbox'}
      }
    if(fields?.firstName){ 
      fieldObject = {...fieldObject,firstName:''}
      valid.push(true);
      }else{
        valid.push(false)
        fieldObject = {...fieldObject,firstName:'Required Field'}
      }
      
    setErrorFields(fieldObject);
    return valid;
}
useEffect(() => {
  window.scroll(0,0);
}, [])

    const uploadProfileImage =(e)=>{
      // console.log("e.target>>>>",e.target.files[0]);
        if(e.target.files){
          // console.log("e.target",e.target.files[0].name);
          const storage = getStorage();
    const file = e.target.files[0];
    // Create the file metadata
    const metadata = {
      contentType: file?.type
    };

    

// Upload file and metadata to the object 'images/mountains.jpg'
    const storageRef = ref(storage, 'images/' + file?.name);
    const uploadTask = uploadBytesResumable(storageRef, file, metadata);

// Listen for state changes, errors, and completion of the upload.
    uploadTask.on('state_changed',
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // console.log('Upload is ' + progress + '% done');
        switch (snapshot.state) {
          case 'paused':
            // console.log('Upload is paused');
            break;
          case 'running':
            // // console.log('Upload is running');
            break;
        }
      }, 
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case 'storage/unauthorized':
            // User doesn't have permission to access the object
            break;
          case 'storage/canceled':
            // User canceled the upload
            break;

          // ...

          case 'storage/unknown':
            // Unknown error occurred, inspect error.serverResponse
            break;
        }
      }, 
      () => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          // // console.log('File available at', downloadURL);
          setProfileUrl(downloadURL)
        });
      }   
);
    }
}
const onChangeFields=(e)=>{
    const {name,value,checked} = e.target;
    setFields({
      ...fields,
      [name]:name === "agree" ? checked : value
    })
}

  const sumbitSignUp =  (e)=>{
    e.preventDefault();
  // // console.log("validation()",validation().indexOf(false),validation())
    if(validation().indexOf(false) === -1){
      dispatch(createUser({...fields,profileUrl})).then((res)=>{
        // history.push('/login');
        // toast.success('Your account has been registered successfully and verficition link sent to your email');
        setRegisterSuccess(true);
        setFields(null);
        setProfileUrl(null)
    }).catch((err)=>{
      toast.error(err?.code)
    })
    }
  }

  
  const googleSignupClick =()=>{
    // const provider = new GoogleAuthProvider();
      dispatch(socialSignIn({provide:'google'})).then((res)=> {
          history.push('/dashboard')
      }).catch((err)=>{
        toast.error(err)
      });
  }
  const facebookSignupClick =()=>{
    // const provider = new GoogleAuthProvider();
      dispatch(socialSignIn({provide:'facebook'})).then((res)=> {
          history.push('/dashboard')
      }).catch((err)=>{
        toast.error(err)
      });
  }
  const closeModalPopup=()=>{
    setRegisterSuccess(false);
  }
    return (
        <>
        {registerSuccess && <RegisterSuccessModal closeModal={()=>closeModalPopup(false)}/>}
        <Header/>
        <section>
<div className="common-spacing containerWidth">
<div className="register-section">
<div className="register-section-heading">
  <h3>Create a New Account</h3>
  <hr/>
</div>
<div className="register-section-body">
<h5>Profile Details</h5>
<div className="register-body-content">
  <div className="register-body-form">
    <div className="register-body-left">
        <div className="manage-profile-pro-pic">
    <form id="form1" name="uploadFiles">
      <div id='img_container'>{profileUrl &&  <img id="preview" src={profileUrl } alt="your image" title=''/>}</div>
      <div className="input-group">
      <div className="custom-file">
      <input type="file" id="inputGroupFile01" className="imgInp custom-file-input" onChange={(e)=>uploadProfileImage(e)} />
      <label className="custom-file-label" htmlFor="inputGroupFile01">
        <i className="fas fa-plus"></i> Upload Photo
        </label>
    </div>
  </div>
  </form>
</div>
      <p>Proﬁle Picture</p>
    </div>
    <div className="register-body-right">
      <form>
        <div className="row">
          <div className="col-md-6 col-lg-6 col-xl-6 col-sm-6 col-xs-12">
        <div className="form-group">
          <label>First Name</label>
          <input placeholder="First Name" type="text" name="firstName" value={fields ? fields?.firstName : ''} className="form-control" onChange={(e)=> onChangeFields(e)}/>
          {errorFields?.firstName && <span className="error-text">{errorFields?.firstName}</span>}
        </div>
      </div>
      <div className="col-md-6 col-lg-6 col-xl-6 col-sm-6 col-xs-12">
        <div className="form-group">
          <label>Last Name</label>
          <input placeholder="Last Name" type="text" name="lastName" className="form-control" value={fields ? fields?.lastName : ''} onChange={(e)=> onChangeFields(e)} />
          {/* <!-- <p>Blanc</p> -->
          <!-- <span className="error-text">This field cannot be blank.</span> --> */}
          {errorFields?.lastName && <span className="error-text">{errorFields?.lastName}</span>}
        </div>
      </div>
      <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12 col-xs-12">
        <div className="form-group">
          <label>Email</label>
          <input placeholder="Email" type="email" name="email" className="form-control" value={fields ? fields?.email : ''} onChange={(e)=> onChangeFields(e)}/>
          {/* <!-- <p>Blanc</p> -->
          <!-- <span className="error-text">Please enter a valid Email address.</span> --> */}
          {errorFields?.email && <span className="error-text">{errorFields?.email}</span>}
        </div>
      </div>
      <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12 col-xs-12">
        <div className="form-group">
          <label>Password</label>
          <input placeholder="Password" type="password"  name="password" className="form-control" value={fields ? fields?.password : ''} onChange={(e)=> onChangeFields(e)}/>
          {/* <!-- <p>Blanc</p> -->
          <!-- <span className="error-text">Password must be at least 6 characters.</span> --> */}
          {errorFields?.password && <span className="error-text">{errorFields?.password}</span>}
        </div>
      </div>
      <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12 col-xs-12">
      <div className="custom-checkbox form-group">
          <input type="checkbox" id="agree" name="agree" checked={fields ? fields?.agree : false} onChange={(e)=> onChangeFields(e)}/>
          <span>I have read and agree to the <b><Link to="/termsAndConditions" target="_blank">Terms and Conditions</Link></b></span>
          
        </div>
        <div className="form-group">
        {errorFields?.agree && <span className="error-text">{errorFields?.agree}</span>}
          </div>
      </div>
      <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12 col-xs-12">
       <div className="signup-btn">
        <a href="" className="btn yellow-btn" type="button" 
        onClick={(e)=>sumbitSignUp(e)}
        // onClick={()=>history.push('/login')}
        >Sign Up</a>
       </div>
      </div>
</div>
      </form>

    </div>
    </div>
  <div className=" text-center">
      <div className="or-sign-with"> <div className="signup-hr"></div>
      <span>Or sign up with</span>
      {/* <!-- <span>  Complete account setup</span> --> */}
      <div className="signup-hr"></div>
      </div></div>
      {/* <!-- <p className="text-center mt-4 mb-5 forgot-pwd-text">We’ve sent a confirmation email to your inbox. Please verify your email address in order to complete your account setup and gain access to your personalized dashboard. Please wait a couple minutes if you do not receive an email immediately and make sure to check your junk mail.</p> --> */}
      <div className="signin-register-footer text-center">
        <div className="register-footer-link">
          <a onClick={()=>googleSignupClick()}>
              <Google />
            </a>
          <a onClick={()=>facebookSignupClick()}>
              <Facebook />
            </a>
        </div>
        <Link to="/login">I already have an account!</Link>
        {/* <!-- <p className="text-center mt-4 mb-5 forgot-pwd-text">Congrats! You’re now on your way to licensure!</p> --> */}
      </div>
     </div>
     </div>
</div>
</div>
</section>
        <Footer />
        </>
  );
}

export default SignUp;
