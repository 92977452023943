import React, { useEffect, useState } from 'react';
import { useHistory ,Link} from 'react-router-dom';
import {getAuth,signOut} from 'firebase/auth'
import { useDispatch, useSelector } from 'react-redux';
import { selectCartItem, storeCourseId } from '../../reducers/coursesReducer';
import { updateUserData } from '../../reducers/authReducer';

const Header = () => {
  const history = useHistory();
  const auth = getAuth();
  const user = useSelector((state)=>state?.authReducer?.userData);
  const cartData = useSelector((state)=>state?.course?.cartListData);
  const dispatch = useDispatch();

  const logout=()=>{
    localStorage.removeItem('userAccessToken')
    // localStorage.removeItem('email')
    localStorage.removeItem('Provider');
    dispatch(updateUserData(null))
    auth.signOut();
    history.push('/');
  }

  const [menuEnable,setMenuEnable] = useState(false);
const  openMenu=() => {
  // console.log("data>>>>true");
  setMenuEnable(true)
}
  const closeMenu=()=> {
    setMenuEnable(false);
}

const navigate=()=>{
  history.push('/shopping');
}

const navigateAndReset=()=>{
  // dispatch(storeCourseId(null))
  history.push('/dashboard')
  
}
const [totalPrice, settotalPrice] = useState(0);
const [totalcount, settotalcount] = useState(0);
useEffect(()=>{
  dispatch(selectCartItem(user?.uid))
},[])
useEffect(() => {
  let price=0;
  let count=0;
  price =cartData ? cartData.reduce(function(prev, cur) {
    count++;
   return Number(prev) + Number(cur.price);
 }, 0):0;
 settotalPrice(price);
 settotalcount(count);
}, [cartData])

// useEffect(() => {
//   dispatch(selectCartItem(user?.uid))
// }, [])

    return (
      <>
      <div className="mobile-menu">
    <ul>

      <li className="dropdown">
        <a
          href="#"
          className="btn"
          role="button"
          // id="dropdownMenuLink"
          // data-toggle="dropdown"
          // aria-haspopup="true"
          // aria-expanded="false"
          onClick={()=>openMenu()}
        >
          <img src="../assets/images/mobilemenu3.png" className="mobile-menu-img" />
          <span>Menu</span></a>
        {/* <div
          className="mobile-dd-menu dropdown-menu"
          aria-labelledby="dropdownMenuLink"
        >

          <a className="dropdown-item" href="#"
            ><img
              src="../assets/images/288ppi/Asset 7.png"
              alt="logo"
              className="mobile-menu-logo"
            />
          </a>
          <hr />
          {user?.uid && <><div className="mobile-menu-profile-details">
            <img src={user?.photoURL ? user?.photoURL : "../assets/images/dashboard2/profile.png"} alt="profile" />
            <div className="profile-details">
              <p>{user?.displayName}</p>
              <Link to="/profile?show=true">view profile</Link>
            </div>
          </div>
          <hr /></> }
                    <Link className="dropdown-item" to="/dashboard">Home Page</Link>
       <Link className="dropdown-item" to='/'>Landing Page</Link>
       <Link className="dropdown-item" to="/enrollment">course enrollment</Link>
       <Link className="dropdown-item" to="/aboutUs">about</Link>
       <Link className="dropdown-item" to="/team">Meet the Team</Link>
       <Link className="dropdown-item" to="/contactUs">Contact Us</Link>
       <Link className="dropdown-item" to="/profile?show=false">subscriptions</Link>
       {user?.uid ?
       <a className="dropdown-item" href='' onClick={()=>logout()}>logout</a>
       : <><Link className="dropdown-item" to="/login">Login</Link>
       <Link className="dropdown-item" to="/signUp">Sign Up</Link> </> }
        </div> */}
      </li>
      <li onClick={navigateAndReset}>

        <img src="../assets/images/mobilemenu.png" />

        <span>Home</span>
      </li>

      <li className="mobile-menu-active" onClick={()=>history.push('/enrollment')}>
        <img src="../assets/images/mobilemenu2.png" />
        <span>Enroll</span>
      </li>
    </ul>
  </div>
  <div className="notification">

    <div onClick={()=>closeMenu()} id="notificationcontent" className={menuEnable ? 'menuBackDrop' : ''} >
    <div id="notificationsection" className={`notifcationsection ${menuEnable ? 'leftZero' : 'leftMinus'}`}>

      <div className="notifcation-content">
        <div
        className="header-menu mobile-dd-menu"

      >
        <div className="header-menu-list">
          <p className="mobile-menu-active">
           <img src="../assets/images/active-menu.png"/>
           <span>Menu</span>
          </p>
       <a className="dropdown-item" href="#"
       >
       <img
         src="../assets/images/288ppi/Asset 7.png"
         alt="logo"
         className="mobile-menu-logo"
       />
     </a>
       </div>

       <hr />
       {user?.uid && <> <div className="mobile-menu-profile-details">
       {user?.photoURL ? <img src={ user?.photoURL} alt="profile" /> :
       <div className="smallCircle_img"></div> }
            <div className="profile-details">
              <p>{user?.displayName}</p>
              <Link to="/profile?show=true">view profile</Link>
            </div>
       </div>
       <hr /> </>}
       <Link className="dropdown-item" to="/dashboard">Home Page</Link>
       <Link className="dropdown-item" to='/'>Landing Page</Link>
       <Link className="dropdown-item" to="/enrollment">Course enrollment</Link>
       <Link className="dropdown-item" to="/aboutUs">About</Link>
       <Link className="dropdown-item" to="/team">Meet the Team</Link>
       <Link className="dropdown-item" to="/contactUs">Contact Us</Link>
       <Link className="dropdown-item" to="/profile?show=false">Subscriptions</Link>
       {user?.uid ?
       <a className="dropdown-item" href='' onClick={()=>logout()}>Logout</a>
       : <><Link className="dropdown-item" to="/login">Login</Link>
       <Link className="dropdown-item" to="/signUp">Sign Up</Link> </> }
</div>
      </div>
    </div>
  </div>
  </div>
{ history.location.pathname !== '/' ? <header className="sticky-header header">
  <nav className="common-menu">
    <ul>
      <li>
        <a
        href="#"
        className="btn"
        role="button"
        id="dropdownMenuLink"
        onClick={()=>openMenu()}
      >
        <img src="../assets/images/menu.png" />
        <span>Menu</span>
        </a>
        {/* <div
        className="header-menu mobile-dd-menu dropdown-menu"
        aria-labelledby="dropdownMenuLink"
      >
        <div className="header-menu-list">
           <p className="mobile-menu-active">
            <img src="../assets/images/active-menu.png"/>
            <span>Menu</span>
           </p>
        <a className="dropdown-item" href="#"
        >
        <img
          src="../assets/images/288ppi/Asset 7.png"
          alt="logo"
          className="mobile-menu-logo"
        />
      </a>
        </div>

        <hr />
        <div className="mobile-menu-profile-details">
          <img src="../assets/images/dashboard2/profile.png" alt="profile" />
          <div className="profile-details">
            <p>Mark Besquin</p>
            <a href="#">view profile</a>
          </div>
        </div>
        <hr />
        <a className="dropdown-item" href="dashboard2.html">Home Page</a>
        <a className="dropdown-item" href="courseenrollment.html">course enrollment</a>
        <a className="dropdown-item" href="about.html">about</a>
        <a className="dropdown-item" href="meetteam.html">Meet the Team</a>
        <a className="dropdown-item" href="contactUs.html">Contact Us</a>
        <a className="dropdown-item" href="profile.html">subscriptions</a>
        <a className="dropdown-item" href="login.html">logout</a>
      </div>  */}

    </li>
      <li onClick={()=>history.push('/enrollment')}>
        <img src="../assets/images/menu1.png" />
        <span>Enroll</span>
      </li>
      <li onClick={()=>history.push('/dashboard')}>
        <img src="../assets/images/menu3.png" />
        <span>Home</span>
      </li>
    </ul>
  </nav>
<div className="logo" onClick={()=>history.push('/dashboard')}>
<img src="../assets/images/logo.png"/>
</div>
<div className="right-header" onClick={navigate}>
  <div className="right-header-cart">
    <div className="right-header-cart-img">
      <img src="../assets/images/cart.png" />
      </div>
     
      <div className="right-header-cart-qty">x {user?.uid ? totalcount :0}</div>
      <div className="right-header-cart-price">${user?.uid ? totalPrice :0}</div>
    
    </div>
  </div>
</header>
: <header className="landing-page-header header">
      <nav className="landing-page-menu common-menu">
        <ul>
          <li>
            <a
            href="#"
            className="btn"
            role="button"
            id="dropdownMenuLink"
            onClick={()=>openMenu()}
          >

<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.52 10.95"><defs></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_6" data-name="Layer 6"><path style={{fill:'#4c4c4d'}} d="M7.26,1.85H1.06a1,1,0,0,1-1-.74A1,1,0,0,1,1,0H13.45a1,1,0,0,1,1,.66,1,1,0,0,1-1,1.19H7.26Z"/><path style={{fill:'#4c4c4d'}} d="M7.25,6.4H1.06a1,1,0,0,1-1-.79A1,1,0,0,1,1,4.44H13.45a1,1,0,0,1,1,.66,1,1,0,0,1-1,1.3Z"/><path style={{fill:'#4c4c4d'}} d="M7.26,11H1.06a1,1,0,0,1-1-.79A1,1,0,0,1,1,9H13.45a1,1,0,0,1,1,.7,1,1,0,0,1-1,1.27H7.26Z"/></g></g></svg>
            <span>Menu</span>
            </a>

        </li>
          <li onClick={()=>history.push('/enrollment')}>

<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.22 11.01"><defs></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_6" data-name="Layer 6"><path style={{fill:'#4c4c4d'}} d="M4.74,11H8.45s.23-.13.05-.31-.7-.85-.7-.85L7.67,9.7l0-.22s-.06-.28.09-.28H13c.26,0,.27-.28.27-.28V7.63H0V9c0,.26.35.25.35.25H5.46a.17.17,0,0,1,.09.28,2.4,2.4,0,0,0-.21.41l-.66.88S4.53,11,4.74,11Z"/><path style={{fill:'#4c4c4d'}} d="M.83,7.72v-7H12.34V8h.88V.24c0-.27-.35-.24-.35-.24H.3A.29.29,0,0,0,0,.3V7.8Z"/><polygon className="cls-1" points="8.57 1.7 8.57 4.23 8.15 3.81 7.82 4.23 7.82 1.7 8.57 1.7"/><path className="cls-1" d="M9.65,2.09V1.62H7L6.63,2l-.4-.39H3.58v.47H3.23V6.7H10V2.09ZM6.52,6.25l-.31-.32H3.81V1.79H6.16l.36.38Zm3-.32H7.07l-.31.32V2.17l.36-.38H9.47Z"/></g></g></svg>
            <span>Enroll</span>
          </li>
          <li onClick={()=>history.push('/dashboard')}>

<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.5 10.68"><defs></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_6" data-name="Layer 6"><path style={{fill:'#4c4c4d'}} d="M4.71,1.07l4,3.58V9.88h-2V6.41h-4V9.88H.8V4.52L4.71,1.07M4.72,0,0,4.16v6.52H3.54V7.21H5.93v3.47H9.5V4.29L4.72,0Z"/></g></g></svg>
            <span>Home</span>
          </li>
        </ul>
      </nav>
      <div className="logo" onClick={()=>history.push('/dashboard')}>
        <img src="images/logo.png" />
      </div>
      <div className="right-header">
        <div className="right-header-cart">
          <div className="right-header-cart-img">
            <img src="images/cart.png" />
          </div>
          
          <div className="right-header-cart-qty">x {user?.uid ? totalcount :0}</div>
          <div className="right-header-cart-price">${user?.uid ? totalPrice :0}</div>
        </div>
      </div>
    </header>
    }
      </>
    );
}

export default Header;
