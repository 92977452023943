import React, { useEffect } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

const Disclaimer = () => {
  
  useEffect(() => {
    window.scroll(0,0);
   }, [])
  return (
    <>
      <Header />
      <section className="commonSection">
        <div className="container">
          <div class="WordSection1">
            {" "}
            <p style={{margin:"0in",background:"white"}}>
              <b>
                <span style={{fontFamily:"Arial,sans-serif", color:"#222222"}}>
                  WEBSITE DISCLAIMER
                </span>
              </b>
            </p>{" "}
            <p style={{margin:"0in",background:"white"}}>
              <span style={{fontFamily:"Arial,sans-serif",color:"black"}}>
                &nbsp;
              </span>
            </p>{" "}
            <p style={{margin:"0in",background:"white"}}>
              <span style={{fontFamily:"Arial,sans-serif", color:"#222222"}}>
                The information provided by ARE LUNCHBOX LLC ("We." "Us", or
                "our") on{" "}
              </span>
              <span style={{fontFamily:"Arial,sans-serif",color:"black"}}>
                <a href="http://www.arelunchbox.com/">
                  <span style={{color:"#1155CC"}}>http://www.arelunchbox.com</span>
                </a>
              </span>
              <span style={{fontFamily:"Arial,sans-serif",color:"#222222"}}>
                {" "}
                (the "Site") is for general informational purposes only. All
                information on the Site is provided in good faith, however we
                make no representation or warranty of any kind, express or
                implied,regarding the accuracy, adequacy, validity, reliability,
                availability or completeness of any information on the Site.
                UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY
                LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF
                THE SITE OR RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE.
                YOUR USE OF THE SITE AND YOUR RELIANCE ON ANY INFORMATION ON THE
                SITE IS SOLELY AT YOUR OWN RISK.
              </span>
            </p>{" "}
            <p style={{margin:"0in",background:"white"}}>
              <span style={{fontFamily:"Arial,sans-serif",color:"black"}}>
                &nbsp;
              </span>
            </p>{" "}
            <p style={{margin:"0in",background:"white"}}>
              <b>
                <span style={{fontFamily:"Arial,sans-serif", color:"#222222"}}>
                  EXTERNAL LINKS DISCLAIMER
                </span>
              </b>
            </p>{" "}
            <p style={{margin:"0in",background:"white"}}>
              <span style={{fontFamily:"Arial,sans-serif",color:"black"}}>
                &nbsp;
              </span>
            </p>{" "}
            <p style={{margin:"0in",background:"white"}}>
              <span style={{fontFamily:"Arial,sans-serif", color:"#222222"}}>
                The Site may contain (or you may be sent through the Site links
                to other websites or content belonging to or originating from
                third parties or links to websites and features in banners or
                other advertising. Such external links are not investigated,
                monitored, or checked for accuracy, adequacy, validity,
                reliability, availability or completeness by us. WE DO NOT
                WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR THE
                ACCURACY OR RELIABILITY OF ANY INFORMATION OFFERED BY
                THIRD-PARTY WEBSITES LINKED THROUGH THE SITE OR ANY WEBSITE OR
                FEATURE LINKED IN ANY BANNER OR OTHER ADVERTISING. WE WILL NOT
                BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY
                TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR
                SERVICES.
              </span>
            </p>{" "}
            <p style={{margin:"0in",background:"white"}}>
              <span style={{fontFamily:"Arial,sans-serif",color:"black"}}>
                &nbsp;
              </span>
            </p>{" "}
            <p style={{margin:"0in",background:"white"}}>
              <b>
                <span style={{fontFamily:"Arial,sans-serif", color:"#222222"}}>
                  PROFESSIONAL DISCLAIMER
                </span>
              </b>
            </p>{" "}
            <p style={{margin:"0in",background:"white"}}>
              <span style={{fontFamily:"Arial,sans-serif",color:"black"}}>
                &nbsp;
              </span>
            </p>{" "}
            <p style={{margin:"0in",background:"white"}}>
              <span style={{fontFamily:"Arial,sans-serif", color:"#222222"}}>
                The Site cannot and does not contain architecture advice. The
                architecture information is provided for general informational
                and educational purposes only and is not a substitute for
                professional advice. Accordingly, before taking any actions
                based upon such information, we encourage you to consult with
                the appropriate professionals. We do not provide any kind of
                architecture advice. THE USE OR RELIANCE OF ANY INFORMATION
                CONTAINED ON THE SITE IS SOLELY AT YOUR OWN RISK.
              </span>
            </p>{" "}
            <p style={{margin:"0in",background:"white"}}>
              <span style={{fontFamily:"Arial,sans-serif",color:"black"}}>
                &nbsp;
              </span>
            </p>{" "}
            <p style={{margin:"0in",background:"white"}}>
              <b>
                <span style={{fontFamily:"Arial,sans-serif", color:"#222222"}}>
                  TESTIMONIALS DISCLAIMER
                </span>
              </b>
            </p>{" "}
            <p style={{margin:"0in",background:"white"}}>
              <span style={{fontFamily:"Arial,sans-serif",color:"black"}}>
                &nbsp;
              </span>
            </p>{" "}
            <p style={{margin:"0in",background:"white"}}>
              <span style={{fontFamily:"Arial,sans-serif", color:"#222222"}}>
                The Site may contain testimonials by users of our products
                and/or services. These testimonials reflect the real-life
                experiences and opinions of such users. However, the experiences
                are personal to those particular users, and may not necessarily
                be representative of all users of our products and/or services.
                We do not claim, and you should not assume, that all users will
                have the same experiences. YOUR INDIVIDUAL RESULTS MAY VARY. The
                testimonials on the Site are submitted in various forms such as
                text, audio and/or video, and are reviewed by us before being
                posted. They appear on the Site verbatim as given by the users,
                except for the correction of grammar or typing errors. Some
                testimonials may have been shortened for the sake of brevity
                where the full testimonial contained extraneous information not
                relevant to the general public. The views and opinions contained
                in the testimonials belong solely to the individual user and do
                not reflect our views and opinions. We are not affiliated with
                users who provide testimonials, and users are not paid or
                otherwise compensated for their testimonials.
              </span>
            </p>{" "}
            <p class="MsoNormal">
              <span style={{fontSize:"12.0pt",lineHeight:"107%",fontFamily: "Arial,sans-serif"}}>
                &nbsp;
              </span>
            </p>{" "}
          </div>
        </div>


        
      </section>
      <Footer />
    </>
  );
};

export default Disclaimer;
