import React from "react";

const CourseActiveModal = ({ closeModal, activateYes }) => {
  return (
    <div className="backDrop">
      <div
        class="activate-custom-modal modal fade show d-block"
        id="activateModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="activateModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="activate-modal-body modal-body">
              <h5>Are you sure you are ready to Activate this course?</h5>
              <p>
                ‘Activating’ will start your 4-Month subscription access to this
                course. This access period can not be “paused” once started.
              </p>
            </div>
            <div class="modal-footer">
              <div class="activate-modal-footer">
                <a onClick={closeModal}>No</a>
                <a onClick={activateYes}>Yes</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseActiveModal;
