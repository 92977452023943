import React from 'react';
import { useHistory } from 'react-router';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import ShoppingCart from '../../components/ShoppingCart';

const Cart = () => {
  const history = useHistory();
    return (
        
        <>
        <Header />
        <section>
      <div className="common-spacing containerWidth">
        <div className="courseenrollment-whole-section">
            <div className="w-100 d-flex justify-content-end">
            <button type="button" className="btn white-btn" onClick={()=>history.push('/enrollment')}>Go Back to Course Enrollment</button>
        </div>
       <h4 className="course-title">Shopping Cart:</h4>
     <ShoppingCart />
      </div>
      </div>
    </section>
        <Footer />
        </>
    );
}

export default Cart;
